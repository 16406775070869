import { Map as IMap } from 'immutable';
import { compose } from 'redux';
import { combineReducers } from 'redux-immutable';
import { mergePersistedState } from 'redux-localstorage';
import { intlReducer } from 'react-intl-redux';
import { reducer as baseCapiReducer } from 'capi/redux/immutable';

import sessionReducer, { sessionLifetimeDeserializer } from '../session/sessionModule';
import { getCapiUrl, getOrderingToken } from './bootstrap';
import { getNowTimestamp } from '../utils/timeUtils';
import brandReducer from '../brand/brandModule';
import { iovationReducer } from '../iovation/iovationModule';
import routingReducer from '../routing/routingModule';
import faceplateReducer from '../landingPage/landingPageModule';
import paymentReducer from '../payment/paymentModule';
import productDetailsReducer from '../giftCardDetailsPage/productDetailsModule';
import purchaseTypeReducer from '../segment/segmentModule';
import loadingReducer from '../loader/loaderModule';
import webViewReducer from '../webView/webViewModule';
import currencyPickerReducer from '../localeCurrencyPicker/LocaleCurrencyPickerModule';
import promoReducer from '../promo/promoModule';

export const capiInitialState = IMap({
    url: getCapiUrl(),
    token: getOrderingToken()
});


export const capiReducer = (state = capiInitialState, action) => baseCapiReducer(state, action);





export const lastActionReducer = () => getNowTimestamp();

const appReducer = combineReducers({
    brand: brandReducer,
    capi: capiReducer,
    session: sessionReducer,
    iovation: iovationReducer,
    lastAction: lastActionReducer,
    intl: intlReducer,
    faceplateInfo: faceplateReducer,
    completedSteps: routingReducer,
    payment: paymentReducer,
    addedProducts: productDetailsReducer,
    loader: loadingReducer,
    webView: webViewReducer,
    currencyPicker: currencyPickerReducer,
    promo: promoReducer,
    purchaseType: purchaseTypeReducer
});

const reducers = compose(
    mergePersistedState(sessionLifetimeDeserializer)
)(appReducer);

export default reducers;
