export const userInCCMRegion = () => document.cookie.trim().includes('notice_behavior=implied,eu');

export const noticeBehaviorCookieExists = () => document.cookie.trim().includes('notice_behavior');

export const foundUserPreferencesCookies = () => document.cookie.trim().includes('cmapi_cookie_privacy');

export const campiAllowsAllCookies = () => document.cookie.trim().includes('cmapi_cookie_privacy=permit 1,2,3');

export const cookiesAllowed = (cookieConsentEnabled, reloadNeeded = false) => {
    if (!cookieConsentEnabled) {
        return true;
    }

    
    
    if (!noticeBehaviorCookieExists()) {
        setTimeout(() => cookiesAllowed(cookieConsentEnabled, true), 1000);
        return false;
    }

    
    
    if (foundUserPreferencesCookies() || userInCCMRegion()) {
        return campiAllowsAllCookies();
    }

    
    
    
    if (reloadNeeded) {
        window.location.reload();
    }

    
    return true;
};
