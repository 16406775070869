import { fromJS, Map as IMap } from 'immutable';
import { v4 as uuid } from 'uuid';
import { EGIFT_DELIVERY_SHAREABLE } from '../constants/constants';

export const filterAndMapKeys = (originData, keyMap) => (
    originData.filter((_, key) => keyMap.has(key)).mapKeys(
        originKey => keyMap.get(originKey, originKey)
    )
);


export const paymentKeyMap = fromJS({
    paymentPlusAuthResponse: 'response',
    hmac: 'hmac'
});

export const giftCardKeyMap = fromJS({
    senderName: 'senderName',
    deliveryDate: 'deliveryDate',
    recipientEmail: 'recipientEmail',
    recipientName: 'recipientName',
    faceplateCode: 'faceplateCode',
    personalMessage: 'message',
    quantity: 'quantity',
    cardValue: 'faceValue',
    selfBuy: 'selfBuy'
});

export const metaDataKeyMap = fromJS({
    amount: 'amount',
    currencyCode: 'currencyCode',
    brandCode: 'brandCode',
    loyaltyNumber: 'loyaltyNumber',
    senderOptIn: 'notifyOffers',
    promoCode: 'promoCode',
    locale: 'languageCode',
    discountAmount: 'totalDiscountRewardAmount',
    checkoutId: 'checkoutId'
});

export const orderResponseKeyMap = fromJS({
    statement: 'statement',
    orderNumber: 'orderNumber',
    billingAddress: 'billingAddress'
});

export const sanitizedPaymentKeyMap = fromJS({
    fullName: 'fullName',
    firstName: 'firstName',
    lastName: 'lastName',
    street1: 'street1',
    street2: 'street2',
    city: 'city',
    state: 'state',
    postalCode: 'postalCode',
    countryCode: 'countryCode',
    phoneNumber: 'phoneNumber',
    paymentMethod: 'paymentMethod',
    CAPI_URI_VERSION: 'CAPI_URI_VERSION',
    purchaserEmail: 'purchaserEmail',
    senderEmail: 'senderEmail',
    purchaseDate: 'purchaseDate',
    currency: 'currency'
});

export const getFormattedName = (name) => {
    if (name) {
        return name.replace(/\s\s+/g, ' ').replace(/,/g, '').trim();
    }
    return name;
};

// SERIALIZER FUNCTIONS
/**
 * Translate payment data to CAPI V4 order format.
 *
 * @param {Immutable.Map} payment Payment data
 * @returns {Immutable.Map} The serialized payment in CAPI V4 order format.
 */
export const paymentSerializer = payment => filterAndMapKeys(payment, paymentKeyMap);

/**
 * Translate a single eGift card item to CAPI V4 order format.
 *
 * @param {Immutable.Map} item Item object.
 * @param {string} brandCode brand code.
 * @returns {Immutable.Map} The serialized eGift card item in CAPI V4 order format.
 */
export const giftCardItemSerializer = (item, brandCode) => {
    // under eGift card, we require only the faceplate code
    const faceplate = item.get('faceplate');
    const updatedItem = item.set('faceplateCode', faceplate.get('code')).delete('faceplate');

    const giftCardItem = filterAndMapKeys(updatedItem, giftCardKeyMap).asMutable();
    
    
    if (item.get('selfBuy')) {
        const senderName = item.get('yourName');
        const senderEmail = item.get('yourEmail');
        const formattedSenderName = getFormattedName(senderName);
        giftCardItem.set('recipientName', formattedSenderName);
        giftCardItem.set('senderName', formattedSenderName);
        giftCardItem.set('recipientEmail', senderEmail);
    } else {
        const senderName = item.get('senderName');
        const recipientName = item.get('recipientName');
        const senderEmail = item.get('senderEmail');
        const eGiftCardShareOption = item.get('eGiftCardShareOption');

        if (senderName) {
            giftCardItem.set('senderName', getFormattedName(senderName));
        }
        if (recipientName) {
            giftCardItem.set('recipientName', getFormattedName(recipientName));
        }
        if (senderEmail) {
            giftCardItem.set('senderEmail', senderEmail);
        }
        if (eGiftCardShareOption === EGIFT_DELIVERY_SHAREABLE) {
            giftCardItem.set('isSharedViaLink', true);
        }
    }

    
    
    giftCardItem.set('cartItemToken', uuid());
    giftCardItem.set('digitalActivationFeeAmount', 0);
    giftCardItem.set('brandCode', brandCode);

    return giftCardItem.asImmutable();
};

export const egiftCardsSerializer = (items, brandCode) => (
    items.map(egc => giftCardItemSerializer(egc, brandCode))
);

export const metaDataSerializer = (metaData) => {
    const serializedMeta = filterAndMapKeys(metaData, metaDataKeyMap);

    
    
    serializedMeta.set('notifyOffers', false);
    serializedMeta.set('totalDiscountRewardAmount', 0);
    serializedMeta.set('promoCode', null);

    
    
    return serializedMeta.update('amount', val => (val ? val.toFixed(2) : undefined));
};

export const orderSerializer = (payment, orderMetaData, eGiftCards) => {
    const capiUriVersion = payment.get('CAPI_URI_VERSION', null);
    const paymentMethod = payment.get('paymentMethod');
    const brandCode = orderMetaData.get('brandCode');

    
    const paymentData = paymentSerializer(payment);
    const eGiftCardsData = egiftCardsSerializer(eGiftCards, brandCode);
    const serializedMetaData = metaDataSerializer(orderMetaData);

    
    const orderData = serializedMetaData.merge({
        [paymentMethod]: paymentData,
        egiftCards: eGiftCardsData,
        shippingGroups: [],
        CAPI_URI_VERSION: capiUriVersion
    });

    
    
    return orderData.set('customRewardCardRecipients', {});
};

export const userDataSerializer = (eGiftCards) => {
    const eGiftCard = eGiftCards.get(0);

    const [firstName, ...lastName] = (
        eGiftCard.get('selfBuy') ? eGiftCard.get('yourName') : eGiftCard.get('senderName')
    ).split(' ');

    const userData = {
        firstName,
        lastName: lastName.join(' ').trim() || 'LNU',
        emailAddress: eGiftCard.get('selfBuy') ? eGiftCard.get('yourEmail') : eGiftCard.get('senderEmail')
    };

    return IMap(userData);
};

export const initiateConfigSerializer = (
    eGiftCards,
    orderMetaData,
    paymentPlusMetaData,
    riskifiedSessionId = null
) => {
    const brandCode = orderMetaData.get('brandCode');
    const eGiftCardsData = egiftCardsSerializer(eGiftCards, brandCode);
    const serializedMetaData = metaDataSerializer(orderMetaData);
    const userData = userDataSerializer(eGiftCards);

    
    return serializedMetaData.merge({
        egiftCards: eGiftCardsData,
        user: userData,
        sessionIds: { RISKIFIED: riskifiedSessionId },
        ...paymentPlusMetaData
    });
};

export const sanitizePaymentData = paymentData => (
    filterAndMapKeys(fromJS(paymentData), sanitizedPaymentKeyMap)
);

export const orderResponseSerializer = response => (
    filterAndMapKeys(fromJS(response), orderResponseKeyMap)
);
