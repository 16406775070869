import { defineMessages } from 'react-intl';

const paymentMessages = defineMessages({
    paymentTitle: {
        id: 'payment.paymentMessages.paymentTitle',
        description: 'Payment title',
        defaultMessage: 'Payment'
    },
    requiredTitle: {
        id: 'payment.paymentMessages.requiredTitle',
        description: 'Required title',
        defaultMessage: 'Required'
    },
    paymentInfoNonExchangeableEGifts: {
        id: 'payment.paymentMessages.paymentInfoNonExchangeableEGifts',
        description: 'payment info Please note that eGifts cannot be refunded or exchanged.',
        defaultMessage: 'Please note that eGifts cannot be refunded or exchanged.'
    },
    CheckEmail: {
        id: 'payment.paymentMessages.paymentInfoNonExchangeableCheckEmail',
        description: 'check email address',
        defaultMessage: 'Please double check that the email address (on the left side of the screen) for sending the card is correct.'
    },
    MakeCorrection: {
        id: 'payment.paymentMessages.paymentInfoNonExchangeableGoback',
        description: 'Go back to make corrections',
        defaultMessage: 'If you wish to make corrections, please click the "Go back" button and make the corrections.'
    },
    cancelButton: {
        id: 'payment.paymentMessages.cancelButton',
        description: 'Cancel button text',
        defaultMessage: 'CANCEL'
    },
    payButton: {
        id: 'payment.paymentMessages.payButton',
        description: 'Pay button text',
        defaultMessage: 'PAY {number}'
    },
    privacyPolicyLink: {
        id: 'payment.paymentMessages.privacyPolicyLink',
        description: 'Privacy policy link',
        defaultMessage: 'Privacy policy'
    },
    orderReviewLabel: {
        id: 'payment.paymentMessages.orderReviewLabel',
        description: 'Order Review Label',
        defaultMessage: 'Order Summary'
    },
    checkoutLabel: {
        id: 'payment.paymentMessages.checkoutLabel',
        description: 'Checkout Label',
        defaultMessage: 'Checkout'
    }
});

export default paymentMessages;
