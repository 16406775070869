export const getNowTimestamp = () => Date.now();

const localeTimeFormatMapping = {
    ja: 'LL'
};

export function getLanguageFromLocale(locale) {
    const language = locale.split('-')[0];
    return (language || 'en');
}

export function getLocaleCurrencyFormat(language) {
    const format = localeTimeFormatMapping[language];
    return (format || 'DD MMM YYYY');
}
