import { fromJS } from 'immutable';
import { RETRIEVED_BRAND_CONFIG, RETRIEVED_BANNER_CONFIG } from 'capi/redux';
import { parseCatalogs } from './catalogUtils';
import { getBrandData } from '../app/bootstrap';
import { getNowTimestamp } from '../utils/timeUtils';




const brandReducer = (state = fromJS(getBrandData()), action) => {
    switch (action.type) {
        
        case RETRIEVED_BRAND_CONFIG.PREFERENCES: {
            const {
                notifyOffers,
                loyaltyProgram,
                physical,
                gdpr,
                cardholderAgreement,
                profanityDetectionEnabled
            } = action.data;
            return state.merge({
                preferencesConfigLoadTime: getNowTimestamp(),
                notifyOffers: fromJS(notifyOffers),
                loyaltyProgram: fromJS(loyaltyProgram),
                physical: fromJS(physical),
                gdpr: fromJS(gdpr),
                cardholderAgreement: fromJS(cardholderAgreement),
                isProfanityDetectionEnabled: profanityDetectionEnabled
            });
        }
        case RETRIEVED_BRAND_CONFIG.BALANCE: {
            const {
                balancePhoneNumber,
                balanceUrl
            } = action.data;
            return state.merge({
                balancePhoneNumber,
                balanceUrl
            });
        }
        case RETRIEVED_BRAND_CONFIG.CATALOG: {
            const {
                catalogs,
                supportsDigitalCards,
                supportsPlasticCards,
                electronicCurrencyList,
                plasticCurrencyList,
                requireRecipientEmailForPhysicalGift
            } = action.data;
            return state.merge({
                catalogs: parseCatalogs(catalogs),
                supportsDigitalCards,
                supportsPlasticCards,
                electronicCurrencyList,
                plasticCurrencyList,
                requireRecipientEmailForPhysicalGift
            });
        }
        case RETRIEVED_BRAND_CONFIG.META: {
            const {
                brandLegalName,
                replyToEmailDisplayName
            } = action.data;
            return state.merge({
                brandLegalName,
                replyToEmailDisplayName
            });
        }
        case RETRIEVED_BRAND_CONFIG.PAYMENT: {
            const {
                allowedBillingCountries,
                payViaWebDropInEnabled
            } = action.data;
            return state.merge({
                allowedBillingCountries,
                paymentWidgetEnabledCurrencies: payViaWebDropInEnabled
            });
        }
        case RETRIEVED_BRAND_CONFIG.LIMITS: {
            const {
                cartMaxLineItems,
                cartMaxNumPlasticCards
            } = action.data;
            return state.merge({
                cartMaxLineItems,
                cartMaxNumPlasticCards
            });
        }
        case RETRIEVED_BRAND_CONFIG.FEATURES: {
            const {
                IsShareViaLinkDeliveryOptionEnabled,
                isShareViaLinkDefaultDeliveryOption
            } = action.data;
            return state.merge({
                features: {
                    IsShareViaLinkDeliveryOptionEnabled,
                    isShareViaLinkDefaultDeliveryOption
                }
            });
        }
        case RETRIEVED_BANNER_CONFIG: {
            const bannerData = action.data;
            return state.merge({ bannerData });
        }
        default:
    }
    return state;
};

export default brandReducer;
