import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl, intlShape } from 'react-intl';
import {
    Typography, Card, Stack
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import promoMessages from './messages/promoMessages';

const PromoCard = (props) => {
    const {
        email,
        price,
        img,
        intl
    } = props;

    return (
        <>
            <Card className="applied-coupon-card promo-card">
                <Stack direction='row' className='coupon-card applied-coupon-desc promo-coupon-card'>
                    <Stack className='coupon-card-image' direction='column'>
                        <Stack direction='row' className='promo-info-container'>
                            <img width='60px' src={img} alt='free card' className='promo-card-img' />
                            <ArrowForwardIcon className='promo-card-arrow' />
                            <Typography className='promo-email'>
                                {email}
                            </Typography>
                        </Stack>
                        <div className='coupon-price-section'>
                            <Typography className='coupon-price-value'>
                                {price}
                            </Typography>
                            <Typography className='coupon-price-summary'>{intl.formatMessage(promoMessages.freeTitle)}</Typography>
                        </div>
                    </Stack>
                </Stack>
            </Card>
        </>
    );
};

PromoCard.propTypes = {
    email: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    intl: intlShape.isRequired
};

export default (injectIntl(PromoCard));
