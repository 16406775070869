import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List as IList } from 'immutable';
import CarouselContainer from './Carousel/CarouselContainer';
import { useFlow } from '../routing/FlowProvider';
import { getAllCategories, getFaceplateGroups } from './landingPageSelectors';
import { getCategorySpecificFaceplates } from '../utils/landingPageUtils';
import { getBannerData } from '../brand/brandSelectors';
import BannerContainer from '../banner/bannerContainer';

const LandingPage = (props) => {
    const {
        stepName, categories, faceplateGroups
    } = props;
    const { goToNextStep } = useFlow();
    const handleSubmit = () => goToNextStep(stepName);

    const catagoriesList = categories.map(category => (
        <CarouselContainer
            {...props}
            handleSubmit={handleSubmit}
            key={category}
            category={category}
            categoryCount={categories.count()}
            noOfFaceplates={getCategorySpecificFaceplates(faceplateGroups, category).size}
        />
    ));

    return (
        <div className='Carousel'>
            <BannerContainer
                {...props}
                catagoriesList={catagoriesList}
            />
        </div>
    );
};

LandingPage.propTypes = {
    stepName: PropTypes.string.isRequired,
    categories: PropTypes.instanceOf(IList).isRequired,
    faceplateGroups: PropTypes.instanceOf(IList).isRequired,
    bannerData: PropTypes.instanceOf(IList).isRequired
};

const mapStateToProps = state => ({
    categories: getAllCategories(state),
    faceplateGroups: getFaceplateGroups(state),
    bannerData: getBannerData(state)
});

export default connect(mapStateToProps)(LandingPage);
