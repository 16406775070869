import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getTestEnvMessages } from '../intl/intlSelectors';

export const TestEnvironmentBanner = (props) => {
    const {
        message
    } = props;

    return (
        <Fragment>
            <div className="testing-env-banner">
                <div className="testing-env-banner-container">
                    <div className="testing-env-message">
                        {message}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

TestEnvironmentBanner.defaultProps = {
    message: null
};

TestEnvironmentBanner.propTypes = {
    message: PropTypes.string
};

const mapStateToProps = state => ({
    message: getTestEnvMessages(state)
});

export const ConnectedTestEnvironmentBanner = connect(mapStateToProps)(TestEnvironmentBanner);
