import React, { useEffect, useState } from 'react';


const windowSize = Component => (
    (props) => {
        const [dimensions, setDimensions] = useState({
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth
        });

        useEffect(() => {
            function handleResize() {
                setDimensions({
                    windowHeight: window.innerHeight,
                    windowWidth: window.innerWidth
                });
            }
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);
        return (
            <Component
                {...props}
                {...dimensions}
            />
        );
    }
);

export default windowSize;
