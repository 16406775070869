import { fromJS, Map as IMap, List as IList } from 'immutable';
import { createSelector } from 'reselect';

export const getAddedProducts = state => state.get('addedProducts', IMap());

export const getEGiftCards = createSelector(
    getAddedProducts,
    addedProducts => fromJS(addedProducts).get('eGiftCards', IList())
);

export const getSelectedItemAmount = createSelector(
    getEGiftCards,
    eGiftCards => eGiftCards.get(0, IMap()).get('cardValue')
);

export const getEGiftCard = createSelector(
    [
        getEGiftCards,
        (state, facePlateCode) => facePlateCode
    ],
    (eGiftCards, facePlateCode) => {
        const eGiftCard = eGiftCards && eGiftCards.get(facePlateCode);
        if (eGiftCard) {
            return {
                cardValue: eGiftCard.get('cardValue'),
                faceplate: eGiftCard.get('faceplate').toJS(),
                selfBuy: eGiftCard.get('selfBuy'),
                recipientEmail: eGiftCard.get('recipientEmail'),
                recipientName: eGiftCard.get('recipientName'),
                personalMessage: eGiftCard.get('personalMessage'),
                senderName: eGiftCard.get('senderName'),
                senderEmail: eGiftCard.get('senderEmail'),
                yourName: eGiftCard.get('yourName'),
                yourEmail: eGiftCard.get('yourEmail'),
                userConsent: eGiftCard.get('userConsent'),
                eGiftShareOption: eGiftCard.get('eGiftCardShareOption')
            };
        }
        return {};
    }
);

export const getSenderEmail = createSelector(
    getEGiftCards,
    eGiftCards => eGiftCards.get(0, IMap()).get('senderEmail') || eGiftCards.get(0, IMap()).get('yourEmail')
);
