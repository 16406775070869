import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

const InputGroupTitle = ({ className, titleText }) => (
    <Typography component='div' className={className} sx={{ textAlign: 'left' }}>
        <Divider textAlign='left'>
            {titleText}
        </Divider>
    </Typography>
);

InputGroupTitle.propTypes = {
    className: PropTypes.string.isRequired,
    titleText: PropTypes.string.isRequired
};

export default InputGroupTitle;
