import {
    select,
    put,
    call,
    takeEvery
} from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { fetchFaceplateGroups, RETRIEVED_FACEPLATE_GROUPS } from 'capi/redux';

import {
    REQUEST_FACEPLATE_GROUPS,
    FACEPLATE_PREFETCH_IMAGES,
    setFaceplateGroups,
    faceplatePrefetchImages
} from './landingPageModule';
import { getIntlLocale } from '../intl/intlSelectors';
import { convertFaceplateGroupsToMap } from '../utils/landingPageUtils';
import { loadingAction } from '../loader/loaderModule';

export function* requestFaceplate(action) {
    const params = action.params || {};
    const intlLanguageCode = yield select(getIntlLocale);
    const languageCode = params.languageCode || intlLanguageCode;
    yield put(fetchFaceplateGroups({ languageCode }));
}

function* handleRetrievedFaceplates(action) {
    const { faceplateGroups } = action;
    const immutableFaceplateGroups = fromJS(faceplateGroups);
    yield put(setFaceplateGroups(immutableFaceplateGroups));

    yield put(loadingAction({ isLoading: false }));

    const formattedFaceplateGroups = yield call(convertFaceplateGroupsToMap, immutableFaceplateGroups);
    yield put(faceplatePrefetchImages(formattedFaceplateGroups));
}

function* prefetchImages(action) {
    yield action.faceplates.forEach((faceplate) => {
        const image = new Image();
        image.src = faceplate.get('imageURL');
    });

    
}

export function* requestFaceplatesWatcher() {
    
    yield put(loadingAction({ isLoading: true }));
    yield takeEvery(REQUEST_FACEPLATE_GROUPS, requestFaceplate);
}

export function* faceplatesReceivedWatcher() {
    yield takeEvery(RETRIEVED_FACEPLATE_GROUPS, handleRetrievedFaceplates);
}

export function* faceplatePrefetchImagesWatcher() {
    yield takeEvery(FACEPLATE_PREFETCH_IMAGES, prefetchImages);
}
