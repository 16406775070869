import { fromJS } from 'immutable';

import { PAYMENT_PLUS_PAYMENT_TYPE, paymentPlusPaymentDataHandler } from './paymentPlusSerializers';
import { orderCompletedSegmentData, orderFailedSegmentData } from '../segment/segmentSelectors';

export const PAYMENT_STATUS = fromJS({
    CONFIG_PROCESSING: 'config_processing',
    CONFIG_SUCCESS: 'config_success',
    CONFIG_FAILED: 'config_failed',
    INIT: 'init',
    ORDERING: 'ordering',
    FAILED: 'failed',
    SUCCESS: 'success',
    FAILED_PROMO: 'promo_failed'
});

export const PROMO_ERROR_RESPONSES = {
    PROMO_SOLD_OUT: 'PROMO_SOLD_OUT',
    PROMO_LIMITED: 'PROMO_LIMITED'
};

export const PAYMENT_METHODS = {
    paymentPlus: PAYMENT_PLUS_PAYMENT_TYPE
};

export const initialState = fromJS({
    status: null,
    numAttempts: 0,
    response: null
});

export const paymentMethodDataHandlers = fromJS({
    [PAYMENT_PLUS_PAYMENT_TYPE]: paymentPlusPaymentDataHandler
});


export const PROCESS_PAYMENT = '@@storefront/PROCESS_PAYMENT';
export const START_SEND_ORDER = '@@storefront/START_SEND_ORDER';
export const PAYMENT_SUCCESS = '@@storefront/PAYMENT_SUCCESS';
export const PAYMENT_FAILED = '@@storefront/PAYMENT_FAILED';
export const PAYMENT_FAILED_PROMO = '@@storefront/PAYMENT_FAILED_PROMO';
export const PAYMENT_COMPLETE = '@@storefront/PAYMENT_COMPLETE';
export const SET_CHECKOUT_ID = '@@storefront/SET_CHECKOUT_ID';
export const PROCESS_INITIATE_CONFIG = '@@storefront/PROCESS_INITIATE_CONFIG';
export const INITIATE_CONFIG_SUCCESS = '@@storefront/INITIATE_CONFIG_SUCCESS';
export const INITIATE_CONFIG_FAILED = '@@storefront/INITIATE_CONFIG_FAILED';
export const RESET_PAYMENT_STATUS = '@@storefront/RESET_PAYMENT_STATUS';



export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PROCESS_PAYMENT:
            return state.merge({
                status: PAYMENT_STATUS.get('INIT'),
                numAttempts: state.get('numAttempts') + 1
            });
        case START_SEND_ORDER:
            return state.merge({ status: PAYMENT_STATUS.get('ORDERING') });
        case PAYMENT_SUCCESS:
            return state.merge({ status: PAYMENT_STATUS.get('SUCCESS') });
        case PAYMENT_FAILED:
            return state.merge({
                status: PAYMENT_STATUS.get('FAILED'),
                response: action.payload
            });
        case PAYMENT_FAILED_PROMO:
            return state.merge({
                status: PAYMENT_STATUS.get('FAILED_PROMO'),
                response: action.payload
            });
        case PAYMENT_COMPLETE:
            return initialState;
        case SET_CHECKOUT_ID:
            return state.set('checkoutId', action.checkoutId);
        case PROCESS_INITIATE_CONFIG:
            return state.set('status', PAYMENT_STATUS.get('CONFIG_PROCESSING'));
        case INITIATE_CONFIG_FAILED:
            return state.set('status', PAYMENT_STATUS.get('CONFIG_FAILED'));
        case INITIATE_CONFIG_SUCCESS:
            return state.merge({
                status: PAYMENT_STATUS.get('CONFIG_SUCCESS'),
                checkoutId: action.payload.checkoutId,
                merchantId: action.payload.merchantId,
                cartAmount: action.payload.grandTotal
            });
        case RESET_PAYMENT_STATUS:
            return state.set('status', initialState.get('status'));
        default:
    }
    return state;
}



export function processPayment(paymentMethod, paymentData) {
    return {
        type: PROCESS_PAYMENT,
        paymentMethod,
        paymentData
    };
}

export function startSendOrder() {
    return {
        type: START_SEND_ORDER
    };
}

export function paymentSuccess(resp) {
    return {
        type: PAYMENT_SUCCESS,
        orderDetails: resp,
        meta: {
            segment: {
                eventAction: 'Order Completed',
                eventData: orderCompletedSegmentData(resp)
            }
        }
    };
}

export function paymentComplete() {
    return {
        type: PAYMENT_COMPLETE
    };
}

export function paymentFailed(err, segmentEventData = {}) {
    return {
        type: PAYMENT_FAILED,
        payload: err,
        meta: {
            segment: {
                eventAction: 'Order failed',
                eventData: orderFailedSegmentData(segmentEventData)
            }
        }
    };
}

export function paymentFailedPromo(err, segmentEventData = {}) {
    return {
        type: PAYMENT_FAILED_PROMO,
        payload: err,
        meta: {
            segment: {
                eventAction: 'Order failed due to promo',
                eventData: orderFailedSegmentData(segmentEventData)
            }
        }
    };
}

export function processInitiateConfig(data) {
    return {
        type: PROCESS_INITIATE_CONFIG,
        data
    };
}

export function initiateConfigRequestSuccess(payload) {
    return {
        type: INITIATE_CONFIG_SUCCESS,
        payload
    };
}

export function initiateConfigRequestFailed() {
    return {
        type: INITIATE_CONFIG_FAILED
    };
}

export function setCheckoutId(checkoutId) {
    return {
        type: SET_CHECKOUT_ID,
        checkoutId
    };
}

export function resetPaymentStatus() {
    return {
        type: RESET_PAYMENT_STATUS
    };
}
