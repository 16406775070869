import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List as IList } from 'immutable';
import Carousel from 'react-multi-carousel';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';

import { getCategorySpecificFaceplates } from '../../utils/landingPageUtils';
import { getFaceplateGroups } from '../landingPageSelectors';
import { saveSelectedCard } from '../landingPageModule';
import { flushProductDetails } from '../../giftCardDetailsPage/productDetailsModule';
import windowSize from '../../utils/windowSize';
import {
    responsive, setCarouselTransform,
    setInitialCarouselTransform, setImageWidthAndMargin
} from './CarouselConfig';
import { deviceType } from '../../utils/deviceTypeUtils';

import { getLoaderStatus } from '../../loader/loaderSelector';

const FaceplateCarousel = (props) => {
    const {
        category, categoryCount, isLoading, faceplates, windowWidth, handleSubmit, saveSelectedCardAction, flushProductDetailsAction
    } = props;

    const [additionalTransfrom, setadditionalTransfrom] = useState(0);
    const carouselRef = useRef();
    const prevSlideNumber = useRef(0);
    const carouselEndReached = useRef(false);
    const device = deviceType(windowWidth);

    useEffect(() => {
        setInitialCarouselTransform(windowWidth, faceplates.size, setadditionalTransfrom);
    }, [faceplates, windowWidth]);

    const faceplateOnClick = (faceplate) => {
        saveSelectedCardAction(category, faceplate);
        flushProductDetailsAction();
        handleSubmit();
    };

    return (
        categoryCount > 1 ? (
            <Carousel
                ref={carouselRef}
                partialVisible
                itemClass="image-item"
                responsive={responsive}
                additionalTransfrom={additionalTransfrom}
                containerClass="carousel-container"
                beforeChange={(nextSlideNumber) => {
                    const setTransform = (forwardVal, reverseVal, endVal) => {
                        setCarouselTransform(
                            carouselRef,
                            prevSlideNumber, carouselEndReached,
                            device, faceplates.size,
                            nextSlideNumber,
                            forwardVal, reverseVal, endVal,
                            additionalTransfrom, setadditionalTransfrom
                        );
                    };

                    switch (device) {
                        case 'mobile':
                            setTransform(-32, 32, -16);
                            break;
                        case 'tab':
                            setTransform(-48, 48, -16);
                            break;
                        default:
                            setTransform(-64, 64, -16);
                            break;
                    }
                }}
            >
                {faceplates.map((faceplate, index) => (
                    <Button
                        onClick={() => faceplateOnClick(faceplate)}
                        style={setImageWidthAndMargin(faceplates.size, windowWidth, index, device)}
                        key={faceplate.get('code')}
                        className='faceplate-wrapper'
                    >
                        {isLoading ? (
                            <Skeleton animation="wave" variant="rounded" className="skeletonclass" />
                        ) : (
                            <img
                                className='faceplates'
                                alt={faceplate.get('altText')}
                                src={faceplate.get('imageURL')}
                                key={faceplate.get('code')}
                            />
                        )}
                    </Button>
                ))
                }
            </Carousel>
        )
            : (
                <div className='categoryImage'>
                    <Grid
                        container
                        spacing={{
                            xs: 0, sm: 1, md: 2, lg: 3
                        }}
                        columns={{
                            xs: 12, sm: 12, md: 12, lg: 12
                        }}
                        className='Grid-Container'
                    >
                        {faceplates.map(faceplate => (
                            <Grid
                                item
                                xs={6}
                                sm={4}
                                md={3}
                                lg={3}
                                key={faceplate.get('code')}
                                className='Grid-Item'
                            >
                                <Button className='categoryImage-item' onClick={() => faceplateOnClick(faceplate)}>
                                    <img
                                        src={faceplate.get('imageURL')}
                                        alt={faceplate.get('altText')}
                                        key={faceplate.get('code')}
                                        className='Image-item'
                                    />
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            )
    );
};


FaceplateCarousel.propTypes = {
    category: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    categoryCount: PropTypes.number.isRequired,
    saveSelectedCardAction: PropTypes.func.isRequired,
    flushProductDetailsAction: PropTypes.func.isRequired,
    windowWidth: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    faceplates: PropTypes.instanceOf(IList).isRequired
};

const mapStateToProps = (state, { category }) => {
    const faceplateGroups = getFaceplateGroups(state);
    const faceplates = getCategorySpecificFaceplates(faceplateGroups, category);
    const isLoading = getLoaderStatus(state);
    return {
        faceplates,
        isLoading
    };
};

const mapDispatchToProps = {
    saveSelectedCardAction: saveSelectedCard,
    flushProductDetailsAction: flushProductDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(FaceplateCarousel));
