import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment/min/locales';
import { Map as IMap, fromJS } from 'immutable';

import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';


import { customAmountNumberFormatter } from '../utils/numberUtils';
import orderStatusMessages from './messages/orderStatusMessages';
import FormLayout from '../formLayout/FormLayout';
import { useFlow } from '../routing/FlowProvider';
import { getIntlLocale, getIntlCurrencyCode } from '../intl/intlSelectors';
import { clearCompletedSteps } from '../routing/routingModule';
import { getLanguageFromLocale, getLocaleCurrencyFormat } from '../utils/timeUtils';
import { trackExternalLinkClick } from '../segment/segmentModule';
import dynamicLinkMessages from '../brand/messages/DynamicLinkMessages';
import ExternalLink from '../primitive/Link';


let OrderSummary = ({
    intl,
    amount,
    orderNumber,
    locale,
    currencyCode,
    trackExternalLinkClickAction
}) => {
    
    const language = getLanguageFromLocale(locale);
    const format = getLocaleCurrencyFormat(language);
    moment.locale(language);
    const dateNow = new Date();
    const successDate = moment(dateNow).format(format);
    const successTime = moment(dateNow).format('HH:mm');
    const linkOnClick = (urlName, url) => trackExternalLinkClickAction({ urlName, url, type: 'Order Success Page' });
    return (
        <React.Fragment>
            <CardContent>
                <Box className='orderDetails'>
                    <Paper elevation={0}>
                        <Typography className='orderIdLabel'>
                            { intl.formatMessage(orderStatusMessages.orderIdLabel) }
                        </Typography>
                        <Typography className='orderId'>{orderNumber}</Typography>
                    </Paper>
                    <Paper elevation={0}>
                        <Typography className='orderTime'>{successTime}</Typography>
                        <Typography className='orderDate'>{successDate}</Typography>
                    </Paper>
                </Box>
                <Box className='orderDetails'>
                    <Typography className='orderAmountLabel'>
                        { intl.formatMessage(orderStatusMessages.amountPaidLabel) }
                    </Typography>
                    <Typography className='orderAmount'>
                        {customAmountNumberFormatter({ locale }, amount, currencyCode, true)}
                    </Typography>
                </Box>
            </CardContent>

            <Typography className='orderActions'>
                <ExternalLink
                    className='orderAction'
                    href={intl.formatMessage(dynamicLinkMessages.customerSupport)}
                    text={intl.formatMessage(orderStatusMessages.howToRedeemLabel)}
                    onClick={
                        () => linkOnClick('How to redeem', intl.formatMessage(dynamicLinkMessages.customerSupport))
                    }
                />
                <ExternalLink
                    className='orderAction'
                    href={intl.formatMessage(dynamicLinkMessages.faq)}
                    text={intl.formatMessage(orderStatusMessages.faqsLabel)}
                    onClick={() => linkOnClick('FAQs', intl.formatMessage(dynamicLinkMessages.faq))}
                />
                <ExternalLink
                    className='orderAction orderStatusCustomerSupport'
                    href={intl.formatMessage(dynamicLinkMessages.inquiryList)}
                    text={intl.formatMessage(orderStatusMessages.customerSupportLabel)}
                    onClick={() => linkOnClick('Customer Support', intl.formatMessage(dynamicLinkMessages.inquiryList))}
                />
            </Typography>
        </React.Fragment>
    );
};

OrderSummary.propTypes = {
    intl: intlShape.isRequired,
    amount: PropTypes.number.isRequired,
    orderNumber: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
    trackExternalLinkClickAction: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    locale: getIntlLocale(state),
    currencyCode: getIntlCurrencyCode(state)
});

OrderSummary = connect(
    mapStateToProps,
    { trackExternalLinkClickAction: trackExternalLinkClick }
)(OrderSummary);

const getFormTitle = intl => (
    <React.Fragment>
        <Alert
            className='successMsg'
            icon={<CheckIcon fontSize='inherit' className='successIcon' />}
            severity='success'
        >
            { intl.formatMessage(orderStatusMessages.orderSuccessTitle) }
        </Alert>
        <Paper className='successInfo'>
            {intl.formatMessage(orderStatusMessages.orderSuccessMessage)}
        </Paper>
    </React.Fragment>
);

const OrderSuccess = ({
    intl,
    stepName,
    clearCompletedStepsAction
}) => {
    const { goToNextStep, goToPreviousStep } = useFlow();
    const location = useLocation();
    const displayData = location.state;
    const {
        amount,
        orderData: { orderNumber } = {},
        bonusCards
    } = displayData || {};

    if (!orderNumber) {
        
        
        goToPreviousStep(stepName, { shouldPushHistory: false });
    } else {
                clearCompletedStepsAction();
    }

    return (
        <FormLayout
            successCard
            formTitle={getFormTitle(intl)}
            formLeftPanelTitle={intl.formatMessage(orderStatusMessages.orderSummaryLabel)}
            displayData={displayData}
            cartQualifiesForPromo={bonusCards.length > 0}
            bonusCard={bonusCards ? fromJS(bonusCards[0]) : IMap()}
        >
            <Box container display='flex' alignItems='center' flexDirection='column'>
                <Card className='orderSummaryContainer'>
                    <OrderSummary intl={intl} amount={amount} orderNumber={orderNumber} />
                </Card>
                <Stack
                    spacing={4}
                    direction='row'
                    alignItems='center'
                    justifyContent='center'
                    className='form-button-group'
                >
                    <Button
                        className='buyAnotherButton'
                        variant='contained'
                        onClick={() => goToNextStep(stepName, { shouldPushHistory: false })}
                    >
                        <p className='buyAnotherText'>
                            {intl.formatMessage(orderStatusMessages.buyAnotherButtonLabel)}
                        </p>
                    </Button>
                </Stack>
            </Box>
        </FormLayout>
    );
};


OrderSuccess.propTypes = {
    intl: intlShape.isRequired,
    
    stepName: PropTypes.string.isRequired,
    
    clearCompletedStepsAction: PropTypes.func.isRequired
};

const mapDispatchToProps = { clearCompletedStepsAction: clearCompletedSteps };

export default connect(null, mapDispatchToProps)(injectIntl(OrderSuccess));
