import { Map as IMap } from 'immutable';


const initialState = {
    isLoading: false
};


export const LOADING_STATUS = 'LOADING_STATUS';


const loadingReducer = (state = IMap(initialState), action) => {
    switch (action.type) {
        case LOADING_STATUS: {
            const { payload: { isLoading = false } = {} } = action;
            return state.set('isLoading', isLoading);
        }
        default:
    }
    return state;
};


export const loadingAction = payload => ({
    type: LOADING_STATUS,
    payload
});

export default loadingReducer;
