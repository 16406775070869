import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import { ToggleButton, ToggleButtonGroup, Box } from '@mui/material';

import { DELIVERY_TYPE, DELIVERY_TYPES } from '../constants/constants';
import giftCardDetailsPageMessages from './messages/giftCardDetailsPageMessages';

const DeliveryTypesWithText = ({ intl, deliveryType, handleChange }) => {
    const { register } = useFormContext();

    const getDeliveryTypeText = (type) => {
        switch (type) {
            case DELIVERY_TYPES.GIFT:
                return intl.formatMessage(giftCardDetailsPageMessages.sendAsGiftToggle);
            case DELIVERY_TYPES.SELF:
                return intl.formatMessage(giftCardDetailsPageMessages.buyForSelfToggle);
            default:
                return '';
        }
    };

    return (
        <>
            <Box className='toggle-desc-wrapper'>
                <ToggleButtonGroup
                    value={deliveryType}
                    {...register(DELIVERY_TYPE, { required: true })}
                    exclusive
                    onChange={handleChange}
                    className='toggleButtonGroup'
                >

                    {
                        Object.keys(DELIVERY_TYPES).map(type => (
                            <ToggleButton
                                key={type}
                                value={DELIVERY_TYPES[type]}
                                label={DELIVERY_TYPES[type]}
                                className='deliveryToggleButton'
                                classes={{
                                    selected: 'SelectedToggleButton'
                                }}
                            >
                                <p className='deliveryToggleButtonText'>{getDeliveryTypeText(type)}</p>
                            </ToggleButton>
                        ))
                    }

                </ToggleButtonGroup>
            </Box>
        </>
    );
};

DeliveryTypesWithText.propTypes = {
    deliveryType: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    intl: intlShape.isRequired
};

export default injectIntl(DeliveryTypesWithText);
