import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Map as IMap } from 'immutable';

import {
    Typography, Card, Stack
} from '@mui/material';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import PromoCard from './PromoCard';
import { getSenderEmail } from '../giftCardDetailsPage/productDetailsSelector';
import { getActivePromoCode, getFirstBonusCard } from '../promo/promoSelectors';
import { getIntlCurrencyCode, getIntlLocale } from '../intl/intlSelectors';
import { customAmountNumberFormatter } from '../utils/numberUtils';
import promoMessages from './messages/promoMessages';

const AppliedCoupon = (props) => {
    const {
        intl,
        bonusCard,
        senderEmail,
        activePromoCode,
        locale,
        currencyCode
    } = props;

    const bonusCardValue = customAmountNumberFormatter(
        { locale },
        bonusCard.get('cardValue', 0),
        currencyCode
    );
    const bonusCardImg = () => {
        const faceplate = bonusCard.get('faceplate', IMap());
        return faceplate ? faceplate.get('imageURL') : '';
    };
    const promoCardDescString = `<div class="promo-desc" style="width: fit-content"> ${intl.formatMessage(promoMessages.freePromoCardTitle, { number: bonusCardValue })} </div>`;
    return (
        <>
            <Stack direction='row' className='applied-coupon-heading'>
                <LocalOfferOutlinedIcon />
                <Typography className='applied-coupon-title'>
                    {intl.formatMessage(promoMessages.offerAndCouponTitle)}
                </Typography>
            </Stack>
            <div className="applied-coupon-card-container">
                <Card className="applied-coupon-card">
                    <Stack direction='row' className='applied-coupon-desc'>
                        <Stack direction='column'>
                            <Stack direction='row'>
                                <CheckOutlinedIcon />
                                <Typography className='promo-name'>
                                    {activePromoCode}
                                </Typography>
                            </Stack>
                            <Typography dangerouslySetInnerHTML={{ __html: promoCardDescString }} />
                        </Stack>
                    </Stack>
                </Card>
                <PromoCard
                    intl={intl}
                    email={senderEmail}
                    price={bonusCardValue}
                    img={bonusCardImg()}
                />
            </div>
        </>
    );
};

AppliedCoupon.propTypes = {
    intl: intlShape.isRequired,
    locale: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
    bonusCard: PropTypes.instanceOf(IMap()).isRequired,
    senderEmail: PropTypes.string.isRequired,
    activePromoCode: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    bonusCard: getFirstBonusCard(state),
    senderEmail: getSenderEmail(state),
    activePromoCode: getActivePromoCode(state),
    locale: getIntlLocale(state),
    currencyCode: getIntlCurrencyCode(state)
});

export default connect(mapStateToProps)(injectIntl(AppliedCoupon));
