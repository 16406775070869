import { Map as IMap } from 'immutable';
import { createSelector } from 'reselect';
import { Big } from 'big.js';

import { getBrandCode } from '../brand/brandSelectors';
import { getIntlLocale, getIntlCurrencyCode } from '../intl/intlSelectors';
import { getEGiftCards } from '../giftCardDetailsPage/productDetailsSelector';
import { getActivePromoCodeWithRewards } from '../promo/promoSelectors';


export const getPayment = state => state.get('payment');

export const getCheckoutId = createSelector(
    getPayment,
    payment => payment.get('checkoutId')
);

export const getMerchantId = createSelector(
    getPayment,
    payment => payment.get('merchantId')
);


export const getCartAmount = createSelector(
    getPayment,
    payment => payment.get('cartAmount')
);

export const getPaymentStatus = createSelector(
    getPayment,
    payment => payment.get('status')
);

export const getConsumerFees = createSelector(
    getPayment,
    payment => payment.get('fees')
);


export const getInitiateConfigData = createSelector(
    [getCheckoutId, getMerchantId, getCartAmount, getPaymentStatus],
    (checkoutId, merchantId, grandTotal, status) => (
        IMap({
            checkoutId,
            merchantId,
            grandTotal,
            status
        })
    )
);

export const sumOfFaceValues = items => (
    items.reduce((acc, item) => (
        new Big(acc).add(new Big(item.get('cardValue')))
    ), 0)
);

export const getGrandTotal = createSelector(
    getEGiftCards,
    eGiftCards => sumOfFaceValues(eGiftCards)
);

export const getOrderMetaData = createSelector(
    [
        getBrandCode,
        getGrandTotal,
        getIntlCurrencyCode,
        getIntlLocale,
        getActivePromoCodeWithRewards,
        getCheckoutId
    ],
    (brandCode, amount, currencyCode, locale, promoCode, checkoutId) => (
        IMap({
            brandCode,
            amount,
            currencyCode,
            locale,
            promoCode,
            checkoutId
        })
    )
);
