import React from 'react';
import PropTypes from 'prop-types';
import {
    ButtonBase, Select, MenuItem, InputLabel
} from '@mui/material';
import { intlShape } from 'react-intl';
import classnames from 'classnames';
import { List as IList } from 'immutable';
import countryToCurrency from 'country-to-currency';
import { getCurrencySymbol } from '../intl/getCurrencySymbol';
import currencyLocaleMessages from './messages/LocaleCurrencyMessages';

const Flag = ({ countryCode, styleNames = {} }) => <i className={classnames(styleNames, 'flag', countryCode)} />;

Flag.defaultProps = {
    styleNames: {}
};

Flag.propTypes = {
    countryCode: PropTypes.string.isRequired,
    styleNames: PropTypes.object
};

const Country = ({ lang, country }) => new Intl.DisplayNames(lang, { type: 'region' }).of(country.toUpperCase());

Country.propTypes = {
    lang: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired
};


const CountryPicker = ({
    intl, locale, setLocale, fullySupportedLocales,
    selectedLocale, setCurrencyCode
}) => {
    const handleChange = (event) => {
        const selectedLocaleValue = event.target.value;
        const selectedCountryCode = selectedLocaleValue.split('-')[1];
        const selectedCurrecnyCode = countryToCurrency[selectedCountryCode.toUpperCase()];

        setLocale(selectedLocaleValue);
        setCurrencyCode(selectedCurrecnyCode);
    };
    return (
        <ButtonBase className='form-inputs-base'>
            <InputLabel className='form-inputs-label' id="label">
                {intl.formatMessage(currencyLocaleMessages.countryLabelText)}
            </InputLabel>
            <Select
                onChange={handleChange}
                displayEmpty
                className='country-dropdown'
                value={locale === '' ? selectedLocale : locale}
            >
                {
                    fullySupportedLocales.map((supportedLocale) => {
                        const [language, countryCode] = supportedLocale.split('-');
                        const currencyCode = countryToCurrency[countryCode.toUpperCase()];

                        return (
                            <MenuItem key={supportedLocale} value={supportedLocale}>
                                <Flag countryCode={countryCode} />
                                <Country lang={language} country={countryCode} />
                                {' - '}
                                {currencyCode}
                                {' ('}
                                {getCurrencySymbol(intl, currencyCode).prefix}
                                {getCurrencySymbol(intl, currencyCode).suffix}
                                {') '}
                            </MenuItem>
                        );
                    })
                }
            </Select>
        </ButtonBase>
    );
};

CountryPicker.propTypes = {
    intl: intlShape.isRequired,
    locale: PropTypes.string.isRequired,
    setLocale: PropTypes.func.isRequired,
    setCurrencyCode: PropTypes.func.isRequired,
    selectedLocale: PropTypes.string.isRequired,
    fullySupportedLocales: PropTypes.instanceOf(IList).isRequired
};

export default CountryPicker;
