import paymentWidgetMessages from '../payment/messages/paymentWidgetMessages';

const CREDITCARD_HOLDERNAME = 'creditCard.holderName';
const CREDITCARD_EXPIRYDATE_TITLE = 'creditCard.expiryDateField.title';
const CREDITCARD_CVV_TITLE = 'creditCard.cvcField.title';
const CREDITCARD_NUMBER_TITLE = 'creditCard.numberField.title';
const CREDITCARD_HOLDER__PLACEHOLDER = 'creditCard.holderName.placeholder';
const CREDITCARD_NUMBER_INVALID = 'error.va.sf-cc-num.01';
const CREDITCARD_NUMBER_LENGTH_INVALID = 'error.va.sf-cc-num.04';
const CREDITCARD_EXPIRYDATE_OLDER = 'error.va.sf-cc-dat.01';
const CREDITCARD_EXPIRYDATE_INVALID = 'error.va.sf-cc-dat.02';
const CREDITCARD_CVV_MISSING = 'error.va.sf-cc-cvc.01';
const CREDITCARD_HOLDERNAME_INVALID = 'creditCard.holderName.invalid';

export const translations = (intl) => {
    const adyen = {
        [CREDITCARD_HOLDER__PLACEHOLDER]: intl.formatMessage(paymentWidgetMessages.cardHolderPlaceHolder),
        [CREDITCARD_HOLDERNAME]: intl.formatMessage(paymentWidgetMessages.cardHolderName),
        [CREDITCARD_EXPIRYDATE_TITLE]: intl.formatMessage(paymentWidgetMessages.expiryDateField),
        [CREDITCARD_CVV_TITLE]: intl.formatMessage(paymentWidgetMessages.cvvField),
        [CREDITCARD_NUMBER_TITLE]: intl.formatMessage(paymentWidgetMessages.cardNumberField),
        [CREDITCARD_NUMBER_INVALID]: intl.formatMessage(paymentWidgetMessages.cardNumberInvalid),
        [CREDITCARD_NUMBER_LENGTH_INVALID]: intl.formatMessage(paymentWidgetMessages.cardNumberLengthInvalid),
        [CREDITCARD_EXPIRYDATE_OLDER]: intl.formatMessage(paymentWidgetMessages.expiryDateOlder),
        [CREDITCARD_EXPIRYDATE_INVALID]: intl.formatMessage(paymentWidgetMessages.expiryDateInvalid),
        [CREDITCARD_CVV_MISSING]: intl.formatMessage(paymentWidgetMessages.cvvMissing),
        [CREDITCARD_HOLDERNAME_INVALID]: intl.formatMessage(paymentWidgetMessages.cardHolderNameMissing)
    };
    return { adyen };
};
