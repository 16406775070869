import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { getShareableLinkDetails } from 'capi';
import { useLocation } from 'react-router-dom';
import { getCurrencySymbol } from '../intl/getCurrencySymbol';
import { getCapiClient } from '../session/sessionSelectors';
import { logError } from '../utils/errorUtils';
import { getBrandName } from '../brand/brandSelectors';
import orderStatusMessages from '../order/messages/orderStatusMessages';
import giftCardDetailsPageMessages from './messages/giftCardDetailsPageMessages';
import {
    PROCESSING_CLASS_NAME, READY_CLASS_NAME, SHARED_CLASS_NAME, STILL_PROCESSING_CLASS_NAME
} from '../constants/constants';
import { getBrowserName } from '../utils/deviceTypeUtils';
import { formatCurrencyAmount } from '../utils/numberUtils';

const SharableLinkCard = (props) => {
    const {
        capi,
        intl,
        brandName
    } = props;

    const location = useLocation();
    const displayData = location.state;
    const {
        orderData: { orderNumber } = {}
    } = displayData || {};


    const [sharableLink, setSharableLink] = useState('');
    const [copied, setCopied] = useState(false);
    const [sharableLinkItem, setSharableLinkItem] = useState(null);
    const [internalStatus, setInternalStatus] = useState('');
    const [sharedViaEmail, setSharedViaEmail] = useState('');
    const [mobShareButtonClicked, setMobShareButtonClicked] = useState(false);
    const sharableLinkInputRef = useRef(null);
    const sharableLinkContentRef = useRef(null);

    const getStatusMessage = (status) => {
        if (status === STILL_PROCESSING_CLASS_NAME) {
            return orderStatusMessages.linkStillLoading;
        }
        if (status === PROCESSING_CLASS_NAME) {
            return orderStatusMessages.linkLoading;
        }
        return orderStatusMessages.linkIsReady;
    };

    const CardHeader = ({ status }) => (
        <div className='egift-sharable-header-wrapper'>
            {status !== READY_CLASS_NAME && <span className={`egift-sharable-header-icon ${status}`} />}
            <p data-testid='header-name' className={`egift-sharable-header-name ${status}`}>{intl.formatMessage(getStatusMessage(status))}</p>
            {status === READY_CLASS_NAME && <span className={`egift-sharable-header-icon ${status}`} />}
        </div>
    );

    CardHeader.propTypes = {
        status: PropTypes.string.isRequired
    };

    const handleShare = async () => {
        const isAndroid = /android/i.test(navigator.userAgent);
        const osName = isAndroid ? 'Android' : 'IOS';
        const browserName = getBrowserName(navigator);

        try {
            setMobShareButtonClicked(true);
            if (navigator.share) {
                const isFirefox = /firefox/i.test(navigator.userAgent);
                const formattedAmountWithCurrency = formatCurrencyAmount(intl, sharableLinkItem.original_amount, sharableLinkItem.original_amount_currency);

                const message = (sharableLinkItem.message ? intl.formatMessage(orderStatusMessages.shareableLinkShareCustomMessgText, {
                    name: sharableLinkItem.recipient.first_name,
                    message: sharableLinkItem.message,
                    brandName,
                    emoji: '🎁',
                    amount: formattedAmountWithCurrency,
                    url: sharableLinkItem.short_url
                }) : intl.formatMessage(orderStatusMessages.shareableLinkShareDefaultText, {
                    name: sharableLinkItem.recipient.first_name,
                    brandName,
                    emoji: '🎁',
                    amount: formattedAmountWithCurrency,
                    url: sharableLinkItem.short_url
                }));

                if (isAndroid) {
                    if (isFirefox) {
                        await navigator.share({
                            url: sharableLinkItem.short_url
                        });
                    } else {
                        await navigator.share({
                            text: message
                        });
                    }
                } else {
                    await navigator.share({
                        text: message
                    });
                }
            } else {
                logError(`Web Share API not supported - browser: ${browserName} - Brand: ${brandName} - OS: ${osName}`);
            }
        } catch (error) {
            logError(`Try catch block for navigator share - browser: ${browserName} - Brand: ${brandName} - OS: ${osName} - with error ${error}`);
        }
    };

    const SharableLinkCardMobile = ({ status }) => (
        <button className={`sl-card-wrpr-mobile ${status} ${mobShareButtonClicked ? 'sl-mob-share-clicked' : ''}`} onClick={handleShare} type='button' data-testid='mob-share'>
            {mobShareButtonClicked ? (
                status === READY_CLASS_NAME && <span className={`sl-card-icon-mobile ${status} sl-mob-share-clicked`} />
            ) : (
                status !== READY_CLASS_NAME && <span className={`sl-card-icon-mobile ${status}`} />
            )}
            <p data-testid='mob-msg' className={`egift-sharable-name-mobile ${status}`}>{intl.formatMessage(getStatusMessage(status))}</p>
            {status === READY_CLASS_NAME && <span className={`sl-card-icon-mobile ${status}`} />}
        </button>
    );

    SharableLinkCardMobile.propTypes = {
        status: PropTypes.string.isRequired
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(sharableLink).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 5000);
        }).catch((error) => {
            logError(error);
        });
    };

    const fetchSharableLinkItems = async () => {
        try {
            const response = await getShareableLinkDetails(capi, orderNumber);
            if (response && response.shareable_details.length > 0) {
                const recipientName = displayData.eGiftCards[0].recipientName.replace(/\s+/g, '');
                const shareableItem = response.shareable_details.filter((item) => {
                    const responseRecipientName = (item.recipient.first_name + item.recipient.last_name).replace(/\s+/g, '');
                    return responseRecipientName === recipientName;
                });
                setSharableLinkItem(shareableItem[0]);
                setSharableLink(shareableItem[0].short_url);
                setInternalStatus(READY_CLASS_NAME);
            } else {
                setInternalStatus(STILL_PROCESSING_CLASS_NAME);
            }
        } catch (error) {
            setInternalStatus(STILL_PROCESSING_CLASS_NAME);
            logError(error);
        }
    };

    const sendEmail = () => {
        const currencySymbol = getCurrencySymbol(intl, sharableLinkItem.original_amount_currency);

        const subject = intl.formatMessage(orderStatusMessages.emailShareSubjectText, {
            Rname: sharableLinkItem.recipient.first_name,
            Sname: sharableLinkItem.from_name,
            brandName
        });
        const body = (sharableLinkItem.message ? intl.formatMessage(orderStatusMessages.emailShareCustomMessgText, {
            name: sharableLinkItem.recipient.first_name,
            messg: sharableLinkItem.message,
            brandName,
            emoji: '🎁',
            amount: `${currencySymbol.prefix}${Number(sharableLinkItem.original_amount)}${currencySymbol.suffix}`,
            url: sharableLinkItem.short_url
        }) : intl.formatMessage(orderStatusMessages.emailShareDefaultText, {
            name: sharableLinkItem.recipient.first_name,
            brandName,
            emoji: '🎁',
            amount: `${currencySymbol.prefix}${Number(sharableLinkItem.original_amount)}${currencySymbol.suffix}`,
            url: sharableLinkItem.short_url
        }));
        const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
        setTimeout(() => {
            setSharedViaEmail(SHARED_CLASS_NAME);
        }, 1000);
    };

    useEffect(() => {
        if (orderNumber) {
            fetchSharableLinkItems();
            setTimeout(() => {
                if (!sharableLinkContentRef.current) {
                    fetchSharableLinkItems();
                    setInternalStatus(STILL_PROCESSING_CLASS_NAME);
                }
            }, 5000);
            setInternalStatus(PROCESSING_CLASS_NAME);
        }
    }, []);

    useEffect(() => {
        if (sharableLink && sharableLinkContentRef.current) {
            sharableLinkContentRef.current.textContent = sharableLink;
        }
    }, [sharableLink]);

    return (
        <div className={`shareable-card-wrapper ${internalStatus}`}>
            {/* card header */}
            <CardHeader status={internalStatus} />
            {/* card body */}
            {
                internalStatus === STILL_PROCESSING_CLASS_NAME ? (
                    <div className={`sl-res-delay-msg-wrpr ${internalStatus}`}>
                        <p className='sl-res-delay-msg-p'>
                            <span>{intl.formatMessage(orderStatusMessages.continueBrowsing)}</span>
                            <br />
                            {intl.formatMessage(orderStatusMessages.stillLoadingInfo)}
                        </p>
                    </div>
                )
                    : (
                        <div className='egift-card-sharable-copy-wrpr' data-testid='send-email-wrpr'>
                            <div className={`egift-email-img-wrpr ${sharedViaEmail}`}>
                                <button type='button' className={`egift-email-icoon-ovel-wrpr ${internalStatus}`} onClick={sendEmail}>
                                    <button
                                        className={`sl-egift-email-icon ${internalStatus}`}
                                        type='button'
                                        data-testid='send-email'
                                    />
                                </button>
                                <button type='button' onClick={sendEmail} className={`sl-egift-email-name ${internalStatus}`}>{intl.formatMessage(giftCardDetailsPageMessages.sendViaEmailText)}</button>
                            </div>
                            <div className='sl-copy-input-wrpr'>
                                <div className='sl-input-content' ref={sharableLinkContentRef} />
                                <input
                                    type='text'
                                    value={sharableLink}
                                    onChange={e => setSharableLink(e.target.value)}
                                    ref={sharableLinkInputRef}
                                    readOnly
                                />
                                {!sharableLink && <div className='undulating-bar' />}
                                <button className={internalStatus} onClick={handleCopy} type='button' name='copy'>
                                    { copied ? intl.formatMessage(orderStatusMessages.copiedData) : intl.formatMessage(orderStatusMessages.copyData) }
                                </button>
                            </div>
                        </div>
                    )
            }
            {}
            <SharableLinkCardMobile status={internalStatus} />
            {}
            {
                internalStatus !== STILL_PROCESSING_CLASS_NAME ? (
                    <div className='egift-sharable-info-wrpr'>
                        <span className={`sl-info-icon ${internalStatus}`} />
                        <p className={internalStatus} data-testid='footer-msg'>
                            {internalStatus === READY_CLASS_NAME
                                ? intl.formatMessage(orderStatusMessages.linkSentToInbox)
                                : intl.formatMessage(orderStatusMessages.receiveLinkShortly)}
                        </p>
                    </div>
                )
                    : (
                        <div className={`egift-sharable-info-wrpr ${internalStatus}`}>
                            <span className={`sl-info-icon ${internalStatus}`} />
                            <p className='sl-res-delay-msg-p' data-testid='footer-msg-still-p'>
                                <span>{intl.formatMessage(orderStatusMessages.continueBrowsing)}</span>
                                {intl.formatMessage(orderStatusMessages.stillLoadingInfo)}
                            </p>
                        </div>
                    )
            }
        </div>
    );
};

const mapStateToProps = state => ({
    capi: getCapiClient(state),
    brandName: getBrandName(state)
});

SharableLinkCard.propTypes = {
    intl: intlShape.isRequired,
    capi: PropTypes.func.isRequired,
    brandName: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(injectIntl(SharableLinkCard));
