import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import { ToggleButton, ToggleButtonGroup, Box } from '@mui/material';
import { CardGiftcard, CreditCard, Check as CheckIcon } from '@mui/icons-material';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { DELIVERY_TYPE, DELIVERY_TYPES } from '../constants/constants';
import giftCardDetailsPageMessages from './messages/giftCardDetailsPageMessages';

const DeliveryTypesDescription = ({ intl, deliveryType }) => {
    const selectedClassName = ' selectedToggleDescriptor';
    const giftClassName = `leftToggleDescriptor${deliveryType === DELIVERY_TYPES.GIFT ? selectedClassName : ''}`;
    const selfClassName = `RightToggleDescriptor${deliveryType === DELIVERY_TYPES.SELF ? selectedClassName : ''}`;

    return (
        <Grid container className='toggle-title-container'>
            <Grid xs={6} sm={6} md={6} lg={6} item>
                <Typography className={giftClassName}>
                    {intl.formatMessage(giftCardDetailsPageMessages.sendAsGiftToggle)}
                </Typography>
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={6} item>
                <Typography className={selfClassName}>
                    {intl.formatMessage(giftCardDetailsPageMessages.buyForSelfToggle)}
                </Typography>
            </Grid>
        </Grid>
    );
};

DeliveryTypesDescription.propTypes = {
    intl: intlShape.isRequired,
    deliveryType: PropTypes.string.isRequired
};

const DeliveryTypesWithIcons = ({
    intl, deliveryType, handleChange
}) => {
    const { register } = useFormContext();

    const getDeliveryTypeIcon = (type, isSelected) => {
        let TypeIcon = null;
        switch (type) {
            case DELIVERY_TYPES.SELF:
                TypeIcon = <CreditCard className='cardIcon' />;
                break;
            case DELIVERY_TYPES.GIFT:
                TypeIcon = <CardGiftcard className='giftCardIcon' />;
                break;
            default:
                return '';
        }

        return (
            <React.Fragment>
                {isSelected && <CheckIcon className='check-icon-Mui' />}
                {TypeIcon}
            </React.Fragment>
        );
    };

    return (
        <>
            <Box className='toggle-desc-wrapper'>
                <ToggleButtonGroup
                    value={deliveryType}
                    {...register(DELIVERY_TYPE, { required: true })}
                    exclusive
                    onChange={handleChange}
                    className='toggleButtonGroup'
                >
                    {
                        Object.keys(DELIVERY_TYPES).map(type => (
                            <ToggleButton
                                key={type}
                                value={DELIVERY_TYPES[type]}
                                label={DELIVERY_TYPES[type]}
                                className='deliveryToggleButton'
                                classes={{
                                    selected: 'SelectedToggleButton'
                                }}
                            >
                                {getDeliveryTypeIcon(type, deliveryType === DELIVERY_TYPES[type])}
                            </ToggleButton>
                        ))
                    }

                </ToggleButtonGroup>

                <DeliveryTypesDescription intl={intl} deliveryType={deliveryType} />
            </Box>
        </>
    );
};

DeliveryTypesWithIcons.propTypes = {
    deliveryType: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    intl: intlShape.isRequired
};

export default injectIntl(DeliveryTypesWithIcons);
