import { fromJS, Map as IMap } from 'immutable';
import isProfane from './isProfane';
import validateFieldsByRules from './ruleValidator';

const validate = (values, props) => {
    const {
        intl,
        isProfanityDetectionEnabled,
        fieldName,
        errorMessage
    } = props;
    let constraints = IMap();
    constraints = constraints.merge(fromJS({
        [fieldName]: [
            isProfane(
                errorMessage, isProfanityDetectionEnabled
            )
        ]
    }));
    return validateFieldsByRules(values, constraints, intl);
};

export { validate };
