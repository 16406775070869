import { defineMessages } from 'react-intl';

const loaderMessages = defineMessages({
    loadingTitle: {
        id: 'loaderMessages.loadingTitle',
        description: 'Loading Title Label',
        defaultMessage: 'Loading...'
    }
});

export default loaderMessages;
