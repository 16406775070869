import { fromJS } from 'immutable';

export const PAYMENT_PLUS_PAYMENT_TYPE = 'paymentPlus';

export const paymentPlusPaymentDataHandler = paymentData => (
    fromJS(paymentData).merge({
        approved: true,
        paymentMethod: PAYMENT_PLUS_PAYMENT_TYPE,
        CAPI_URI_VERSION: 'v5'
    })
);
