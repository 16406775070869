import { defineMessages } from 'react-intl';

const errorMessages = defineMessages({
    errorRefreshTitle: {
        id: 'error.ErrorRefresh.title',
        description: 'Top-level heading displayed when an unrecoverable error occurs and the user should refresh the page and retry',
        defaultMessage: 'Error'
    },
    errorRefreshDescription: {
        id: 'error.ErrorRefresh.description',
        description: 'Description displayed when an unrecoverable error occurs and the user should refresh the page and retry',
        defaultMessage: 'Please refresh the page and try again.'
    },
    errorRefreshContactUs: {
        id: 'error.ErrorRefresh.contactUs',
        description: 'Description displayed when an unrecoverable error occurs and the user should refresh the page and retry',
        defaultMessage: 'For questions, please contact [Customer Support]({link}).'
    },
    notFoundTitle: {
        id: 'error.errorNotFound.title',
        description: 'Top-level heading displayed when a page is not found for a given URL',
        defaultMessage: 'Page Not Found'
    },
    notFoundErrorDescription: {
        id: 'error.errorNotFound.errorDescription',
        description: 'Description displayed when a page is not found for a given URL',
        defaultMessage: 'We can\'t seem to find the page you\'re looking for.'
    },
    notFoundErrorCode: {
        id: 'error.errorNotFound.errorCode',
        description: 'Error code displayed when a page is not found for a given URL',
        defaultMessage: 'Error code: '
    },
    notFoundErrorHomeUrl: {
        id: 'error.errorNotFound.homeUrl',
        description: 'Home url text displayed when a page is not found for a given URL',
        defaultMessage: 'Try checking the url, or go back to our [home page]({link}).'
    },
    errorNoRetryTitle: {
        id: 'error.ErrorNoRetry.title',
        description: 'Top-level heading displayed when an unrecoverable error occurs and the user should check their order status before any retry',
        defaultMessage: 'Error'
    },
    errorNoRetryDescription: {
        id: 'error.ErrorNoRetry.description',
        description: 'Description displayed when an unrecoverable error occurs and the user should check their order status before any retry',
        defaultMessage: 'If you were placing an order, please check your email for a confirmation before attempting to place the order again.'
    },
    errorNoRetryContactUs: {
        id: 'error.ErrorNoRetry.contactUs',
        description: 'Description displayed when an unrecoverable error occurs and the user should check their order status before any retry',
        defaultMessage: 'For questions, please contact [Customer Support]({link}).'
    }
});

export default errorMessages;
