import { List as IList, Map as IMap } from 'immutable';
import { createSelector } from 'reselect';

export const getFaceplatesInfo = state => state.get('faceplateInfo', IMap());

export const getFaceplateGroups = createSelector(
    getFaceplatesInfo,
    faceplateInfo => faceplateInfo.get('faceplateGroups', IList())
);

export const getSelectedFaceplate = createSelector(
    getFaceplatesInfo,
    faceplateInfo => faceplateInfo.get('selectedFaceplate', IMap())
);

export const getSelectedFaceplateCode = createSelector(
    getSelectedFaceplate,
    faceplate => faceplate.get('code')
);

export const getSelectedFaceplateCategory = createSelector(
    getSelectedFaceplate,
    faceplate => faceplate.get('category')
);

export const getAllCategories = createSelector(
    getFaceplateGroups,
    faceplateGroups => faceplateGroups.map(data => data.get('name'))
);
