import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment-timezone';
import { Map as IMap } from 'immutable';

import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import ConsentCheckbox from './ConsentCheckbox';
import giftCardDetailsPageMessages from './messages/giftCardDetailsPageMessages';
import validationMessages from './messages/productDetailsValidationMessages';
import DeliveryAddress from './DeliveryAddress';
import FormLayout from '../formLayout/FormLayout';
import { useFlow } from '../routing/FlowProvider';
import { handleProceedToPay, segmentProductViewed } from './productDetailsModule';
import { customAmountUnformatter } from '../utils/numberUtils';
import * as ProductDetailsSelector from './productDetailsSelector';
import { getIntlLocale, getIntlCurrencyCode } from '../intl/intlSelectors';
import DenomSuggestions from './DenomSuggestions';
import { DELIVERY_TYPES } from '../constants/constants';
import { getSelectedFaceplate } from '../landingPage/landingPageSelectors';
import { textTrim } from '../utils/textUtils';
import ExternalLink from '../primitive/Link';
import dynamicLinkMessages from '../brand/messages/DynamicLinkMessages';
import { trackExternalLinkClick } from '../segment/segmentModule';
import { getShareViaLinkEnabled } from '../brand/brandSelectors';

export const ErrorsAlert = ({
    numberOfErrors, intl, open, handleClose
}) => {
    const alertMessage = intl.formatMessage(validationMessages.errorsAlert, { numberOfErrors });

    return (
        <Snackbar className='errorsAlertContainer' autoHideDuration={3000} open={open} onClose={handleClose}>
            <Alert
                className='errorsAlert'
                severity='error'
                variant='filled'
                elevation={5}
            >
                {alertMessage}
            </Alert>
        </Snackbar>
    );
};

ErrorsAlert.propTypes = {
    handleClose: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    numberOfErrors: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired
};

let GiftCardDetails = ({
    intl,
    stepName,
    handleProceedToPayAction,
    segmentProductViewedAction,
    trackExternalLinkClickAction,
    productDetails,
    shareViaLinkEnabled,
    locale,
    selectedFaceplate,
    currencyCode
}) => {
    const { goToNextStep } = useFlow();
    const formValidation = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        shouldFocusError: true
    });
    const { handleSubmit, formState: { errors, isSubmitSuccessful }, reset } = formValidation;
    const [numberOfErrors, setNumberOfErrors] = React.useState(0);
    const [displayErrorsAlert, setDisplayErrorsAlert] = React.useState(false);

    const handleErrorsAlertClose = () => {
        setDisplayErrorsAlert(false);
    };

    React.useEffect(() => {
        segmentProductViewedAction(
            selectedFaceplate,
            selectedFaceplate.get('category')
        );
    }, []);

    React.useEffect(() => {
        if (isSubmitSuccessful === false) {
            reset({}, { keepValues: true });
        }
    }, [isSubmitSuccessful, locale]);


    const postValidation = (validProductDetails) => {
        const {
            customAmount, deliveryType, recipientEmail, recipientName, personalMessage,
            senderName, senderEmail, yourName, yourEmail, userConsentCheckBox, eGiftCardShareOption
        } = validProductDetails;
        const cardValue = Number(customAmountUnformatter({ locale }, currencyCode, customAmount));
        const selfBuy = deliveryType && deliveryType === DELIVERY_TYPES.SELF;
        const userConsent = userConsentCheckBox && userConsentCheckBox === 'true';
        const deliveryDate = moment.utc().toISOString();
        handleProceedToPayAction({
            eGiftCards: [
                {
                    cardValue,
                    selfBuy,
                    faceplate: selectedFaceplate,
                    recipientEmail,
                    recipientName: textTrim(recipientName),
                    personalMessage,
                    senderName: textTrim(senderName),
                    senderEmail,
                    yourName: textTrim(yourName),
                    yourEmail,
                    userConsent,
                    deliveryDate,
                    quantity: 1, 
                    eGiftCardShareOption
                }
            ],
            currencyCode
        });
        goToNextStep(stepName);
    };

    const handleError = () => {
        const errorsLength = !!errors && Object.keys(errors).length;
        setNumberOfErrors(errorsLength);
        setDisplayErrorsAlert(true);
    };

    return (
        <Grid container>
            <Grid container>
                <FormProvider {...formValidation}>
                    <form className='formGroup pdpForm' onSubmit={handleSubmit(postValidation, handleError)} noValidate>
                        <Grid item>
                            <DenomSuggestions productDetails={productDetails} locale={locale} />
                        </Grid>

                        <Grid item>
                            <DeliveryAddress
                                productDetails={productDetails}
                                shareViaLinkEnabled={shareViaLinkEnabled}
                                locale={locale}
                            />
                        </Grid>

                        <Grid item>
                            <ExternalLink
                                text={intl.formatMessage(giftCardDetailsPageMessages.giftCardDetailsLink)}
                                href={intl.formatMessage(dynamicLinkMessages.customerSupport)}
                                className='GiftCardLinkActionLinks'
                                onClick={() => trackExternalLinkClickAction({
                                    urlName: 'gift card details',
                                    type: 'pdp form consent',
                                    url: intl.formatMessage(dynamicLinkMessages.customerSupport)
                                })}
                            />
                        </Grid>

                        <Grid item>
                            <ConsentCheckbox productDetails={productDetails} />
                            <div className='formCheckboxConsentTextemail'>
                                {intl.formatMessage(giftCardDetailsPageMessages.receiveEmails)}
                            </div>
                        </Grid>
                        <Grid item>
                            <div className='formCheckboxConsentTextemail'>
                                {intl.formatMessage(giftCardDetailsPageMessages.doublecheckEmail)}
                            </div>
                        </Grid>
                        <Grid item>
                            <ExternalLink
                                text={intl.formatMessage(giftCardDetailsPageMessages.privacyPolicyLink)}
                                href={intl.formatMessage(dynamicLinkMessages.privacyPolicy)}
                                className='privacy-policy-link'
                                onClick={() => trackExternalLinkClickAction({
                                    urlName: 'privacy policy',
                                    type: 'pdp form consent',
                                    url: intl.formatMessage(dynamicLinkMessages.privacyPolicy)
                                })}
                            />
                        </Grid>

                        <Grid item>
                            <ExternalLink
                                text={intl.formatMessage(giftCardDetailsPageMessages.termsAndConditionsLink)}
                                href={intl.formatMessage(dynamicLinkMessages.termsAndCondition)}
                                className='terms-and-conditions-link'
                                onClick={() => trackExternalLinkClickAction({
                                    urlName: 'terms & conditions',
                                    type: 'pdp form consent',
                                    url: intl.formatMessage(dynamicLinkMessages.termsAndCondition)
                                })}
                            />
                        </Grid>

                        <Grid item className='buttonGrid'>
                            <ErrorsAlert
                                numberOfErrors={numberOfErrors}
                                intl={intl}
                                open={displayErrorsAlert}
                                handleClose={handleErrorsAlertClose}
                            />

                            <Button
                                type='submit'
                                className='submitButton'
                                variant='contained'
                            >
                                <p className='submitButtonText'>
                                    {intl.formatMessage(giftCardDetailsPageMessages.proceedToPayButtonText)}
                                </p>
                            </Button>
                        </Grid>
                    </form>
                </FormProvider>
            </Grid>
        </Grid>
    );
};

GiftCardDetails.defaultProps = {
    productDetails: {}
};

GiftCardDetails.propTypes = {
    
    intl: intlShape.isRequired,
    
    stepName: PropTypes.string.isRequired,
    productDetails: PropTypes.object,
    handleProceedToPayAction: PropTypes.func.isRequired,
    segmentProductViewedAction: PropTypes.func.isRequired,
    shareViaLinkEnabled: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    
    selectedFaceplate: PropTypes.instanceOf(IMap).isRequired,
    currencyCode: PropTypes.string.isRequired,
    
    trackExternalLinkClickAction: PropTypes.func.isRequired
};

const GiftCardDetailsMapStateToProps = state => ({
    selectedFaceplate: getSelectedFaceplate(state),
    currencyCode: getIntlCurrencyCode(state)
});

GiftCardDetails = connect(
    GiftCardDetailsMapStateToProps,
    { trackExternalLinkClickAction: trackExternalLinkClick }
)(GiftCardDetails);

const ProductDetailsPage = ({
    intl, stepName, handleProceedToPayAction, segmentProductViewedAction, productDetails, shareViaLinkEnabled, locale
}) => (
    <FormLayout
        formTitle={intl.formatMessage(giftCardDetailsPageMessages.createAGiftCardTitle)}
        noDetailsCard
        enableBackBtn
        stepName={stepName}
    >
        <GiftCardDetails
            stepName={stepName}
            intl={intl}
            handleProceedToPayAction={handleProceedToPayAction}
            segmentProductViewedAction={segmentProductViewedAction}
            productDetails={productDetails}
            shareViaLinkEnabled={shareViaLinkEnabled}
            locale={locale}
        />
    </FormLayout>
);

ProductDetailsPage.defaultProps = {
    productDetails: {}
};

ProductDetailsPage.propTypes = {
    
    intl: intlShape.isRequired,
    
    stepName: PropTypes.string.isRequired,
    handleProceedToPayAction: PropTypes.func.isRequired,
    segmentProductViewedAction: PropTypes.func.isRequired,
    productDetails: PropTypes.object,
    shareViaLinkEnabled: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    productDetails: ProductDetailsSelector.getEGiftCard(state, 0),
    locale: getIntlLocale(state),
    shareViaLinkEnabled: getShareViaLinkEnabled(state)
});

const mapDispatchToProps = {
    handleProceedToPayAction: handleProceedToPay,
    segmentProductViewedAction: segmentProductViewed
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductDetailsPage));
