import React from 'react';
import {
    Provider,
    connect,
    useSelector
} from 'react-redux';
import PropTypes from 'prop-types';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';
import { Helmet } from 'react-helmet';
import { injectIntl, intlShape } from 'react-intl';

import appStore from './appStore';
import CustomIntlProvider from '../intl/CustomIntlProvider';
import FlowRoute from '../routing/FlowRoute';
import { history } from '../routing/routing';
import Header from '../header/HeaderContainer';
import headerMessages from '../header/message/HeaderMessage';
import Footer from '../footer/Footer';
import Loader from '../loader/loader';
import { getLoaderStatus } from '../loader/loaderSelector';
import { getIsWebView } from '../webView/webViewSelectors';
import ScrollToTopWrapper from '../primitive/ScrollToTopWrapper';
import { getEnv } from './bootstrap';
import { ConnectedTestEnvironmentBanner } from '../testEnvironment/TestEnvironmentBanner';
import { getBrandName } from '../brand/brandSelectors';

import {
    XS_BREAKPOINT_CUTOFF, SM_BREAKPOINT_CUTOFF,
    MD_BREAKPOINT_CUTOFF, LG_BREAKPOINT_CUTOFF,
    XL_BREAKPOINT_CUTOFF
} from '../utils/deviceTypeUtils';
import LocaleCurrencyPicker from '../localeCurrencyPicker/LocaleCurrencyPicker';


const theme = createTheme({
    breakpoints: {
        values: {
            xs: XS_BREAKPOINT_CUTOFF,
            sm: SM_BREAKPOINT_CUTOFF,
            md: MD_BREAKPOINT_CUTOFF,
            lg: LG_BREAKPOINT_CUTOFF,
            xl: XL_BREAKPOINT_CUTOFF
        }
    }
});

let App = (props) => {
    const {
        intl,
        brandName,
        isLoading
    } = props;
    const isWebView = useSelector(state => getIsWebView(state));
    const showTestEnv = getEnv() !== 'PROD';

    const [isOpen, setIsOpen] = React.useState(false);

    const callbackIsOpen = (isOpenStatus) => {
        setIsOpen(isOpenStatus);
    };

    const popupStatus = (isOpenStatus) => {
        setIsOpen(isOpenStatus);
    };

    return (
        <div>
            <StyledEngineProvider injectFirst>
                <div className="App">
                    <Helmet>
                        <title>{intl.formatMessage(headerMessages.pageTitle, { brandName })}</title>
                    </Helmet>
                    {}
                    <div className='header-wrapper'>
                        {showTestEnv && (
                            <ConnectedTestEnvironmentBanner />
                        )}
                        {!isWebView && <Header callbackIsOpen={callbackIsOpen} />}
                    </div>
                    <div className="app-body">
                        {isLoading && <Loader showTestEnv={showTestEnv} />}
                        <FlowRoute />
                        <Footer />
                    </div>
                    <LocaleCurrencyPicker isOpen={isOpen} popupStatus={popupStatus} />
                </div>
            </StyledEngineProvider>

        </div>
    );
};

App.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    brandName: PropTypes.string.isRequired
};

export const mapStateToProps = state => ({
    brandName: getBrandName(state),
    isLoading: getLoaderStatus(state)
});

App = connect(mapStateToProps)(injectIntl(App));

const ReduxApp = () => (
    <Provider store={appStore}>
        <CustomIntlProvider>
            <ThemeProvider theme={theme}>
                <Router history={history}>
                    {}
                    <ScrollToTopWrapper>
                        <App />
                    </ScrollToTopWrapper>
                </Router>
            </ThemeProvider>
        </CustomIntlProvider>
    </Provider>
);


export default ReduxApp;
