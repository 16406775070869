import Big from 'big.js';
import { getCurrencySymbol } from '../intl/getCurrencySymbol';
import currencyFractionMap from '../currency/data/currencyFractionMap';
import { currencyFormatConfig, currencyIntegerFormatConfig } from '../intl/intlModule';

export const isDecimal = price => price % 1 !== 0;

export const isIncomplete = number => (
    number.charAt(number.length - 1) === '.' || number.charAt(number.length - 2) === '.'
);

export const formatPrice = price => (
    isDecimal(price) ? parseFloat(price.toFixed(2)) : parseInt(price, 10)
);

export const stripCurrencySymbol = (intl, number, currency) => {
    const currencySymbol = getCurrencySymbol(intl, currency);
    const symbol = currencySymbol.prefix ? currencySymbol.prefix : currencySymbol.suffix;
    return number.replace(symbol, '').trim();
};

/**
 * fixDecimalFormat turns a 'Big' into a string to prep it for manipulation and translation.
 *
 * @param {Big|Number|String} value - number to be fixed to a position
 * @param {Number} digits - digits after decimal
 * @return {String} fixed value
 */
export const fixDecimalFormat = (value, digits = 2) => {
    if (!value) return '';
    return isDecimal(value) ? new Big(value).toFixed(digits) : new Big(value).toFixed(0);
};

export const getCurrencyFractionDigits = (currencyCode) => {
    const fractionDigits = currencyFractionMap.get(currencyCode, currencyFractionMap.get('DEFAULT'));
    return fractionDigits;
};

export const getFormattedAmountWithCurrency = (intl, number, currency, forceDecimal = false) => {
    const formatConfig = {};
    const currencyFractionDigits = getCurrencyFractionDigits(currency);
    const value = fixDecimalFormat(number, currencyFractionDigits);
    if (currency) { formatConfig.currency = currency; }
    if (isDecimal(value) || forceDecimal) {
        formatConfig.format = 'currency';
        formatConfig.minimumFractionDigits = currencyFractionDigits;
        formatConfig.maximumFractionDigits = currencyFractionDigits;
    } else {
        formatConfig.format = 'currencyInteger';
    }
    return intl.formatNumber(number, formatConfig);
};

export const formatAmountToString = amount => (
    isDecimal(amount) ? amount.toFixed(2) : amount.toString()
);

export const stripValue = (value, decimalChar = '', groupChar = '') => {
    const strippedValue = value.toString()
        .replace(new RegExp(`[${groupChar}]`, 'g'), '')
        .replace(new RegExp(`[${decimalChar}]`, 'g'), '.')
        .replace(/(-(?![0-9.])|[^0-9-.])/g, '');
    return strippedValue.match(/^\d*(?:\.\d{0,2})?/)[0];
};

/**
 * gets the group and decimal characters for the current locale
 * @param {Object} intl - intl object
 * @param {string} currency - currency code
 * @returns {{decimalChar: string, groupChar: string}} returns an object containing the decimal and group characters
 */
const getInternationalParts = (intl, currency) => {
    let decimalChar = '';
    let groupChar = '';
    new Intl.NumberFormat(intl.locale, { style: 'currency', currency })
        .formatToParts('1000.50').forEach(({ type, value }) => {
            if (type === 'decimal') { decimalChar = value; }
            if (type === 'group') { groupChar = value; }
        });
    return { decimalChar, groupChar };
};



export const customAmountUnformatter = (intl, currency, value) => {
    let val = value;
    let currencyFractionDigits;
    let fractionDigits;
    const intlParts = getInternationalParts(intl, currency);
    val = stripValue(val, intlParts.decimalChar, intlParts.groupChar);
    currencyFractionDigits = getCurrencyFractionDigits(currency);
    if (!currencyFractionDigits && val.toString().includes('.')) {
        return val.toString().split('.')[0];
    }
    if (isDecimal(val)) {
        currencyFractionDigits = getCurrencyFractionDigits(currency);
        fractionDigits = val.toString().split('.')[1].length;
        val = fixDecimalFormat(val, Math.min(currencyFractionDigits, fractionDigits));
    }
    return val;
};

export const normalizeInternationalCurrency = (intl, currency) => (
    value => customAmountUnformatter(intl, currency, value)
);

export const customAmountNumberFormatter = (intl, number, currency, grouping = false) => {
    if (!number) {
        return '';
    }

    let denomination = number.toString();

    let formatConfig = {
        style: 'currency',
        currency,
        useGrouping: grouping,
        ...currencyFormatConfig
    };
    const numberFraction = denomination.split('.');
    if (isIncomplete(denomination)) {
        if (denomination === '.') {
            denomination = `0${number}`;
        }
        formatConfig.minimumFractionDigits = 1;
    } else if (!isDecimal(denomination)) {
        formatConfig = { ...formatConfig, ...currencyIntegerFormatConfig };
    }

    return new Intl.NumberFormat(intl.locale, formatConfig)
        .formatToParts(denomination).map(({ type, value }) => {
            switch (type) {
                case 'fraction': return (value.length > numberFraction[1].length ? '' : value);
                // case 'currency': return '';
                case 'literal': return '';
                case 'group': return (grouping ? value : '');
                default: return value;
            }
        }).reduce((string, part) => string + part);
};

export const formatCurrencyAmount = (intl, number, currency, forceDecimal = false) => {
    const currencySymbol = getCurrencySymbol(intl, currency);
    const formattedAmountWithCurrency = getFormattedAmountWithCurrency(intl, number, currency, forceDecimal);

    return `${formattedAmountWithCurrency}${currencySymbol.suffix}`;
};
