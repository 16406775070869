export const RECIPIENT_EMAIL = 'recipientEmail';
export const RECIPIENT_NAME = 'recipientName';

export const SENDER_EMAIL = 'senderEmail';
export const SENDER_NAME = 'senderName';

export const YOUR_EMAIL = 'yourEmail';
export const YOUR_NAME = 'yourName';

export const USER_CONSENT = 'userConsentCheckBox';
export const EGIFT_CARD_SHARE_OPTION = 'eGiftCardShareOption';

export const PERSONAL_MESSAGE_MAX_LENGTH = 120;
export const PERSONAL_MESSAGE = 'personalMessage';

export const DELIVERY_TYPE = 'deliveryType';
export const DELIVERY_TYPES = Object.freeze({
    GIFT: 'GIFT',
    SELF: 'SELF'
});

export const CUSTOM_AMOUNT = 'customAmount';


export const WEBVIEW_KEY = 'from';
export const WEBVIEW_VALUE = 'mbapp';

export const JAPAN_LOCALE = 'ja-jp';

export const EMAIL_MAXLENGTH = 254;
export const CUSTOM_AMOUNT_MAXLENGTH = 10;


export const CONSUMER_PRODUCT_CODE = 'NORM';
export const BILLABLE_PRODUCT_FEATURE_CODE = 'ECOM';


export const PAYMENT_PLUS_RISK = 'PAYMENT_PLUS_RISK';


export const SEGMENT_IP = '0.0.0.0';

export const DEFAULT_CURRENCY_CODE = 'USD';


export const EGIFT_DELIVERY_EMAIL = 'email';
export const EGIFT_DELIVERY_SHAREABLE = 'sharable';


export const SHARED_CLASS_NAME = 'clicked-share-link';
export const PROCESSING_CLASS_NAME = 'processing-status';
export const STILL_PROCESSING_CLASS_NAME = 'still-processing-status';
export const READY_CLASS_NAME = 'ready-status';
export const ACTIVE_CLASS_NAME = 'active-not-shared-yet';
