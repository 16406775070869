import React from 'react';
import PropTypes from 'prop-types';
import {
    injectIntl,
    intlShape
} from 'react-intl';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Markdown from 'markdown-to-jsx';

import errorMessages from './errorMessages';
import { ROUTES, HOME_STEP } from '../routing/flowConstants';

const NotFound = ({ intl, errorCode }) => (
    <Box className='error-container'>
        <Typography className='error-desc'>
            {intl.formatMessage(errorMessages.notFoundErrorDescription)}
        </Typography>

        <Typography className='error-code'>
            {intl.formatMessage(errorMessages.notFoundErrorCode)}
            {errorCode}
        </Typography>

        <Typography className='error-check-url'>
            <Markdown>
                {
                    intl.formatMessage(
                        errorMessages.notFoundErrorHomeUrl,
                        { link: ROUTES[HOME_STEP] }
                    )
                }
            </Markdown>
        </Typography>
    </Box>
);

NotFound.propTypes = {
    intl: intlShape.isRequired,
    errorCode: PropTypes.number.isRequired
};

export default injectIntl(NotFound);
