import { fromJS } from 'immutable';

const commonLanguages = fromJS({
    

        fr: async () => (require('react-intl/locale-data/fr')),
    en: async () => (require('react-intl/locale-data/en')),
    es: async () => (require('react-intl/locale-data/es')),
    sv: async () => (require('react-intl/locale-data/sv')),
    de: async () => (require('react-intl/locale-data/de')),
    pt: async () => (require('react-intl/locale-data/pt')),
    ja: async () => (require('react-intl/locale-data/ja')),
    nl: async () => (require('react-intl/locale-data/nl'))
});

export async function dynamicLocaleImport(locale) {
    
    
    const language = locale.split('-')[0];
    if (commonLanguages.has(language)) {
        return commonLanguages.get(language)();
    }
    
    const languageFile = `react-intl/locale-data/${language}.js`;
    return (await import(languageFile)).default;
}
