import { Map as IMap } from 'immutable';
import { createSelector } from 'reselect';
import {
    getCapiSessionID,
    getCapiUrl,
    getCapiToken
} from 'capi/redux/immutable';
import { createCapiClient } from 'capi';

import { getIovation } from '../iovation/iovationModule';

export const getCapiClient = createSelector(
    getCapiSessionID, getCapiUrl, getCapiToken, getIovation,
    (sessionID, capiUrl, capiToken, iovation) => (
        createCapiClient(
            capiUrl,
            capiToken,
            sessionID,
            iovation.get('blackbox'),
            iovation.get('blackboxUrl'),
            'v2'
        )
    )
);

export const getStorefrontSession = state => state.get('session', IMap());

export const getStorefrontSessionKey = createSelector(
    getStorefrontSession,
    session => session.get('sessionKey')
);
