import {
    call, put, select, takeEvery
} from 'redux-saga/effects';
import { addLocaleData } from 'react-intl';

import countryToCurrency from 'country-to-currency';

import { getDefaultLocaleCode, getPreferredLocaleCode } from '../app/bootstrap';
import { updateSearch } from '../routing/routing';
import { getBrandCode, getSupportedLocales, getBrandElectronicCurrencyList } from '../brand/brandSelectors';
import { restrictedPagesForLocaleUpdate } from '../routing/flowConstants';

import { getCopy } from './intlGetCopy';
import {
    SET_LOCALE,
    SET_CURRENCY,
    updateIntlMessages,
    setIntlLocale,
    updateIntlCurrency,
    completeLanguageChange,
    intlCurrencyUpdated
} from './intlModule';
import {
    getIntl,
    getIntlLocale,
    formats
} from './intlSelectors';
import { loadingAction } from '../loader/loaderModule';

import { dynamicLocaleImport } from './dynamicLocaleImport';

export const getCurrencySupportedPreferredLocale = (preferredLocaleCode, supportedLocaleCodes) => {
    const preferredLocale = supportedLocaleCodes.includes(preferredLocaleCode) ? preferredLocaleCode : null;
    return preferredLocale;
};

export const getCurrencySupportedDefaultLocale = (defaultLocaleCode, supportedLocaleCodes) => {
    const isDefaultLocale = supportedLocaleCodes.includes(defaultLocaleCode) ? defaultLocaleCode : null;
    const defaultLocale = (isDefaultLocale === null) ? supportedLocaleCodes.get(0) : isDefaultLocale;
    return defaultLocale;
};

export const checkIsPageRestrictedForLocaleUpdate = () => {
    let pageName;
    if (window && window.location) {
        pageName = (window.location.pathname.split('/').pop());
        return restrictedPagesForLocaleUpdate.some(page => page.includes(pageName));
    }
    return false;
};

export const getLocale = (hasStoredIntlData, supportedLocaleCodes, intlLocale) => {
    const isPageRestrictedForLocaleUpdate = checkIsPageRestrictedForLocaleUpdate();

    const storedLocale = (hasStoredIntlData && supportedLocaleCodes.contains(intlLocale))
        ? intlLocale
        : null;

    if (storedLocale && isPageRestrictedForLocaleUpdate) {
        return storedLocale;
    }

    const preferredLocaleCode = getPreferredLocaleCode();
    const preferredLocale = getCurrencySupportedPreferredLocale(preferredLocaleCode, supportedLocaleCodes);

    const defaultLocaleCode = getCurrencySupportedDefaultLocale(getDefaultLocaleCode(), supportedLocaleCodes);

    return preferredLocale || defaultLocaleCode || 'en-us';
};

export const getCurrency = (locale) => {
    const countryCode = locale.split('-')[1];
    const currency = countryToCurrency[countryCode.toUpperCase()];
    return currency || 'USD';
};

export function* updateCurrency(currency) {
    const electronicCurrencyList = yield select(getBrandElectronicCurrencyList);
    const intlFormats = yield select(formats);

    
    if (electronicCurrencyList.includes(currency)) {
        yield put(updateIntlCurrency(intlFormats, currency));
        
        
        yield put(intlCurrencyUpdated());
    }
}

export function* changeLanguage({ locale = 'en-us' }) {
    const brandCode = yield select(getBrandCode);
    const messages = yield call(getCopy, brandCode, locale);
    const localeData = yield call(dynamicLocaleImport, locale);
    yield call(addLocaleData, localeData);
    yield put(updateIntlMessages(messages, locale));
    yield call(updateSearch, { locale });
    
    
    
    yield put(completeLanguageChange());
}

export function* changeCurrency({ currency = 'USD' }) {
    yield call(updateCurrency, currency);
}

export function* initializeIntl() {
    const intl = yield select(getIntl);
    const intlLocale = yield select(getIntlLocale);
    const supportedLocaleCodes = yield select(getSupportedLocales);
    
    
    
    const hasStoredIntlData = Boolean(intl && intl.formats);
    const locale = getLocale(hasStoredIntlData, supportedLocaleCodes, intlLocale);

    yield put(setIntlLocale(locale));
    
    
    const currency = getCurrency(locale);
    yield call(changeLanguage, { locale });
    yield call(changeCurrency, { currency });
}

export function* intlSagasWatcher() {
    yield put(loadingAction({ isLoading: true }));
    yield takeEvery(SET_LOCALE, changeLanguage);
    yield takeEvery(SET_CURRENCY, changeCurrency);
    yield put(loadingAction({ isLoading: false }));
}
