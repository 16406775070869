import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List as IList } from 'immutable';
import { useSearchParams } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { getFaceplateGroups } from '../landingPageSelectors';
import { saveSelectedCard } from '../landingPageModule';
import {
    getCategorySpecificFaceplates,
    isCategoryAvailable,
    textToCapitalize as captialize
} from '../../utils/landingPageUtils';
import BackButton from '../../primitive/buttons/BackButton';
import { useFlow } from '../../routing/FlowProvider';
import homePageMessages from '../assets/messages/homePageMessages';
import NotFound from '../../error/NotFound';
import { flushProductDetails } from '../../giftCardDetailsPage/productDetailsModule';

export function CategoryPage({
    intl, faceplateGroups, stepName, saveSelectedCardAction, flushProductDetailsAction
}) {
    const { goToNextStep } = useFlow();

    const [searchParams] = useSearchParams();
    const category = searchParams.get('category');

    const isCategory = useMemo(
        () => isCategoryAvailable(faceplateGroups, category),
        [faceplateGroups, category]
    );

    const faceplates = useMemo(
        () => getCategorySpecificFaceplates(faceplateGroups, category),
        [faceplateGroups, category]
    );

    const faceplateOnClick = (faceplate) => {
        saveSelectedCardAction(category, faceplate);
        flushProductDetailsAction();
        goToNextStep(stepName);
    };

    return !isCategory ? (<NotFound errorCode={404} />)
        : (
            <div className='categoryImage'>
                <BackButton stepName={stepName} className='categoryBackText' />

                <Typography className='categoryText'>
                    {homePageMessages[category]
                        ? captialize(intl.formatMessage(homePageMessages[category])) : captialize(category)}
                </Typography>

                <Grid
                    container
                    spacing={{
                        xs: 0, sm: 1, md: 2, lg: 3
                    }}
                    columns={{
                        xs: 12, sm: 12, md: 12, lg: 12
                    }}
                    className='Grid-Container'
                >
                    {faceplates.map(faceplate => (
                        <Grid
                            item
                            xs={6}
                            sm={4}
                            md={3}
                            lg={3}
                            key={faceplate.get('code')}
                            className='Grid-Item'
                        >
                            <Button className='categoryImage-item' onClick={() => faceplateOnClick(faceplate)}>
                                <img
                                    src={faceplate.get('imageURL')}
                                    alt={faceplate.get('altText')}
                                    key={faceplate.get('code')}
                                    className='Image-item'
                                />
                            </Button>
                        </Grid>
                    ))}
                </Grid>

            </div>
        );
}

CategoryPage.propTypes = {
    intl: intlShape.isRequired,
    faceplateGroups: PropTypes.instanceOf(IList).isRequired,
    
    saveSelectedCardAction: PropTypes.func.isRequired,
    flushProductDetailsAction: PropTypes.func.isRequired,
    
    stepName: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    faceplateGroups: getFaceplateGroups(state)
});

const mapDispatchToProps = {
    saveSelectedCardAction: saveSelectedCard,
    flushProductDetailsAction: flushProductDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CategoryPage));
