import axios from 'axios';

import { getDefaultLocaleCode, getStaticUrl } from '../app/bootstrap';
import { logAxiosError } from '../utils/errorUtils';

export const getMessagesFromUrl = async (url) => {
    try {
        const response = await axios.get(url);
        
        if (typeof response.data === 'object') {
            return response.data;
        }
    } catch (error) {
        logAxiosError(error);
        return error.response.status;
    }
    return 0;
};

export const getCopy = async (brandCode, locale, staticUrl = getStaticUrl()) => {
    let copy = {};

    if (staticUrl) {
        const localePath = locale.replace('-', '/');
        let defaultLocalePath = getDefaultLocaleCode() || 'en-us';
        defaultLocalePath = defaultLocalePath.replace('-', '/');
        const urls = [
            `${staticUrl}/messages/${brandCode}/${localePath}.json?version=20240101-5`,
            `${staticUrl}/messages/DEFAULT/${localePath}.json?version=20240101-5`,
            `${staticUrl}/messages/${brandCode}/${defaultLocalePath}.json?version=20240101-5`
        ];
        
                for (let i = 0; i < urls.length; i++) {
            const response = await getMessagesFromUrl(urls[i]);
            if (typeof response === 'object') {
                copy = response;
            }
            if (response !== 0 && response !== 404) {
                return copy;
            }
        }
    }
        return copy;
};
