import { defineMessages } from 'react-intl';

const currencyLocalMessages = defineMessages({
    learnMoreLink: {
        id: 'currencyLocalePicker.currencyLocaleMessages.learnMore',
        description: 'Learn More Link',
        defaultMessage: 'Set where you live and what language you speak.'
    },
    updateSettingsTitle: {
        id: 'currencyLocalePicker.currencyLocaleMessages.updateSettingsTitle',
        description: 'Update your settings',
        defaultMessage: 'Update your settings'
    },
    saveButtonText: {
        id: 'currencyLocalePicker.currencyLocaleMessages.save',
        description: 'Save button text',
        defaultMessage: 'Save'
    },
    cancelButtonText: {
        id: 'currencyLocalePicker.currencyLocaleMessages.cancel',
        description: 'Cancel button text',
        defaultMessage: 'Cancel'
    },
    countryLabelText: {
        id: 'currencyLocalePicker.currencyLocaleMessages.countryLabelText',
        description: 'Country label text for currency locale picker',
        defaultMessage: 'Country'
    },
    languageLabelText: {
        id: 'currencyLocalePicker.currencyLocaleMessages.languageLabelText',
        description: 'Langauge label text for currency locale picker',
        defaultMessage: 'Langauge'
    }
});

export default currencyLocalMessages;
