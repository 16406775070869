import { defineMessages } from 'react-intl';

const paymentWidgetMessages = defineMessages({
    firstNameLabel: {
        id: 'payment.paymentWidgetMessages.firstNameLabel',
        description: 'first name label',
        defaultMessage: 'First Name'
    },
    lastNameLabel: {
        id: 'payment.paymentWidgetMessages.lastNameLabel',
        description: 'last name label',
        defaultMessage: 'Last Name'
    },
    cardHolderName: {
        id: 'payment.paymentWidgetMessages.cardHolderName',
        description: 'Card Holder Name label',
        defaultMessage: '*Name on Card'
    },
    expiryDateField: {
        id: 'payment.paymentWidgetMessages.expiryDateField',
        description: 'Expiry Date label',
        defaultMessage: '*Expiry Date'
    },
    cvvField: {
        id: 'payment.paymentWidgetMessages.cvvField',
        description: 'CVV Field Label',
        defaultMessage: '*CVV'
    },
    cardNumberField: {
        id: 'payment.paymentWidgetMessages.cardNumberField',
        description: 'Card Number Field',
        defaultMessage: '*Card Number'
    },
    cardHolderPlaceHolder: {
        id: 'payment.paymentWidgetMessages.cardHolderPlaceHolder',
        description: 'Card Number Place holder',
        defaultMessage: 'John Doe'
    },
    paymentWidgetLoadingError: {
        id: 'payment.paymentWidgetMessages.paymentWidgetLoadingError',
        description: 'Error displayed if payment widget fails to load',
        defaultMessage: 'Couldn\'t proceed to payment. Please refresh and try again. If the issue still persists, please contact customer support.'
    },
    cardNumberInvalid: {
        id: 'payment.paymentWidgetMessages.cardNumberInvalid',
        description: 'Error displayed if credit card number is invalid',
        defaultMessage: 'Card number is not valid'
    },
    cardNumberLengthInvalid: {
        id: 'payment.paymentWidgetMessages.cardNumberLengthInvalid',
        description: 'Error displayed if credit card number length is invalid',
        defaultMessage: 'Card number is the wrong length'
    },
    expiryDateOlder: {
        id: 'payment.paymentWidgetMessages.expiryDateOlder',
        description: 'Error displayed if credit card expire date is older',
        defaultMessage: 'Expiry date: card too old'
    },
    expiryDateInvalid: {
        id: 'payment.paymentWidgetMessages.expiryDateInvalid',
        description: 'Error displayed if credit card expire date is invalid',
        defaultMessage: 'Expiry date: card too old'
    },
    cvvMissing: {
        id: 'payment.paymentWidgetMessages.cvvMissing',
        description: 'Error displayed if cvv number is missing',
        defaultMessage: 'Security code field is empty'
    },
    cardHolderNameMissing: {
        id: 'payment.paymentWidgetMessages.cardHolderNameMissing',
        description: 'Error displayed if card holder name is missing',
        defaultMessage: 'Invalid cardholder name'
    }
});

export default paymentWidgetMessages;
