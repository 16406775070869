import { select, takeEvery } from 'redux-saga/effects';

import { STEP_CHANGE } from '../routing/routingModule';
import { getAnalyticsPathName, getBaseEventProperties } from './segmentSelectors';
import { trackPage, trackEvent } from './segment';
import { SEGMENT_IP } from '../constants/constants';

export function* sendRoutingEvent({ path, location }) {
    const name = getAnalyticsPathName(path);
    const { state = {} } = location;

    if (name) {
        const baseProperties = yield select(getBaseEventProperties);
        const properties = {
            ...baseProperties,
            
            
            
            cashStarSessionId: (state && state.sessionKey) || baseProperties.cashStarSessionId
        };
        const segmentProperties = {
            name,
            properties
        };
        trackPage(name, segmentProperties, { context: { ip: SEGMENT_IP } });
    }
}

export function* sendTrackingEvent(action, data) {
    const baseProperties = yield select(getBaseEventProperties);
    const preexistingDataProperties = data.properties || {};
    data.properties = { ...baseProperties, ...preexistingDataProperties };
    trackEvent(action, data, { context: { ip: SEGMENT_IP } });
}

export function* sendSegmentEvents(action) {
    const { eventAction, eventData } = action.meta.segment;
    yield sendTrackingEvent(eventAction, eventData);
}

export const takeGeneralPattern = action => ((action.meta || {}).segment !== undefined);

export function* segmentWatcher() {
    yield takeEvery(takeGeneralPattern, sendSegmentEvents);
    yield takeEvery(STEP_CHANGE, sendRoutingEvent);
}
