import React, {
    createContext,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getCompletedSteps } from './routingSelectors';
import { saveCompletedSteps, changeStep } from './routingModule';
import emptyFunc from '../utils/emptyFunc';
import { push, replace } from './routing';
import {
    HOME_STEP,
    CATEGORY_STEP,
    PDP_STEP,
    PAYMENT,
    ORDER_SUCCESS,
    ORDER_FAILURE,
    ROUTES,
    currencyPickerAllowedPages
} from './flowConstants';
import { setIsCurrencyPickerEnabled } from '../localeCurrencyPicker/LocaleCurrencyPickerModule';

export const FLOW_CONFIG = {
    [HOME_STEP]: {
        next: ({ seeAll }) => (seeAll ? CATEGORY_STEP : PDP_STEP),
        previous: emptyFunc
    },
    [CATEGORY_STEP]: {
        next: () => PDP_STEP,
        previous: () => HOME_STEP
    },
    [PDP_STEP]: {
        next: () => PAYMENT,
        previous: () => HOME_STEP
    },
    [PAYMENT]: {
        next: ({ paymentSuccess }) => (paymentSuccess ? ORDER_SUCCESS : ORDER_FAILURE),
        previous: () => PDP_STEP
    },
    [ORDER_SUCCESS]: {
        next: () => HOME_STEP,
        previous: () => PAYMENT
    },
    [ORDER_FAILURE]: {
        next: () => HOME_STEP,
        previous: () => PAYMENT
    }
};

export const FlowContext = createContext();



let sagaContextMethods = {};
export const getFlowContextMethodsForSaga = () => sagaContextMethods;

export const FlowProvider = ({ children, flowConfig }) => {
    const dispatch = useDispatch();
    const completedSteps = useSelector(state => getCompletedSteps(state)).toJS();
    const setCompletedSteps = steps => dispatch(saveCompletedSteps(steps));
    const setCurrencyPicker = status => dispatch(setIsCurrencyPickerEnabled(status));

    const setStepCompleted = (stepName, isCompleted = true) => {
        if (stepName === HOME_STEP) {
            setCompletedSteps({ [stepName]: isCompleted });
        } else if (stepName === CATEGORY_STEP) {
            setCompletedSteps({ [HOME_STEP]: true, [stepName]: isCompleted });
        } else {
            setCompletedSteps({ ...completedSteps, [stepName]: isCompleted });
        }
    };

    const pushOrReplacePath = (path, shouldPushHistory, overwritingHistoryParams = {}) => {
        if (shouldPushHistory) {
            push({ pathname: path, ...overwritingHistoryParams });
        } else {
            replace({ pathname: path, ...overwritingHistoryParams });
        }
    };

    const goToNextStep = (stepName, additionalFlowParams = {}) => {
        const nextStep = flowConfig[stepName].next({ ...additionalFlowParams });
        const { shouldPushHistory = true, overwritingHistoryParams = {} } = additionalFlowParams;
        setStepCompleted(stepName);
        if (nextStep) {
            const route = ROUTES[nextStep];
            pushOrReplacePath(route, shouldPushHistory, overwritingHistoryParams);
        }
    };

    const goToPreviousStep = (stepName, additionalFlowParams = {}) => {
        const previous = flowConfig[stepName].previous({ ...additionalFlowParams });
        const { shouldPushHistory = true, overwritingHistoryParams = {} } = additionalFlowParams;
        if (previous) {
            const route = ROUTES[previous];
            pushOrReplacePath(route, shouldPushHistory, overwritingHistoryParams);
        }
    };

        const findFirstIncompleteStep = (stepName, additionalFlowParams = {}) => {
        let currentStep = flowConfig[stepName];
        let currentStepName = stepName;
        while (currentStep) {
            const previous = currentStep.previous({ ...additionalFlowParams });
            if (!previous || completedSteps[previous]) {
                return currentStepName;
            }
            currentStep = flowConfig[previous];
            currentStepName = previous;
        }
        
        return '';
    };

    const stepHasChanged = (stepName, location) => dispatch(changeStep(stepName, location));

    const showCurrencyPicker = (stepName) => {
        if (currencyPickerAllowedPages.includes(stepName)) {
            setCurrencyPicker({ isCurrencyPickerEnabled: true });
        } else {
            setCurrencyPicker({ isCurrencyPickerEnabled: false });
        }
    };

    // This object will be used by redux saga to trigger routing actions
    sagaContextMethods = {
        goToNextStep,
        goToPreviousStep
    };

    return (
        <FlowContext.Provider
            value={{
                goToNextStep,
                goToPreviousStep,
                findFirstIncompleteStep,
                stepHasChanged,
                showCurrencyPicker
            }}
        >
            {children}
        </FlowContext.Provider>
    );
};

FlowProvider.displayName = 'FlowProvider';

FlowProvider.propTypes = {
    children: PropTypes.node.isRequired,
    flowConfig: PropTypes.object
};

FlowProvider.defaultProps = {
    flowConfig: FLOW_CONFIG
};

export const useFlow = () => useContext(FlowContext);
