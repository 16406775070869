import validationMessages from '../messages/productDetailsValidationMessages';
import { isValidEmail } from './email';
import { isNameValid } from './name';
import { validate as validateProfanity } from './validate';

export const getEmailSchema = intl => ({
    required: intl.formatMessage(validationMessages.requiredFields),
    validate: {
        validateEmail: value => isValidEmail(value) || intl.formatMessage(validationMessages.emailValidityError)
    }
});

export const getNameSchema = (intl, isProfanityDetectionEnabled, fieldName, errorMessage) => {
    const props = {
        intl,
        isProfanityDetectionEnabled,
        fieldName,
        errorMessage
    };

    return ({
        required: intl.formatMessage(validationMessages.requiredFields),
        pattern: {
            value: /^([^!#$%^&()/<>?@}{~:,]*)$/,
            message: intl.formatMessage(validationMessages.specialCharactersError)
        },
        maxLength: {
            value: 30,
            message: intl.formatMessage(validationMessages.nameMaxLength, { maxLength: 30 })
        },
        validate: {
            validateNameProfanityCheck: value => validateProfanity(value, props) || intl.formatMessage(errorMessage),
            validateName2: value => isNameValid(value) || intl.formatMessage(validationMessages.nameValidityError)
        }
    });
};


export const getMessageSchema = (intl, isProfanityDetectionEnabled, fieldName, errorMessage) => {
    const props = {
        intl,
        isProfanityDetectionEnabled,
        fieldName,
        errorMessage
    };

    return ({
        maxLength: {
            value: 120,
            message: intl.formatMessage(validationMessages.messageMaxLength, {
                maxLength: 120
            })
        },
        validate: {
            validateMessage: value => validateProfanity(value, props) || intl.formatMessage(errorMessage)
        }
    });
};

export const getUserConsentSchema = intl => ({
    required: intl.formatMessage(validationMessages.consentCheckboxError)
});

export const getCustomAmountSchema = (intl, minDenomination, maxDenomination, formatCurrency) => {
    const formattedMinDenomination = formatCurrency(minDenomination);
    const formattedMaxDenomination = formatCurrency(maxDenomination);

    return {
        required: intl.formatMessage(validationMessages.amountRequired),
        max: {
            value: maxDenomination,
            message: `${intl.formatMessage(validationMessages.maxLimit, { number: formattedMaxDenomination })} `
        },
        min: {
            value: minDenomination,
            message: `${intl.formatMessage(validationMessages.minLimit, { number: formattedMinDenomination })} `
        }
    };
};
