import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';

import { getIntlLocale } from '../intl/intlSelectors';

import CashstarLink from './CashstarLink';
import footerLinksDefault from './messages/FooterMessages';

export const CashstarLinksDefault = ({
    intl,
    selectedLocale
}) => (
    <Fragment>
        <Grid
            item
            className='grid-item'
            xs={12}
            sm={12}
            md='auto'
        >
            <CashstarLink
                url='/store/self_service/v2/about/faq'
                linkText={intl.formatMessage(footerLinksDefault.faq)}
                external
            />
        </Grid>
        <Grid
            item
            className='grid-item'
            xs={12}
            sm={12}
            md='auto'
        >
            <CashstarLink
                url='/store/self_service/v2/about/terms_and_conditions'
                linkText={intl.formatMessage(footerLinksDefault.terms)}
                external
            />
        </Grid>
        <Grid
            item
            className='grid-item'
            xs={12}
            sm={12}
            md='auto'
        >
            <CashstarLink
                url='/store/self_service/v2/about/privacy_policy'
                linkText={intl.formatMessage(footerLinksDefault.privacy)}
                external
            />
        </Grid>
        <Grid
            item
            className='grid-item'
            xs={12}
            sm={12}
            md='auto'
        >
            <CashstarLink
                url={intl.formatMessage(footerLinksDefault.accessibilityLink)}
                linkText={intl.formatMessage(footerLinksDefault.accessibility)}
                external
            />
        </Grid>
        <Grid
            item
            className='grid-item'
            xs={12}
            sm={12}
            md='auto'
        >
            <CashstarLink
                url={`/self_service/register/?locale=${selectedLocale}`}
                linkText={intl.formatMessage(footerLinksDefault.support)}
                external
            />
        </Grid>
    </Fragment>
);

CashstarLinksDefault.propTypes = {
    selectedLocale: PropTypes.string.isRequired,
    intl: intlShape.isRequired
};

export const mapStateToProps = state => ({
    selectedLocale: getIntlLocale(state)
});


export default connect(mapStateToProps)(injectIntl(CashstarLinksDefault));
