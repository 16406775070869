import { defineMessages } from 'react-intl';


const homePageMessages = defineMessages({
    SEASONAL: {
        id: 'landingPage.category.seasonal',
        description: 'seasonal category text',
        defaultMessage: 'Seasonal'
    },
    BIRTHDAY: {
        id: 'landingPage.category.birthday',
        description: 'birthday category text',
        defaultMessage: 'Birthday'
    },
    THANKYOU: {
        id: 'landingPage.category.thankyou',
        description: 'thankyou category text',
        defaultMessage: 'Thank You'
    },
    CONGRATULATIONS: {
        id: 'landingPage.category.congratulations',
        description: 'Congratulations category text',
        defaultMessage: 'Congratulations'
    },
    WORKPLACE: {
        id: 'landingPage.category.workplace',
        description: 'Work Place category text',
        defaultMessage: 'Work Place'
    },
    LOVE: {
        id: 'landingPage.category.love',
        description: 'Love category text',
        defaultMessage: 'Love'
    },
    ANYTIME: {
        id: 'landingPage.category.anytime',
        description: 'Anytime category text',
        defaultMessage: 'Anytime'
    },
    seeAll: {
        id: 'landingPage.carousel.seeAll',
        description: 'see all button text on carousel',
        defaultMessage: 'See all'
    },
    physicalDesignHeader: {
        id: 'choice.choiceMessages.physicalDesignHeader',
        description: 'Section heading for selecting a faceplate image for the physical (plastic) gift card(s)',
        defaultMessage: 'Choose your Gift Card Design'
    },
    electronicDesignHeader: {
        id: 'choice.choiceMessages.electronicDesignHeader',
        description: 'Section heading for selecting a faceplate image for the electronic gift card(s)',
        defaultMessage: 'Choose your Gift Card Design'
    }
});

export default homePageMessages;
