import {
    List as IList,
    Map as IMap
} from 'immutable';
import { deviceType } from './deviceTypeUtils';

export const isSeeAllToBeShown = (windowWidth, imagesLength) => {
    const device = deviceType(windowWidth);
    switch (device) {
        case 'mobile':
            if (imagesLength > 2) return true;
            break;
        case 'tab':
            if (imagesLength > 3) return true;
            break;
        case 'desktop':
            if (imagesLength > 4) return true;
            break;
        default:
            return false;
    }
    return false;
};


export const getCategorySpecificFaceplates = (faceplatesObj = IList(), category) => {
    const faceplatesOfCategory = faceplatesObj.filter(categories => (categories.get('name') === category));
    const faceplateOfCategory = faceplatesOfCategory.get(0);
    if (faceplateOfCategory) {
        return faceplateOfCategory.get('faceplates') || IList();
    }
    return IList();
};

export const isCategoryAvailable = (faceplatesObj = IList(), category) => {
    const isCategory = faceplatesObj.toJS().filter(categories => categories.name === category).length;
    return isCategory;
};

export const convertFaceplateGroupsToMap = faceplateGroups => faceplateGroups
    .reduce((red, val) => red.concat(val.get('faceplates')), IList())
    .reduce((coll, fp) => coll.set(fp.get('code'), fp), IMap());


export const textToCapitalize = text => text.toLowerCase().replace(
    /\b[\w']+/g,
    x => x.charAt(0).toUpperCase() + x.slice(1)
);
