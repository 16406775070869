import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import {
    Box, Dialog, DialogActions, DialogContent, DialogContentText, Button, Typography, IconButton
} from '@mui/material';
import countryToCurrency from 'country-to-currency';

import CloseIcon from '@mui/icons-material/Close';

import { List as IList } from 'immutable';
import languagePickerMessages from '../intl/languagePickerMessages';
import { getSupportedLocales, getBrandElectronicCurrencyList } from '../brand/brandSelectors';
import { getIntlLocale } from '../intl/intlSelectors';
import { setLocale as setLocaleActionCreator, setCurrency as setCurrencyActionCreator } from '../intl/intlModule';
import currencyLocaleMessages from './messages/LocaleCurrencyMessages';

import CountryPicker from './CountryPicker';
import LanguagePicker from './LanguagePicker';

const LocaleCurrencyPicker = ({
    intl, isOpen, popupStatus, supportedLocales, selectedLocale, setLocale, setCurrency, supportedElectronicCurrencyList
}) => {
    const [countryLocale, setCountryLocale] = React.useState('');
    const [languageLocale, setLanguageLocale] = React.useState('');
    const [currencyCode, setCurrencyCode] = React.useState('');

    const fullySupportedLocales = supportedLocales.filter(supportedLocale => supportedLocale in languagePickerMessages);

    const selectedCountryOnly = countryLocale.split('-')[1];

    React.useEffect(() => {
        setLanguageLocale(selectedLocale);
        setCountryLocale(selectedLocale);
    }, [setLocale]);

    React.useEffect(() => setLanguageLocale(countryLocale), [countryLocale]);

    const fullySupportedLocalesArr = fullySupportedLocales.filter((supportedLocale) => {
        const countryCode = supportedLocale.split('-')[1];
        const currencyCodeKey = countryToCurrency[countryCode.toUpperCase()];

        return supportedElectronicCurrencyList.includes(currencyCodeKey) ? supportedLocale : null;
    });

    const supportedLocalesUniqueObj = {};
    let supportedLocalesUniqueArr = [];
    const localeCountryCode = selectedLocale.split('-')[1];

    fullySupportedLocalesArr.map((item) => {
        const countryCode = item.split('-')[1];

        if (!supportedLocalesUniqueObj[countryCode]) {
            supportedLocalesUniqueObj[countryCode] = (countryCode === localeCountryCode) ? selectedLocale : item;
        }
        return item;
    });

    supportedLocalesUniqueArr = Object.values(supportedLocalesUniqueObj);

    const localesForSelectedCountry = fullySupportedLocales.filter((supportedLocale) => {
        if (supportedLocale.split('-')[1] === selectedCountryOnly) {
            return supportedLocale;
        }
        return false;
    });

    const handleOnCancel = () => {
        popupStatus(false);
    };

    const handleOnSubmit = () => {
        setLocale(languageLocale);
        setCountryLocale(languageLocale);
        setCurrency(currencyCode);
        handleOnCancel(popupStatus);
    };

    return (
        <>
            <Dialog
                open={isOpen}
                className='locale-currency-popup-modal'
                onClose={() => { handleOnCancel(popupStatus); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form>
                    <Box className='currency-locale-wrapper'>
                        <IconButton className='close-currency-locale-modal'>
                            <CloseIcon onClick={() => { handleOnCancel(popupStatus); }} />
                        </IconButton>
                        <Typography className='update-settings-title'>
                            {intl.formatMessage(currencyLocaleMessages.updateSettingsTitle)}
                        </Typography>
                        <Typography className='update-settings-sub-title'>
                            {intl.formatMessage(currencyLocaleMessages.learnMoreLink)}
                        </Typography>

                        <DialogContent className='form-inputs-wrapper'>
                            <DialogContentText id="alert-dialog-description">
                                {supportedLocalesUniqueArr.length <= 1 && localesForSelectedCountry.size > 1
                                    ? null
                                    : (
                                        <CountryPicker
                                            intl={intl}
                                            locale={countryLocale}
                                            setLocale={setCountryLocale}
                                            setCurrencyCode={setCurrencyCode}
                                            selectedLocale={selectedLocale}
                                            fullySupportedLocales={supportedLocalesUniqueArr}
                                        />
                                    )
                                }
                                <LanguagePicker
                                    intl={intl}
                                    language={languageLocale}
                                    setLanguage={setLanguageLocale}
                                    locale={countryLocale}
                                    localesForSelectedCountry={localesForSelectedCountry}
                                />
                            </DialogContentText>
                        </DialogContent>

                        {}
                        <DialogActions>
                            <Button
                                className='button-popup button-cancel'
                                variant='contained'
                                onClick={() => { handleOnCancel(popupStatus); }}
                            >
                                <p className='cancelButtonText'>
                                    {intl.formatMessage(currencyLocaleMessages.cancelButtonText)}
                                </p>
                            </Button>
                            <Button
                                className='button-popup button-save'
                                variant='contained'
                                onClick={handleOnSubmit}
                            >
                                <p className='saveButtonText'>
                                    {intl.formatMessage(currencyLocaleMessages.saveButtonText)}
                                </p>
                            </Button>
                        </DialogActions>
                    </Box>
                </form>
            </Dialog>
        </>
    );
};

LocaleCurrencyPicker.defaultProps = {
    supportedLocales: IList(),
    supportedElectronicCurrencyList: IList()
};

LocaleCurrencyPicker.propTypes = {
    
    intl: intlShape.isRequired,
    isOpen: PropTypes.bool.isRequired,
    popupStatus: PropTypes.func.isRequired,
    supportedLocales: PropTypes.instanceOf(IList),
    supportedElectronicCurrencyList: PropTypes.instanceOf(IList),
    selectedLocale: PropTypes.string.isRequired,
    setLocale: PropTypes.func.isRequired,
    setCurrency: PropTypes.func.isRequired
};

export const mapStateToProps = state => ({
    selectedLocale: getIntlLocale(state),
    supportedLocales: getSupportedLocales(state),
    supportedElectronicCurrencyList: getBrandElectronicCurrencyList(state)
});

export const mapDispatchToProps = {
    setLocale: setLocaleActionCreator,
    setCurrency: setCurrencyActionCreator
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LocaleCurrencyPicker));
