import React from 'react';
import PropTypes from 'prop-types';

import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import TextInput from './TextInput';

const TextInputContainer = (props) => {
    const {
        containerClassName = 'textInputBase', className, id, name,
        label, validationSchema, ...otherProps
    } = props;

    return (
        <ButtonBase className={containerClassName} tabIndex={-1}>
            <Typography className='form-custom-label'>
                {label}
            </Typography>
            <TextInput
                className={className}
                id={id}
                name={name}
                label={label}
                validationSchema={validationSchema}
                {...otherProps}
            />
        </ButtonBase>
    );
};

TextInputContainer.defaultProps = {
    containerClassName: 'textInputBase'
};

TextInputContainer.propTypes = {
    containerClassName: PropTypes.string,
    className: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validationSchema: PropTypes.object.isRequired
};

export default TextInputContainer;
