
const isObjectEmpty = objectName => Object.keys(objectName).length === 0;

const validateFieldsByRules = (value, constraints, intl = null, fieldSequence = null) => {
    const errors = {};
    let errorState = false;
    let fieldNames;
    if (fieldSequence) {
        fieldNames = fieldSequence;
    } else {
        fieldNames = constraints.keySeq();
    }

    fieldNames.forEach((fieldName) => {
        const fieldRules = constraints.get(fieldName);

        fieldRules.some((rule) => {
            const vals = value;
            const error = rule(vals);
            if (error) {
                errors[fieldName] = intl ? intl.formatMessage(error, error.values) : error;
                return true;
            }
            return false;
        });
    });

    if (isObjectEmpty(errors)) {
        errorState = true;
    }
    return errorState;
};

export default validateFieldsByRules;
