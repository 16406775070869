
import 'core-js';

import { logError, logWarning } from '../utils/errorUtils';



export default function loadPolyfills() {
    const fillIntl = () => new Promise((resolve) => {
        
        if (window.Intl && window.Intl.NumberFormat.prototype.formatToParts) return resolve();

        require.ensure([
            'intl',
            'intl/locale-data/jsonp/en.js'
        ],
        (require) => {
            const IntlPolyfill = require('intl');
            
            
            Intl.NumberFormat = IntlPolyfill.NumberFormat;
            
            require('intl/locale-data/jsonp/en');
            logWarning('Polyfilled Intl API');
            resolve();
        },
        error => logError(error),
        'Intl');
    });

        const fillIntlDateTimeFormatTimezone = () => new Promise((resolve) => {
        try {
            new Intl.DateTimeFormat('en', {
                timeZone: 'America/Los_Angeles',
                timeZoneName: 'long'
            }).format();
            return resolve();
        } catch (error) {
            require.ensure(['date-time-format-timezone'],
                (require) => {
                    require('date-time-format-timezone');
                    logWarning('Polyfilled Intl.DateTimeFormat API timezone support');
                    return resolve();
                },
                requireError => logError(requireError),
                'DateTimeFormatTimezone');
        }
    });

    const fillUrlSearchParams = () => new Promise((resolve) => {
        if (window.URLSearchParams) return resolve();

        require.ensure(['url-search-params'],
            (require) => {
                window.URLSearchParams = require('url-search-params');
                logWarning('Polyfilled URLSearchParams API');
                return resolve();
            },
            error => logError(error),
            'URLSearchParams');
    });

    return Promise.all([
        fillIntl(),
        fillIntlDateTimeFormatTimezone(),
        fillUrlSearchParams()
    ]);
}
