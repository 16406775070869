import { logError } from '../utils/errorUtils';

export const segmentInit = (writeKey) => {
    
    let { analytics } = window;
    if (typeof analytics === 'undefined') {
        analytics = [];
        window.analytics = analytics;
    }

    
    if (analytics.initialize) return;

    
    if (analytics.invoked) {
        logError(new Error('Segment snippet included twice.'));
        return;
    }

    
    
    analytics.invoked = true;

    
    analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on'
    ];

    
    
    
    
    analytics.factory = method => (...args) => {
        const localArgs = Array.prototype.slice.call(args);
        localArgs.unshift(method);
        analytics.push(localArgs);
        return analytics;
    };

    
    for (let i = 0; i < analytics.methods.length; i++) {
        const key = analytics.methods[i];
        analytics[key] = analytics.factory(key);
    }

    
    
    analytics.load = (key) => {
        
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        const protocol = (document.location.protocol === 'https:' ? 'https://' : 'http://');
        script.src = `${protocol}cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;

        
        const first = document.getElementsByTagName('script')[0];
        first.parentNode.insertBefore(script, first);
    };

    
    analytics.SNIPPET_VERSION = '4.0.1';

    
    
    analytics.load(writeKey);
};

export const trackPage = (...args) => window.analytics && window.analytics.page && window.analytics.page(...args);

export const trackEvent = (...args) => window.analytics && window.analytics.track && window.analytics.track(...args);
