import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Link } from '@mui/material';

import { trackExternalLinkClick } from '../segment/segmentModule';

export const CashstarLink = ({
    linkText,
    url,
    trackExternalLinkClickAction
}) => {
    const onClick = () => trackExternalLinkClickAction(
        { urlName: linkText, type: 'footer', url }
    );
    const linkProps = {
        href: url,
        onClick,
        target: '_blank',
        rel: 'noopener'
    };
    return (
        <Link {...linkProps}>
            <Typography className='footer-grid-item'>
                {linkText}
            </Typography>
        </Link>
    );
};


CashstarLink.propTypes = {
    
    linkText: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    
    trackExternalLinkClickAction: PropTypes.func.isRequired
};

export default connect(
    null,
    { trackExternalLinkClickAction: trackExternalLinkClick }
)(CashstarLink);
