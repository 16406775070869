import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { injectIntl, intlShape } from 'react-intl';
import Markdown from 'markdown-to-jsx';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';

import giftCardDetailsPageMessages from './messages/giftCardDetailsPageMessages';
import { USER_CONSENT } from '../constants/constants';
import { getgdprTermsAcknowledgementEnabled } from '../brand/brandSelectors';
import { getUserConsentSchema } from './validation/productDetailsSchema';
import { trackExternalLinkClick } from '../segment/segmentModule';
import dynamicLinkMessages from '../brand/messages/DynamicLinkMessages';


const ConsentCheckbox = (props) => {
    const [stateCheck, setStateCheck] = React.useState(false);
    const {
        intl,
        productDetails: { userConsent } = {},
        termsAcknowledgementEnabled,
        trackExternalLinkClickAction
    } = props;
    const formValidation = useFormContext();
    const { register, formState: { errors }, setValue } = formValidation;
    const handleChange = (event) => {
        setStateCheck(event.target.checked);
    };

    React.useEffect(() => {
        if (userConsent === true) {
            setValue(USER_CONSENT, true);
        }
    }, []);

    return (
        <div>
            <div>
                { termsAcknowledgementEnabled && (
                    <FormControl
                        required
                        className='formCheckboxGroup'
                        error={!!errors.userConsentCheckBox}
                    >
                        <FormGroup>
                            <FormControlLabel
                                className='formCheckboxConsent'
                                id='formCheckboxConsentId'
                                control={<Checkbox />}
                                checked={stateCheck}
                                value={stateCheck}
                                {...register(USER_CONSENT, getUserConsentSchema(intl))}
                                onClick={handleChange}
                                label={(
                                    <Markdown
                                        options={{
                                            overrides: {
                                                
                                                a: {
                                                    component: Link,
                                                    props: {
                                                        target: '_blank',
                                                        rel: 'noopener',
                                                        onClick: (e) => {
                                                            trackExternalLinkClickAction({
                                                                urlName: e.target.firstChild.nodeValue,
                                                                type: 'pdp form consent',
                                                                url: e.target.href
                                                            });
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                        className='formCheckboxConsentText'
                                    >
                                        {
                                            intl.formatMessage(
                                                giftCardDetailsPageMessages.concernCheckboxLabel,
                                                {
                                                    privacyLink:
                                                    intl.formatMessage(dynamicLinkMessages.privacyPolicy),
                                                    termsLink: intl.formatMessage(dynamicLinkMessages.termsAndCondition)
                                                }
                                            )
                                        }
                                    </Markdown>
                                )}
                            />
                        </FormGroup>
                        <FormHelperText className="formCheckboxError">
                            {errors.userConsentCheckBox ? errors.userConsentCheckBox.message : null}
                        </FormHelperText>
                    </FormControl>
                )}
            </div>
        </div>
    );
};

ConsentCheckbox.defaultProps = {
    productDetails: {}
};

ConsentCheckbox.propTypes = {
    errors: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    productDetails: PropTypes.object,
    termsAcknowledgementEnabled: PropTypes.bool.isRequired,
    trackExternalLinkClickAction: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    termsAcknowledgementEnabled: getgdprTermsAcknowledgementEnabled(state)
});

export default connect(
    mapStateToProps,
    { trackExternalLinkClickAction: trackExternalLinkClick }
)(injectIntl(ConsentCheckbox));
