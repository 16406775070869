import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { injectIntl, intlShape } from 'react-intl';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

import giftCardDetailsPageMessages from './messages/giftCardDetailsPageMessages';
import SelfBuyForm from './SelfBuyForm';
import SendGiftForm from './SendGiftForm';
import { DELIVERY_TYPE, DELIVERY_TYPES, JAPAN_LOCALE } from '../constants/constants';
import DeliveryTypesWithText from './DeliveryTypesWithText';
import DeliveryTypesWithIcons from './DeliveryTypesWithIcons';
import { saveSelectedPurchaseType } from '../segment/segmentModule';

const DeliveryDetails = ({ deliveryType, productDetails, shareViaLinkEnabled }) => (deliveryType === DELIVERY_TYPES.GIFT
    ? <SendGiftForm productDetails={productDetails} shareViaLinkEnabled={shareViaLinkEnabled} />
    : <SelfBuyForm productDetails={productDetails} />);

DeliveryDetails.propTypes = {
    deliveryType: PropTypes.string.isRequired,
    productDetails: PropTypes.object.isRequired,
    shareViaLinkEnabled: PropTypes.bool.isRequired
};

const DeliveryAddress = (props) => {
    const {
        intl, locale, productDetails, saveSelectedPurchaseTypeAction, shareViaLinkEnabled
    } = props;
    const { selfBuy } = productDetails;

    const { setValue, watch } = useFormContext();
    const savedDeliveryType = selfBuy ? DELIVERY_TYPES.SELF : DELIVERY_TYPES.GIFT;
    const deliveryType = watch(DELIVERY_TYPE) || savedDeliveryType;
    const [purchaseTypeStatus, savePurchaseTypeStatus] = useState(deliveryType);

    React.useEffect(() => {
        if (deliveryType === DELIVERY_TYPES.GIFT) {
            setValue(DELIVERY_TYPE, DELIVERY_TYPES.GIFT);
        } else {
            setValue(DELIVERY_TYPE, DELIVERY_TYPES.SELF);
        }
    }, []);

    React.useEffect(() => {
        savePurchaseTypeStatus(purchaseTypeStatus);
        saveSelectedPurchaseTypeAction(purchaseTypeStatus);
    }, [purchaseTypeStatus]);

    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setValue(DELIVERY_TYPE, newAlignment);
            savePurchaseTypeStatus(newAlignment);
        }
    };

    return (
        <>
            <Typography className='deliveryRequiredTitle'>
                {intl.formatMessage(giftCardDetailsPageMessages.requiredTitle)}
            </Typography>
            <Card className='deliveryContainer'>
                <Typography className='denomTitle'>
                    {intl.formatMessage(giftCardDetailsPageMessages.deliveryDetailsTitle)}
                </Typography>
                {
                    locale === JAPAN_LOCALE
                        ? <DeliveryTypesWithText deliveryType={deliveryType} handleChange={handleChange} />
                        : <DeliveryTypesWithIcons deliveryType={deliveryType} handleChange={handleChange} />
                }
                <DeliveryDetails
                    deliveryType={deliveryType}
                    productDetails={productDetails}
                    shareViaLinkEnabled={shareViaLinkEnabled}
                />
            </Card>
        </>
    );
};

DeliveryAddress.defaultProps = {
    productDetails: {}
};

DeliveryAddress.propTypes = {
    
    intl: intlShape.isRequired,
    locale: PropTypes.object.isRequired,
    productDetails: PropTypes.object,
    saveSelectedPurchaseTypeAction: PropTypes.object.isRequired,
    shareViaLinkEnabled: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
    saveSelectedPurchaseTypeAction: saveSelectedPurchaseType
};

export default connect(null, mapDispatchToProps)(injectIntl(DeliveryAddress));
