import { defineMessages } from 'react-intl';

const dynamicLinkMessages = defineMessages({
    privacyPolicy: {
        id: 'page.dynamiclinks.privacyPolicy',
        description: 'Dynamic link for privacy policy',
        defaultMessage: '/store/self_service/v2/about/privacy_policy'
    },
    faq: {
        id: 'page.dynamiclinks.faq',
        description: 'Dynamic link for faq',
        defaultMessage: '/store/self_service/v2/about/faq'
    },
    inquiryList: {
        id: 'page.dynamiclinks.inquiryList',
        description: 'Dynamic link for inquiry list',
        defaultMessage: '/store/self_service/v2/about/faq'
    },
    noRetryInquiryList: {
        id: 'page.dynamiclinks.noRetryInquiryList',
        description: 'Dynamic link for no retry inquiry list',
        defaultMessage: '/store/self_service/v2/about/faq'
    },
    termsAndCondition: {
        id: 'page.dynamiclinks.termsAndCondition',
        description: 'Dynamic link for terms and condition',
        defaultMessage: '/store/self_service/v2/about/terms_and_conditions'
    },
    customerSupport: {
        id: 'page.dynamiclinks.customerSupport',
        description: 'Dynamic link for user guide',
        defaultMessage: '/self_service/v2/about/customer_support/contact'
    },
    manageGiftCard: {
        id: 'page.dynamiclinks.manageGiftCard',
        description: 'Dynamic link for manage gift card',
        defaultMessage: '/self_service/v2/'
    },
    learnMore: {
        id: 'page.dynamiclinks.learnMore',
        description: 'Dynamic link for LearnMore for currency locale selector',
        defaultMessage: '#'
    }
});

export default dynamicLinkMessages;
