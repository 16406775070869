import { defineMessages } from 'react-intl';

const orderStatusMessages = defineMessages({
    orderSuccessTitle: {
        id: 'orderSuccessPage.orderSuccessTitle',
        description: 'Order success title',
        defaultMessage: 'Yay! Successfully sent'
    },
    orderFailureTitle: {
        id: 'orderSuccessPage.orderFailureTitle',
        description: 'Order failure title',
        defaultMessage: 'Sorry, payment failed'
    },
    orderFailureMessage: {
        id: 'orderSuccessPage.orderFailureMessage',
        description: 'Order failure message',
        defaultMessage: 'Don\'t worry, your money is not deducted.'
    },
    orderSuccessMessage: {
        id: 'orderSuccessPage.orderSuccessMessage',
        description: 'Order Success message',
        defaultMessage: 'Congratulations ! Your order is successfully placed and you will receive eGift email in 5 minutes.'
    },
    buyAnotherButtonLabel: {
        id: 'orderSuccessPage.buyAnotherButtonLabel',
        description: 'Buy another button text',
        defaultMessage: 'Buy another gift card'
    },
    tryAgainButtonLabel: {
        id: 'orderSuccessPage.tryAgainButtonLabel',
        description: 'Try again button text',
        defaultMessage: 'Try again'
    },
    cancelButtonLabel: {
        id: 'orderSuccessPage.cancelButtonLabel',
        description: 'Cancel button text',
        defaultMessage: 'Back to home'
    },
    personalMessageLabel: {
        id: 'orderSuccessPage.personalMessageLabel',
        description: 'Personal message label',
        defaultMessage: 'Personal message'
    },
    giftCardValueLabel: {
        id: 'orderSuccessPage.giftCardValueLabel',
        description: 'Gift card value label',
        defaultMessage: 'Value'
    },
    sendToLabel: {
        id: 'orderSuccessPage.snedToLabel',
        description: 'Send to label',
        defaultMessage: 'Send to'
    },
    sendALinkToLabel: {
        id: 'orderSuccessPage.sendALinkToLabel',
        description: 'Send a link to label',
        defaultMessage: 'Send a link to'
    },
    orderIdLabel: {
        id: 'orderSuccessPage.orderIdLabel',
        description: 'Order id label',
        defaultMessage: 'Order ID'
    },
    amountPaidLabel: {
        id: 'orderSuccessPage.amountPaidLabel',
        description: 'Amount paid label',
        defaultMessage: 'Amount paid'
    },
    howToRedeemLabel: {
        id: 'orderSuccessPage.howToRedeemLabel',
        description: 'How to redeem label',
        defaultMessage: 'How to redeem'
    },
    faqsLabel: {
        id: 'orderSuccessPage.faqsLabel',
        description: 'FAQs label',
        defaultMessage: 'FAQs'
    },
    customerSupportLabel: {
        id: 'orderSuccessPage.customerSupportLabel',
        description: 'Customer support label',
        defaultMessage: 'Customer support'
    },
    amountToPayLabel: {
        id: 'orderSuccessPage.amountToPayLabel',
        description: 'Amount to pay label',
        defaultMessage: 'Amount to pay'
    },
    orderSummaryLabel: {
        id: 'orderSuccessPage.orderSummaryLabel',
        description: 'Order Summary Label',
        defaultMessage: 'Order summary'
    },
    linkStillLoading: {
        id: 'orderSuccessPage.linkStillLoading',
        description: 'shareable link still loading ',
        defaultMessage: 'Still Preparing link to share...'
    },
    linkLoading: {
        id: 'orderSuccessPage.linkLoading',
        description: 'shareable link loading ',
        defaultMessage: 'Preparing link to share...'
    },
    linkIsReady: {
        id: 'orderSuccessPage.linkIsReady',
        description: 'shareable link is ready to share ',
        defaultMessage: 'Share this gift'
    },
    continueBrowsing: {
        id: 'orderSuccessPage.continueBrowsing',
        description: 'sharable link still loading',
        defaultMessage: 'Continue browsing!'
    },
    stillLoadingInfo: {
        id: 'orderSuccessPage.stillLoadingInfo',
        description: 'sharable link still loading',
        defaultMessage: 'We\'ll send the link to your inbox when it\'s ready.'
    },
    linkSentToInbox: {
        id: 'orderSuccessPage.linkSentToInbox',
        description: 'sharable link is ready',
        defaultMessage: 'We\'ve sent this link to your inbox.'
    },
    receiveLinkShortly: {
        id: 'orderSuccessPage.receiveLinkShortly',
        description: 'sharable link will be ready shortly',
        defaultMessage: 'You\'ll also receive this link in your inbox shortly.'
    },
    copyData: {
        id: 'orderSuccessPage.copyData',
        description: 'copy data',
        defaultMessage: 'copy'
    },
    copiedData: {
        id: 'orderSuccessPage.copiedData',
        description: 'data copied',
        defaultMessage: 'copied'
    },
    emailShareSubjectText: {
        id: 'payment.paymentMessages.emailShareSubjectText',
        description: 'text to display the subject during email share',
        defaultMessage: '{Rname}, {Sname} sent you a gift from {brandName}!'
    },
    emailShareCustomMessgText: {
        id: 'payment.paymentMessages.emailShareCustomMessgText',
        description: 'text to display the custom message by sender during email share',
        defaultMessage: 'Hi {name}, \n{messg} \n\n\n {amount} {brandName} eGift {emoji}. \n{url} \n\n'
    },
    emailShareDefaultText: {
        id: 'payment.paymentMessages.emailShareDefaultText',
        description: 'text to display the default message by sender during email share',
        defaultMessage: 'Hi {name}, \nHere\'s a {brandName} eGift - enjoy it! \n\n\n{amount} {brandName} eGift {emoji}. \n{url} \n\n'
    },
    shareableLinkShareDefaultText: {
        id: 'payment.paymentMessages.shareableLinkShareDefaultText',
        description: 'text to display the default message by sender during shareable Link share',
        defaultMessage: 'Hi {name},\nHere’s a {brandName} eGift - enjoy it! \n\n{amount} {brandName} eGift {emoji}. \n{url}  \n\n'
    },
    shareableLinkShareCustomMessgText: {
        id: 'payment.paymentMessages.shareableLinkShareCustomMessgText',
        description: 'text to display the custom message by sender during shareable Link share',
        defaultMessage: 'Hi {name}, \n{message} \n\n{amount} {brandName} eGift {emoji}. \n{url}  \n\n'
    }
});

export default orderStatusMessages;
