import { UPDATE } from 'react-intl-redux';

export const SET_LOCALE = '@@storefront/SET_LOCALE';
export const SET_CURRENCY = '@@storefront/SET_CURRENCY';
export const LANGUAGE_CHANGE_COMPLETE = '@@storefront/LANGUAGE_CHANGE_COMPLETE';
export const INTL_CURRENCY_UPDATED = '@@storefront/INTL_CURRENCY_UPDATED';

export const currencyFormatConfig = { 
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
};

export const currencyIntegerFormatConfig = { 
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
};

export const INTL_FORMATS = {
    number: {
        currency: { ...currencyFormatConfig, currency: 'USD' },
        currencyInteger: { ...currencyIntegerFormatConfig, currency: 'USD' }
    },
    date: {
        date: { 
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        },
        dateTime: { 
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC'
        }
    },
    time: {
        '12HourTime': { 
            hour12: true,
            timeZone: 'UTC'
        }
    }
};

export const getDateTimeFormat = (timeZone = 'UTC', showTimeZone = false) => {
    const format = {
        ...INTL_FORMATS.date.dateTime,
        timeZone
    };
    if (showTimeZone) {
        format.timeZoneName = 'short';
    }
    return format;
};

export const getDateFormat = () => {
    const format = {
        ...INTL_FORMATS.date.date
    };
    return format;
};

export const getTimeFormat = (timeZone = 'UTC') => ({
    ...INTL_FORMATS.time['12HourTime'],
    timeZone
});

const updateCurrencyFormats = (formats, code) => {
    const newFormats = formats && !formats.isEmpty() ? formats.toJS() : { ...INTL_FORMATS };
    newFormats.number.currency.currency = code;
    newFormats.number.currencyInteger.currency = code;
    return newFormats;
};

export const updateIntlCurrency = (formats, newCurrency) => {
    const newFormats = updateCurrencyFormats(formats, newCurrency);
    
    
    return { type: UPDATE, payload: { formats: newFormats } };
};

export const setIntlLocale = locale => ({
    type: UPDATE,
    payload: { locale }
});

export const setLocale = locale => ({
    type: SET_LOCALE,
    locale
});

export const setCurrency = currency => ({
    type: SET_CURRENCY,
    currency
});

export const updateIntlMessages = (messages, locale) => ({
    type: UPDATE,
    payload: { messages, locale, defaultLocale: locale }
});

export const setShouldPredictIntlCurrency = shouldPredictIntlCurrency => ({
    type: UPDATE,
    payload: { shouldPredictIntlCurrency }
});

export const setIntlIsLoading = loading => ({
    type: UPDATE,
    payload: { loading }
});

export const completeLanguageChange = () => ({
    type: LANGUAGE_CHANGE_COMPLETE
});

export const intlCurrencyUpdated = () => ({
    type: INTL_CURRENCY_UPDATED
});
