import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography
} from '@mui/material';
import { Map as IMap } from 'immutable';

import FormLeftPanel from './FormLeftPanel';
import { BackButton } from '../primitive/buttons';


const FormLayout = (props) => {
    const {
        successCard,
        failureCard,
        defaultCard,
        noDetailsCard,
        enableBackBtn,
        formTitle,
        children,
        stepName,
        formLeftPanelTitle,
        displayData,
        cartQualifiesForPromo,
        bonusCard,
        formLeftHeading
    } = props;

    const formLeftPanelProps = {
        successCard,
        failureCard,
        defaultCard,
        noDetailsCard,
        formLeftPanelTitle,
        displayData,
        cartQualifiesForPromo,
        bonusCard,
        formLeftHeading
    };

    return (
        <Grid container className='form-container'>
            <Grid
                className='form-container-item-grid'
                item
                xs={12}
                sm={12}
                md={7}
                lg={7}
            >
                <div className='form-heading-back-button-container'>
                    {enableBackBtn && <BackButton stepName={stepName} className='form-layout-back-button' />}
                    <Typography className='form-left-heading'>{formLeftHeading}</Typography>
                </div>
                <Typography className='form-title-left'>
                    {formTitle}
                </Typography>
                <FormLeftPanel {...formLeftPanelProps} />
            </Grid>
            <Grid
                className='form-container-right-item-grid'
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
            >
                {formTitle
                    && (
                        <Typography className='form-title-right'>
                            {formTitle}
                        </Typography>
                    )
                }
                {children}
            </Grid>
        </Grid>
    );
};

FormLayout.defaultProps = {
    successCard: false,
    failureCard: false,
    defaultCard: false,
    noDetailsCard: false,
    enableBackBtn: false,
    formTitle: '',
    stepName: '',
    formLeftPanelTitle: '',
    displayData: null,
    cartQualifiesForPromo: false,
    formLeftHeading: '',
    bonusCard: IMap()
};

FormLayout.propTypes = {
    
    successCard: PropTypes.bool,
    failureCard: PropTypes.bool,
    defaultCard: PropTypes.bool,
    noDetailsCard: PropTypes.bool,
    enableBackBtn: PropTypes.bool,
    formTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    children: PropTypes.element.isRequired,
    stepName: PropTypes.string,
    formLeftPanelTitle: PropTypes.string,
    displayData: PropTypes.object,
    cartQualifiesForPromo: PropTypes.bool,
    bonusCard: PropTypes.instanceOf(IMap()),
    formLeftHeading: PropTypes.string
};

export default FormLayout;
