import { Map as IMap } from 'immutable';

import { checkoutStartedSegmentData, productClickedSegmentData } from '../segment/segmentSelectors';


export const PROCEED_TO_PAY = '@@storefront/PROCEED_TO_PAY';
export const SEGMENT_PRODUCT_VIEWED = '@@storefront/SEGMENT_PRODUCT_VIEWED';
export const FLUSH_PRODUCT_DETAILS = '@@storefront/FLUSH_PRODUCT_DETAILS';


const productDetailsReducer = (state = IMap({}), action) => {
    switch (action.type) {
        case PROCEED_TO_PAY: {
            const addedProducts = action.data;
            return state.merge({ ...addedProducts });
        }
        case FLUSH_PRODUCT_DETAILS: {
            return state.clear();
        }
        default:
    }
    return state;
};


export const handleProceedToPay = products => ({
    type: PROCEED_TO_PAY,
    data: products,
    meta: {
        segment: {
            eventAction: 'Checkout Started',
            eventData: checkoutStartedSegmentData(products)
        }
    }
});

export const segmentProductViewed = (faceplate, category) => ({
    type: SEGMENT_PRODUCT_VIEWED,
    meta: {
        segment: {
            eventAction: 'Product Viewed',
            eventData: productClickedSegmentData(faceplate, category)
        }
    }
});

export const flushProductDetails = () => ({
    type: FLUSH_PRODUCT_DETAILS
});

export default productDetailsReducer;
