
const hasBootstrapData = () => !!window.BOOTSTRAP_DATA;
const hasPreferredValues = () => !!window.BOOTSTRAP_DATA.preferredValues;
const hasEnableShareableLinkFlowData = () => !!window.BOOTSTRAP_DATA.enableShareableLinkFlow;



export const getEnv = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.ENVIRONMENT;
    }
    return null;
};

export const getBrandData = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.brand;
    }
    return {};
};

export const getDefaultLocaleCode = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.brand.defaultLocaleCode;
    }
    return null;
};

export const getBrandUrl = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.brand.brandUrl;
    }
    return '';
};

/**
 * Get static media URL with optional suffix.
 * @param {string} [suffix] Optional path appended to the static media url.
 * @returns {string|undefined} If the current window does not have bootstrap data,
 *                             return undefined. Otherwise, return the formatted static media URL based on
 *                             bootstrap data and suffix parameter.
 */
export const getStaticMediaURL = (suffix) => {
    if (!hasBootstrapData()) {
        return null;
    }
    let url = window.BOOTSTRAP_DATA.staticMediaUrl;
    url = url.replace(/\/$/, '');

    if (suffix) {
        url += suffix;
    }
    return url;
};

export const getPreferredLocaleCode = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.brand.preferredLocaleCode;
    }
    return null;
};

/* Helper to get the static URL and provide a testing seam */
export const getStaticUrl = () => {
    if (window.BOOTSTRAP_DATA) {
        return window.BOOTSTRAP_DATA.staticUrl;
    }
    return undefined;
};

export const getCapiUrl = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.capiUrl;
    }
    return null;
};

export const getOrderingToken = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.brand.orderingToken;
    }
    return null;
};

export const getOrderingTokenServiceCodes = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.brand.ordering_token_service_codes;
    }
    return null;
};

export const getSegmentKey = () => {
    try {
        // Obfuscate segment key by naming after moon of Saturn
        return window.BOOTSTRAP_DATA.brand.iapetus;
    } catch (e) {
        return null;
    }
};

const hasCookieConsentEnabled = () => (hasBootstrapData()
    && !!window.BOOTSTRAP_DATA.brand
    && !!window.BOOTSTRAP_DATA.brand.cookieConsentEnabled);

export const getCookieConsentEnabled = () => {
    if (hasCookieConsentEnabled()) {
        return window.BOOTSTRAP_DATA.brand.cookieConsentEnabled;
    }
    return null;
};

export const getShrinkEnabledInputField = () => {
    try {
        return window.BOOTSTRAP_DATA.brand.shrink_enabled_input_field;
    } catch (e) {
        return null;
    }
};

export const getExcludeBillingInfo = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.brand.exclude_billing_info;
    }
    return false;
};

export const getPreferredValues = () => window.BOOTSTRAP_DATA.preferredValues;

export const getPreferredPromoCode = () => {
    if (hasPreferredValues()) {
        return getPreferredValues().promoCode;
    }
    return null;
};

export const getEnableShareableLinkFlow = () => {
    if (hasEnableShareableLinkFlowData()) {
        const enableShareableLinkFlow = window.BOOTSTRAP_DATA.enableShareableLinkFlow.split(',');
        return enableShareableLinkFlow;
    }
    return [];
};
