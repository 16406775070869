import React from 'react';
import PropTypes from 'prop-types';
import Banner from './banner';


const BannerContainer = (props) => {
    const {
        catagoriesList, bannerData
    } = props;
    const headerBanners = [];
    const footerBanners = [];
    const innerBanners = [];

    bannerData.forEach((banner) => {
        if (banner.get('position') === 'header-banner') {
            headerBanners.push(banner);
        } else if (banner.get('position') === 'footer-banner') {
            footerBanners.push(banner);
        } else if (banner.get('position') === 'insert-after') {
            innerBanners.push(banner);
        }
    });

    const footerBannerData = footerBanners.map(item => (
        <Banner
            {...props}
            transDuration={item.get('transition_duration')}
            bannerContent={item.get('banner_content')}
            containerClass='footer-banner'
            itemClass='footer-banner-images'
        />
    ));
    const headerBannerData = headerBanners.map(item => (
        <Banner
            {...props}
            transDuration={item.get('transition_duration')}
            bannerContent={item.get('banner_content')}
            containerClass='header-banner'
            itemClass='header-banner-images'
        />
    ));
    const categoriesData = catagoriesList.map((category, index) => {
        const bannerContent = innerBanners.find(x => x.get('insert_after') === index);
        return (
            <div>
                {category}
                {
                    (bannerContent)
                        ? (
                            <Banner
                                {...props}
                                transDuration={bannerContent.get('transition_duration')}
                                bannerContent={bannerContent.get('banner_content')}
                                containerClass='inner-banner'
                                itemClass='inner-banner-images'
                            />
                        ) : <></>
                }
            </div>
        );
    });
    return (
        <div>
            {headerBannerData}
            {categoriesData}
            {footerBannerData}
        </div>
    );
};

BannerContainer.propTypes = {
    catagoriesList: PropTypes.array.isRequired,
    bannerData: PropTypes.array.isRequired
};

export default BannerContainer;
