import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Markdown from 'markdown-to-jsx';

import errorMessages from './errorMessages';
import dynamicLinkMessages from '../brand/messages/DynamicLinkMessages';

const ErrorNoRetry = ({ intl }) => (
    <Box className='error-container'>
        <Typography className='error-desc'>
            {intl.formatMessage(errorMessages.errorNoRetryDescription)}
        </Typography>

        <Typography className='error-check-url'>
            <Markdown>
                {
                    intl.formatMessage(
                        errorMessages.errorNoRetryContactUs,
                        { link: intl.formatMessage(dynamicLinkMessages.noRetryInquiryList) }
                    )
                }
            </Markdown>
        </Typography>
    </Box>
);

ErrorNoRetry.propTypes = {
    
    intl: intlShape.isRequired
};

export default injectIntl(ErrorNoRetry);
