import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import Payment from '@bhn/payplus-payments-js/dist/core/payment';
import { List as IList, Map as IMap } from 'immutable';
import { Alert } from '@mui/material';

import { getEnv, getExcludeBillingInfo } from '../app/bootstrap';
import { logError, logWarning } from '../utils/errorUtils';
import { getIntlLocale, getIntlCurrencyCode } from '../intl/intlSelectors';
import {
    PAYMENT_METHODS,
    PAYMENT_STATUS,
    processInitiateConfig,
    resetPaymentStatus,
    processPayment,
    paymentFailed
} from './paymentModule';
import {
    parseErrorResponse,
    defaultFailureResponse,
    parseErrorResponseForSegment
} from './paymentSagas';
import { getInitiateConfigData, getPaymentStatus } from './paymentSelectors';
import { getEGiftCards } from '../giftCardDetailsPage/productDetailsSelector';
import { getBrandDefaultLocale } from '../brand/brandSelectors';
import Loader from '../loader/loader';
import { translations } from '../utils/paymentWidgetUtils';
import paymentWidgetMessages from './messages/paymentWidgetMessages';

export const PaymentWidget = (props) => {
    const {
        locale,
        initiateConfigData,
        placeOrder,
        processInitiateConfigAction,
        resetPaymentStatusAction,
        paymentFailedAction,
        currencyCode,
        brandDefaultLocale,
        eGiftCards,
        paymentStatus,
        intl
    } = props;

    const [getPaymentMethodsApiFailure, setGetPaymentMethodsApiFailure] = useState(false);
    const countryCode = (brandDefaultLocale || 'en-us').split('-')[1].toUpperCase();
    const checkoutId = initiateConfigData.get('checkoutId');
    const merchantId = initiateConfigData.get('merchantId');
    const grandTotal = initiateConfigData.get('grandTotal');
    const eGiftCard = eGiftCards.get(0, IMap());
    const emailAddress = eGiftCard.get('selfBuy') ? eGiftCard.get('yourEmail') : eGiftCard.get('senderEmail');
    const fullName = eGiftCard.get('selfBuy') ? eGiftCard.get('yourName') : eGiftCard.get('senderName');
    const [firstName, lastName] = fullName ? fullName.split(/ (.*)/) : [];

    const paymentStatusRef = useRef(null);
    paymentStatusRef.current = paymentStatus;

    useEffect(() => {
        
        
        if (eGiftCards && eGiftCards.size) {
            processInitiateConfigAction({ isEdited: false });
        }

        return () => {
            
            
            if (paymentStatusRef.current === PAYMENT_STATUS.get('CONFIG_SUCCESS')) {
                resetPaymentStatusAction();
            }
        };
    }, []);

    const initiateConfigCallback = (res) => {
        if (res.status !== 200) {
            setGetPaymentMethodsApiFailure(true);
        }
    };

    const authCallback = (res) => {
        if (res.status === 201) {
            const {
                status,
                hmac,
                threeDSflow,
                ...authRes
            } = res;
            if (!hmac) {
                logWarning('Payment Plus Hmac key missing', { responseRecieved: res });
            }
            placeOrder(PAYMENT_METHODS.paymentPlus, {
                paymentPlusAuthResponse: authRes,
                hmac
            });
        } else {
            
            const failureResponse = (res.error || {}).response || defaultFailureResponse;
            const parsedErrorResponse = parseErrorResponse(failureResponse);
            const parsedErrorForSegment = parseErrorResponseForSegment(failureResponse);
            logError('Payment Plus Auth Error', {
                data: failureResponse.data,
                status: failureResponse.status,
                headers: failureResponse.headers
            });
            paymentFailedAction(
                parsedErrorResponse,
                {
                    error: parsedErrorForSegment,
                    totalAmount: grandTotal,
                    currencyCode,
                    eGiftCards
                }
            );
        }
    };

    if (paymentStatus === PAYMENT_STATUS.get('CONFIG_FAILED') || getPaymentMethodsApiFailure) {
        return (
            <Alert severity="error" className='payment-widget-loading-error'>
                {intl.formatMessage(paymentWidgetMessages.paymentWidgetLoadingError)}
            </Alert>
        );
    }

    const billingInfoComponents = [
        { name: 'BillingInfoHeader' },
        { name: 'NameInput', value: { firstName, lastName: lastName && lastName.trim() } },
        { name: 'EmailInput', value: emailAddress },
        { name: 'PhoneInput' }
    ];

    const configuration = (paymentStatus === PAYMENT_STATUS.get('CONFIG_SUCCESS')) && {
        spinner: (<Loader showTestEnv={getEnv() !== 'PROD'} />),
        environment: getEnv() === 'PROD' ? 'live' : 'test',
        paymentPlusData: { merchantId },
        checkoutId,
        cart: {
            countryCode,
            languageCode: locale,
            cartAmount: grandTotal,
            currencyCode
        },
        components: [
            ...(!getExcludeBillingInfo() ? billingInfoComponents : []),
            { name: 'PaymentComponent' },
            { name: 'SubmitBtn', type: 'id', id: 'pay-button' }
        ],
        metaData: {
            adyen: {
                translations: translations(intl).adyen,
                placeHolderFontWeight: 300
            }
        },
        authCallback,
        initiateConfigCallback
    };

    return configuration ? (new Payment(configuration)).render() : null;
};

PaymentWidget.defaultProps = {
    initiateConfigData: null
};

PaymentWidget.propTypes = {
    
    locale: PropTypes.string.isRequired,
    initiateConfigData: PropTypes.object,
    currencyCode: PropTypes.string.isRequired,
    eGiftCards: PropTypes.instanceOf(IList).isRequired,
    brandDefaultLocale: PropTypes.string.isRequired,
    paymentStatus: PropTypes.string.isRequired,
    
    processInitiateConfigAction: PropTypes.func.isRequired,
    resetPaymentStatusAction: PropTypes.func.isRequired,
    placeOrder: PropTypes.func.isRequired,
    paymentFailedAction: PropTypes.func.isRequired,
    intl: intlShape.isRequired
};

const mapStateToProps = state => ({
    locale: getIntlLocale(state),
    initiateConfigData: getInitiateConfigData(state),
    currencyCode: getIntlCurrencyCode(state),
    eGiftCards: getEGiftCards(state),
    brandDefaultLocale: getBrandDefaultLocale(state),
    paymentStatus: getPaymentStatus(state)
});

const mapDispatchToProps = {
    processInitiateConfigAction: processInitiateConfig,
    resetPaymentStatusAction: resetPaymentStatus,
    paymentFailedAction: paymentFailed,
    placeOrder: processPayment
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentWidget));
