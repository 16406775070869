import { createBrowserHistory } from 'history';
import { changeLocation } from './routingModule';

const browserHistory = createBrowserHistory({});

export const history = browserHistory;

export const createLocationChangeActionDispatcher = store => (
    history.listen((location, action) => {
        const { state } = location;
        if (!state || !state.bypassAnalytics) {
            store.dispatch(changeLocation(location, action));
        }
    })
);

const updateSearchString = (params = []) => {
    const search = new window.URLSearchParams(window.location.search);
    Object.entries(params).forEach(([key, value]) => search.set(key, value));
    return search.toString();
};

export const replace = ({
    pathname, params, state = {}, ...rest
}) => {
    history.replace(
        {
            pathname,
            search: updateSearchString(params),
            ...rest
        },
        { ...state }
    );
};

export const push = ({
    pathname, params, state = {}, ...rest
}) => {
    history.push(
        {
            pathname,
            search: updateSearchString(params),
            ...rest
        },
        { ...state }
    );
};

export const getURLSearchParams = () => new window.URLSearchParams(window.location.search);

export const updateSearch = (params) => {
    history.replace({ search: updateSearchString(params) }, history.location.state);
};

export const removeQueryParams = (paramKeys) => {
    const search = new window.URLSearchParams(window.location.search);
    paramKeys.forEach(paramKey => search.delete(paramKey));
    const state = history.location.state || {};
    
    state.bypassAnalytics = true;
    history.replace({ search: search.toString() }, state);
};
