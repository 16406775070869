import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import {
    Typography, Card
} from '@mui/material';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import IconButton from '@mui/material/IconButton';

const MoreOffersCoupons = (props) => {
    const {
        toggleDrawer,
        cssClassName,
        heading,
        variant,
        couponList,
        offersTextCssClassName,
        offersText
    } = props;


    return (
        <Card className={cssClassName}>
            <div className='apply-coupon-title-disabled'>
                {(variant === 'apply')
                    && <LocalOfferOutlinedIcon className='offers-icon' />
                }
                <Typography className='apply-coupon-title'>
                    {heading}
                </Typography>
            </div>

            <div className='apply-coupon-desc'>
                <Typography className={offersTextCssClassName}>{offersText}</Typography>
            </div>

            {couponList && toggleDrawer
            && (
                <IconButton onClick={toggleDrawer(true)}>
                    <ArrowForwardIosOutlinedIcon />
                </IconButton>
            )
            }
        </Card>
    );
};

MoreOffersCoupons.defaultProps = {
    toggleDrawer: null,
    couponList: null
};

MoreOffersCoupons.propTypes = {
    cssClassName: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
    toggleDrawer: PropTypes.func,
    couponList: PropTypes.array,
    offersTextCssClassName: PropTypes.string.isRequired,
    offersText: PropTypes.string.isRequired
};

export default injectIntl(MoreOffersCoupons);
