import {
    getHomePath,
    getCategoryPath,
    getPdpPath,
    getPaymentPath,
    getOrderSuccessPath,
    getOrderFailurePath
} from './paths';


export const HOME_STEP = 'home';
export const CATEGORY_STEP = 'category';
export const PDP_STEP = 'pdp';
export const PAYMENT = 'payment';
export const ORDER_SUCCESS = 'order_success';
export const ORDER_FAILURE = 'order_failure';


export const ROUTES = {
    [HOME_STEP]: getHomePath(),
    [CATEGORY_STEP]: getCategoryPath(),
    [PDP_STEP]: getPdpPath(),
    [PAYMENT]: getPaymentPath(),
    [ORDER_SUCCESS]: getOrderSuccessPath(),
    [ORDER_FAILURE]: getOrderFailurePath()
};


export const currencyPickerAllowedPages = [HOME_STEP, PDP_STEP, CATEGORY_STEP];


export const restrictedPagesForLocaleUpdate = [PAYMENT, ORDER_SUCCESS, ORDER_FAILURE];
