import { Map as IMap, fromJS } from 'immutable';
import { createSelector } from 'reselect';
import { DEFAULT_CURRENCY_CODE } from '../constants/constants';

export const getIntl = state => state.get('intl');

export const intlSelector = (state) => {
    const intl = state.get('intl');
    let returnIntl = intl;
    if (IMap.isMap(intl)) {
        returnIntl = intl.toJS();
    }
    return returnIntl;
};

export const intlMessages = createSelector(
    intlSelector,
    intl => ({
        ...intl.messages
    })
);

export const messages = createSelector(
    intlMessages,
    intl => fromJS(intl)
);

export const getIntlLocale = createSelector(
    intlSelector,
    intl => fromJS(intl).get('locale')
);

export const formats = createSelector(
    intlSelector,
    intl => fromJS(intl).get('formats', IMap())
);

export const getTestEnvMessages = createSelector(
    messages,
    intlTestMessages => intlTestMessages.get('testEnvironmentBannerMessages.testEnvironmentBannerMessage', null)
);

export const getCurrencyFormat = createSelector(
    formats,
    intlFormats => intlFormats.getIn(['number', 'currency'], IMap())
);

export const getIntlCurrencyCode = createSelector(
    getCurrencyFormat,
    currencyFormat => currencyFormat.get('currency', DEFAULT_CURRENCY_CODE)
);
