import { Map as IMap, List as IList } from 'immutable';
import { createSelector } from 'reselect';

import {
    PROMO_STORE_KEY,
    ACTIVE_PROMO,
    PROMO_STATUS,
    REJECTED_PROMO,
    PROMO_LIMIT_EXCEEDED
} from './promoModule';

export const getPromoState = state => state.get(PROMO_STORE_KEY, IMap());

export const getActivePromo = createSelector(
    getPromoState,
    promoState => promoState.get(ACTIVE_PROMO, null)
);

export const getActivePromoCode = createSelector(
    getActivePromo,
    promo => (promo ? promo.get('promoCode') : null)
);

export const getPromoRewards = createSelector(
    getPromoState,
    promoRewards => (promoRewards ? promoRewards.get('rewards') : null)
);

export const getCartQualifiesForPromo = createSelector(
    getPromoRewards,
    cartqualify => (cartqualify ? cartqualify.get('cartQualifies') : false)
);

export const getBonusCards = createSelector(
    getPromoRewards,
    bonusCards => (bonusCards ? bonusCards.get('electronicRewardCards', IList()) : IList())
);

export const getActivePromoCodeWithRewards = createSelector(
    [getActivePromo, getBonusCards],
    (promo, bonusCard) => (promo && bonusCard !== IList() ? promo.get('promoCode') : null)
);

export const getFirstBonusCard = createSelector(
    getBonusCards,
    bonusCard => (bonusCard ? bonusCard.get(0, IMap()) : IMap())
);

export const getPromoStatus = createSelector(
    getPromoState,
    promoState => promoState.get(PROMO_STATUS, null)
);

export const getRejectedPromo = createSelector(
    getPromoState,
    promoState => promoState.get(REJECTED_PROMO, null)
);

export const getPromoLimitExceeded = createSelector(
    getPromoState,
    promoState => promoState.get(PROMO_LIMIT_EXCEEDED, false)
);
