import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import { injectIntl, intlShape } from 'react-intl';
import { getIsWebView } from '../webView/webViewSelectors';

import loaderMessages from './messages/loaderMessages';

const Loader = ({ intl, showTestEnv = false }) => {
    const isMbAppView = useSelector(state => getIsWebView(state));

    return (
        <Grid container className={`loader-container ${showTestEnv && 'test-env'} ${isMbAppView && 'mb-app-view'} `}>
            <Box className="loader-screen">
                <CircularProgress color="inherit" />
                <Typography className="loader-text">
                    {intl.formatMessage(loaderMessages.loadingTitle)}
                </Typography>
            </Box>
        </Grid>
    );
};

Loader.propTypes = {
    
    intl: intlShape.isRequired,
    showTestEnv: PropTypes.bool.isRequired
};

export default injectIntl(Loader);
