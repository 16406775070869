import React from 'react';
import PropTypes from 'prop-types';
import { Navigate as ReactRouterNavigate } from 'react-router-dom';



const Navigate = ({ pathname }) => <ReactRouterNavigate to={{ pathname, search: window.location.search }} />;

Navigate.propTypes = {
    pathname: PropTypes.string.isRequired
};

export default Navigate;
