import {
    XS_BREAKPOINT_CUTOFF, SM_BREAKPOINT_CUTOFF,
    MD_BREAKPOINT_CUTOFF, MAX_SCREEN_WIDTH_CUTOFF,
    isMobileview, isTabView, isDesktopView
} from '../../utils/deviceTypeUtils';


export const responsive = {
    desktop: {
        breakpoint: { max: MAX_SCREEN_WIDTH_CUTOFF, min: (MD_BREAKPOINT_CUTOFF - 1) },
        items: 4,
        slidesToSlide: 4,
        partialVisibilityGutter: 50
    },
    tablet: {
        breakpoint: { max: (MD_BREAKPOINT_CUTOFF - 1), min: (SM_BREAKPOINT_CUTOFF - 1) },
        items: 3,
        slidesToSlide: 3,
        partialVisibilityGutter: 60
    },
    mobile: {
        breakpoint: { max: (SM_BREAKPOINT_CUTOFF - 1), min: XS_BREAKPOINT_CUTOFF },
        items: 2,
        slidesToSlide: 2,
        partialVisibilityGutter: 40
    }
};

export const setInitialCarouselTransform = (
    windowWidth,
    faceplateLength,
    setadditionalTransfrom
) => {
    if (faceplateLength === 4 && isDesktopView(windowWidth)) {
        setadditionalTransfrom(-198);
    } else if (faceplateLength === 3 && isTabView(windowWidth)) {
        setadditionalTransfrom(-180);
    } else if (faceplateLength === 2 && isMobileview(windowWidth)) {
        setadditionalTransfrom(-80);
    } else {
        setadditionalTransfrom(0);
    }
};

const setCarouselTransformEnd = (carouselEndReached, device, setadditionalTransfrom, faceplatesLength) => {
    carouselEndReached.current = true;
    switch (device) {
        case 'mobile':
            setadditionalTransfrom(-96 - (16 * (faceplatesLength - 3)));
            break;
        case 'tab':
            setadditionalTransfrom(-196 - (16 * (faceplatesLength - 4)));
            break;
        default:
            setadditionalTransfrom(-216 - (16 * (faceplatesLength - 5)));
            break;
    }
};

export const setCarouselTransform = (
    carouselRef,
    prevSlideNumber, carouselEndReached,
    device, faceplatesLength,
    nextSlideNumber,
    forwardVal, reverseVal, endVal,
    additionalTransfrom, setadditionalTransfrom
) => {
    const { totalItems } = carouselRef.current.state;
    const { slidesToShow } = carouselRef.current.state;
    const lastSetOfSlides = (totalItems - nextSlideNumber) === slidesToShow;

        if ((prevSlideNumber.current <= nextSlideNumber) && !lastSetOfSlides) {
        setadditionalTransfrom(additionalTransfrom + forwardVal);
    }

        if ((prevSlideNumber.current > nextSlideNumber) && !carouselEndReached.current) {
        setadditionalTransfrom(additionalTransfrom + reverseVal);
    }

        if (carouselEndReached.current) {
        setadditionalTransfrom(endVal * nextSlideNumber);
    }

    prevSlideNumber.current = nextSlideNumber;
    carouselEndReached.current = false;
        if (lastSetOfSlides) {
        setCarouselTransformEnd(carouselEndReached, device, setadditionalTransfrom, faceplatesLength);
    } else if (nextSlideNumber === 0) {
        setadditionalTransfrom(0);
    }
};

export const setImageWidthAndMargin = (faceplatesLength, windowWidth, index, device) => {
    let width;
    let marginLeft;

    switch (device) {
        case 'mobile':
            width = (windowWidth / 2) - 40;
            break;
        case 'tab':
            width = (windowWidth / 3) - 60;
            marginLeft = (faceplatesLength <= 2) ? (-60 * index) : 0;
            break;
        default:
            width = (windowWidth / 4) - 60;
            marginLeft = (faceplatesLength <= 3) ? (-50 * index) : 0;
            break;
    }
    const height = (width * 63.1) / 100;
    return { width, marginLeft, height };
};
