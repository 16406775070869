import React, { useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
    Link, Stack, Button, Typography
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { List as IList } from 'immutable';
import DrawerComponent from './DrawerComponent';

import headerMessages from './message/HeaderMessage';
import Logo from './Logo';
import dynamicLinkMessages from '../brand/messages/DynamicLinkMessages';
import languagePickerMessages from '../intl/languagePickerMessages';
import { getIntlLocale } from '../intl/intlSelectors';
import { getSupportedLocales } from '../brand/brandSelectors';
import { getCurrencyPickerStatus } from '../localeCurrencyPicker/LocaleCurrencyPickerSelector';

const Flag = ({ countryCode, styleNames = {} }) => <i className={classnames(styleNames, 'flag', countryCode)} />;

const countryToCurrency = require('country-to-currency');

const HeaderContainer = ({
    intl, locale, callbackIsOpen, supportedLocales,
    isCurrencyPickerStatus
}) => {
    const [value, setValue] = useState(0);

    const switchTab = (e, newValue) => {
        setValue(newValue);
    };

    const handleClickOpen = () => {
        callbackIsOpen(true);
    };

    const languageText = new Intl.DisplayNames([locale.split('-')[0]], {
        type: 'language'
    }).of(locale.split('-')[0]);

    const countrySelected = locale.split('-')[1];

    const fullySupportedLocales = supportedLocales.filter(supportedLocale => supportedLocale in languagePickerMessages);
    const supportedLocalesUniqueObj = {};
    let fullySupportedLocalesUniqueArr = [];

    const localeCountryCode = locale.split('-')[1];

    fullySupportedLocales.map((item) => {
        const countryCode = item.split('-')[1];

        if (!supportedLocalesUniqueObj[countryCode]) {
            supportedLocalesUniqueObj[countryCode] = (countryCode === localeCountryCode) ? locale : item;
        }
        return item;
    });

    fullySupportedLocalesUniqueArr = Object.values(supportedLocalesUniqueObj);

    const selectedCountryOnly = locale.split('-')[1];

    const localesForSelectedCountry = fullySupportedLocales.filter((supportedLocale) => {
        if (supportedLocale.split('-')[1] === selectedCountryOnly) {
            return supportedLocale;
        }
        return false;
    });

    return (
        <>
            <Box className='localizationHeader'>
                <Stack direction="row" spacing={4}>
                    {}
                    {fullySupportedLocalesUniqueArr.length <= 1 && localesForSelectedCountry.size <= 1
                        ? null
                        : (isCurrencyPickerStatus && (
                            <Button variant="text" className='localizationHeaderButton' onClick={handleClickOpen}>
                                <Flag className='localizationHeaderFlag' countryCode={countrySelected} />
                                <Typography className='localizationHeaderText'>
                                    -
                                    {' '}
                                    {countryToCurrency[countrySelected.toUpperCase()]}
                                    {' '}
                                    |
                                    {' '}
                                    {languageText}
                                </Typography>
                            </Button>
                        ))
                    }
                </Stack>
            </Box>
            <Box className='header'>
                <Logo />
                <Tabs
                    value={value}
                    onChange={switchTab}
                    className='headerTab'
                >
                    <Link
                        className='headerLeft'
                        href={intl.formatMessage(dynamicLinkMessages.customerSupport)}
                        disableRipple
                        rel='noreferrer noopener'
                        target='_blank'
                    >
                        {intl.formatMessage(headerMessages.headerGiftCards)}
                    </Link>

                    <Link
                        className='headerRight'
                        href={intl.formatMessage(dynamicLinkMessages.manageGiftCard)}
                        disableRipple
                        rel='noreferrer noopener'
                        target='_blank'
                    >
                        {intl.formatMessage(headerMessages.manageGiftCard)}
                    </Link>
                    <DrawerComponent
                        handleClickOpen={handleClickOpen}
                        fullySupportedLocalesUniqueArr={fullySupportedLocalesUniqueArr}
                        localesForSelectedCountry={localesForSelectedCountry}
                    />
                </Tabs>
            </Box>
        </>
    );
};

Flag.defaultProps = {
    styleNames: {}
};

Flag.propTypes = {
    countryCode: PropTypes.string.isRequired,
    styleNames: PropTypes.object
};

HeaderContainer.defaultProps = {
    supportedLocales: IList()
};

HeaderContainer.propTypes = {
    intl: intlShape.isRequired,
    locale: PropTypes.string.isRequired,
    callbackIsOpen: PropTypes.func.isRequired,
    supportedLocales: PropTypes.instanceOf(IList),
    isCurrencyPickerStatus: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    locale: getIntlLocale(state),
    supportedLocales: getSupportedLocales(state),
    isCurrencyPickerStatus: getCurrencyPickerStatus(state)
});

export default connect(mapStateToProps)(injectIntl(HeaderContainer));
