export const getHostname = () => {
    if (window && window.location && window.location.hostname) return window.location.hostname;
    return '';
};

export const getReferrer = () => {
    if (document && document.referrer) return document.referrer;
    return '';
};

export const getUrlParams = () => {
    // Provided by our 'url-search-params' polyfill for crappy browsers
    
    let urlParams;
    if (window && window.location && typeof URLSearchParams !== 'undefined') {
        urlParams = new URLSearchParams(window.location.search);
    }

    const sessionDetails = {
        ref: '',
        affiliateCode: ''
    };

    if (typeof urlParams !== 'undefined') {
        sessionDetails.ref = urlParams.get('ref') || '';
        sessionDetails.affiliateCode = urlParams.get('affiliate_id') || '';
    }

    return sessionDetails;
};
