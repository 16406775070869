import React, { Fragment } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { ListItem, Button } from '@mui/material';

import footerLinksDefault from '../footer/messages/FooterMessages';

export const DrawerFooterLinksDefault = ({
    intl
}) => (
    <Fragment>
        <ListItem>
            <Button
                className='drawerHeaderButton'
                href='/store/self_service/v2/about/faq'
                rel='noreferrer noopener'
                target='_blank'
            >
                {intl.formatMessage(footerLinksDefault.faq)}
            </Button>
        </ListItem>
        <ListItem>
            <Button
                className='drawerHeaderButton'
                href='/store/self_service/v2/about/terms_and_conditions'
                rel='noreferrer noopener'
                target='_blank'
            >
                {intl.formatMessage(footerLinksDefault.terms)}
            </Button>
        </ListItem>
        <ListItem>
            <Button
                className='drawerHeaderButton'
                href='/store/self_service/v2/about/privacy_policy'
                rel='noreferrer noopener'
                target='_blank'
            >
                {intl.formatMessage(footerLinksDefault.privacy)}
            </Button>

        </ListItem>
        <ListItem>
            <Button
                className='drawerHeaderButton'
                href={intl.formatMessage(footerLinksDefault.accessibilityLink)}
                rel='noreferrer noopener'
                target='_blank'
            >
                {intl.formatMessage(footerLinksDefault.accessibility)}
            </Button>
        </ListItem>
    </Fragment>
);

DrawerFooterLinksDefault.propTypes = {
    intl: intlShape.isRequired
};


export default injectIntl(DrawerFooterLinksDefault);
