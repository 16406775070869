import { List as IList, Map as IMap, fromJS } from 'immutable';

const createCatalogMap = (catalog) => {
    const electronicCatalog = catalog.get('electronicCatalog', IMap());
    const maxTransactionAmount = catalog.get('maxTransactionAmount');
    const digitalActivationFeeAmount = catalog.get('digitalActivationFeeAmount', '0.00');

    return IMap({
        egcRangedCatalog: electronicCatalog.get('rangedCatalog', IMap()),
        egcFixedCatalog: electronicCatalog.get('fixedCatalog', IList()),
        egcRecommendedDenoms: electronicCatalog.get('recommendedDenoms', IList()),
        egcDefaultAmount: electronicCatalog.get('defaultAmount'),
        digitalActivationFeeAmount,
        maxTransactionAmount
    });
};

export const parseCatalogs = catalogs => fromJS(catalogs)
    .mapEntries(([currency, catalog]) => {
        if (IList.isList(catalog)) {
            const parsedCatalogs = catalog.map(brandCatalog => createCatalogMap(brandCatalog));
            return [
                currency,
                parsedCatalogs
            ];
        }
        return [
            currency,
            createCatalogMap(catalog)
        ];
    });


export const getInCatalog = (catalog, keys, defaultValue = null, catalogIndex = 0) => {
    if (IList.isList(catalog)) {
        return catalog.get(catalogIndex).getIn(keys, defaultValue);
    }
    return catalog.getIn(keys, defaultValue);
};
