import { defineMessages } from 'react-intl';

const headerMessages = defineMessages({
    headerGiftCards: {
        id: 'header.menu.giftcard',
        description: 'giftcard menu text',
        defaultMessage: 'Gift Cards'
    },
    manageGiftCard: {
        id: 'header.menu.manageGiftCard',
        description: 'Manage gift card header text',
        defaultMessage: 'Manage Gift Cards'
    },
    linkText: {
        id: 'header.headerMessages.linkText',
        description: 'Text within the header logo link to indicate where clicking the header image '
            + 'will navigate to - not screen visible',
        defaultMessage: '{brandName}'
    },
    logoSrc: {
        id: 'header.headerMessages.logoSrc',
        description: 'Src of the logo',
        defaultMessage: '{brandCode}.svg'
    },
    pageTitle: {
        id: 'header.headerMessages.pageTitle',
        description: 'Title heading of the Application - not screen visible',
        defaultMessage: '{brandName} Gift Cards by CashStar'
    }
});

export default headerMessages;
