import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import { runSaga } from 'redux-saga';
import { fetchBrandConfig, fetchBannerConfig } from 'capi/redux';
import { BRAND_CONFIG_FIELDS } from 'capi';

import App from './app/App';
import appStore from './app/appStore';
import { initializeIntl } from './intl/intlSagas';
import loadPolyfills from './app/polyfills';
import getWebfonts from './fontloaders/fontModule';
import { getOrCreateStorefrontSession } from './session/sessionModule';
import { requestFaceplateGroups } from './landingPage/landingPageModule';
import initializeIovation from './iovation/iovationModule';
import { createLocationChangeActionDispatcher } from './routing/routing';
import { setIsWebViewValue } from './webView/webViewModule';
import { getInitialPromo } from './promo/promoModule';

const run = async () => {
    
    if (process.env.NODE_ENV === 'development') {
        
        const axe = require('@axe-core/react');
        axe(React, ReactDOM, 1000);

        
        const whyDidYouRender = require('@welldone-software/why-did-you-render');
        whyDidYouRender(React);
    }

    
    setIsWebViewValue(appStore.dispatch);

    
    const sessionAction = getOrCreateStorefrontSession();
    appStore.dispatch(sessionAction);

    
    const requiredFields = [
        BRAND_CONFIG_FIELDS.PREFERENCES,
        BRAND_CONFIG_FIELDS.CATALOG,
        BRAND_CONFIG_FIELDS.PAYMENT,
        BRAND_CONFIG_FIELDS.BALANCE,
        BRAND_CONFIG_FIELDS.META,
        BRAND_CONFIG_FIELDS.PROGRAMS,
        BRAND_CONFIG_FIELDS.LIMITS,
        BRAND_CONFIG_FIELDS.FEATURES
    ];
    const configAction = fetchBrandConfig(requiredFields);
    appStore.dispatch(configAction);

    const bannerConfigData = fetchBannerConfig();
    appStore.dispatch(bannerConfigData);

    const sagaArgs = { dispatch: appStore.dispatch, getState: appStore.getState };
    await Promise.all([
        
        getWebfonts(appStore),
        (runSaga(sagaArgs, initializeIntl)).done
        
        
    ]);

    
    const requestFaceplatesAction = requestFaceplateGroups();
    appStore.dispatch(requestFaceplatesAction);

    
    const activeOrDefaultPromoAction = getInitialPromo();
    appStore.dispatch(activeOrDefaultPromoAction);

    
    createLocationChangeActionDispatcher(appStore);

    createRoot(document.getElementById('app-root')).render(<App />);

    
    
    
    initializeIovation(appStore);
};

loadPolyfills().then(() => {
    run();
});
