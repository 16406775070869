import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';

import { intlSelector } from './intlSelectors';

const CustomIntlProvider = ({
    children,
    intl
}) => (
    <IntlProvider {...intl}>
        {children}
    </IntlProvider>
);

CustomIntlProvider.defaultProps = {
    intl: {}
};

CustomIntlProvider.propTypes = {
    children: PropTypes.node.isRequired,
    intl: PropTypes.object
};

const mapStateToProps = state => ({
    intl: intlSelector(state)
});

export default connect(mapStateToProps)(CustomIntlProvider);
