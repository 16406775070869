import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import {
    getHomePath,
    getCategoryPath,
    getPdpPath,
    getPaymentPath,
    getOrderSuccessPath,
    getOrderFailurePath,
    getErrorNoRetryPath
} from './paths';
import Navigate from './Navigate';
import ErrorBoundary from '../error/ErrorBoundary';
import Step from './Step';

import {
    HOME_STEP,
    CATEGORY_STEP,
    PDP_STEP,
    PAYMENT,
    ORDER_SUCCESS,
    ORDER_FAILURE
} from './flowConstants';
import { FlowProvider } from './FlowProvider';

import HomePage from '../landingPage/LandingPage';
import CategoryPage from '../landingPage/CategoryPage/CategoryPage';
import ProductDetailsPage from '../giftCardDetailsPage/ProductDetailsPage';
import OrderSuccess from '../order/OrderSuccess';
import OrderFailure from '../order/OrderFailure';
import PaymentPage from '../payment/PaymentPage';
import NotFound from '../error/NotFound';
import ErrorNoRetry from '../error/ErrorNoRetry';

export const FlowRoute = () => {
    const homePath = getHomePath();
    const navigateToHome = <Navigate pathname={homePath} />;

    return (
        <ErrorBoundary>
            <FlowProvider>
                {  }
                <Suspense fallback='Loading...'>
                    <Routes>
                        <Route key="root" path="/" element={navigateToHome} />
                        <Route
                            key={HOME_STEP}
                            path={getHomePath()}
                            element={<Step stepName={HOME_STEP} component={HomePage} />}
                        />
                        <Route
                            key={CATEGORY_STEP}
                            path={getCategoryPath()}
                            element={<Step stepName={CATEGORY_STEP} component={CategoryPage} />}
                        />
                        <Route
                            key={PDP_STEP}
                            path={getPdpPath()}
                            element={<Step stepName={PDP_STEP} component={ProductDetailsPage} />}
                        />
                        <Route
                            key={PAYMENT}
                            path={getPaymentPath()}
                            element={<Step stepName={PAYMENT} component={PaymentPage} />}
                        />
                        <Route
                            key={ORDER_SUCCESS}
                            path={getOrderSuccessPath()}
                            element={<Step stepName={ORDER_SUCCESS} component={OrderSuccess} />}
                        />
                        <Route
                            key={ORDER_FAILURE}
                            path={getOrderFailurePath()}
                            element={<Step stepName={ORDER_FAILURE} component={OrderFailure} />}
                        />
                        <Route
                            key="error-no-retry"
                            path={getErrorNoRetryPath()}
                            element={<ErrorNoRetry />}
                        />
                        <Route path="*" key="page-not-found" element={<NotFound errorCode={404} />} />
                    </Routes>
                </Suspense>
            </FlowProvider>
        </ErrorBoundary>
    );
};

export default FlowRoute;
