import { defineMessages } from 'react-intl';

const giftCardDetailsPageMessages = defineMessages({
    deliveryDetailsTitle: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.deliveryDetails',
        description: 'Delivery details denom title',
        defaultMessage: 'Delivery details'
    },
    sendViaDetailsTitle: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.sendViaDetails',
        description: 'Send Via details denom title',
        defaultMessage: 'Send via'
    },
    sendViaSharableLinkText1: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.sendViaSharableLinkText1',
        description: 'Send Via Sharable link',
        defaultMessage: 'Shareable Link'
    },
    sendViaSharableLinkText2: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.sendViaSharableLinkText2',
        description: 'Send Via Sharable link',
        defaultMessage: '(LINE, Viber, Text, etc.)'
    },
    sendViaEmailText: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.sendViaEmailText',
        description: 'Send Via Email',
        defaultMessage: 'Email'
    },
    requiredTitle: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.required',
        description: 'Required title',
        defaultMessage: 'Required'
    },
    sendAsGiftToggle: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.sendAsGift',
        description: 'Send as gift toggle button',
        defaultMessage: 'Send as gift'
    },
    buyForSelfToggle: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.buyForSelf',
        description: 'Buy for self toggle button',
        defaultMessage: 'Buy for self'
    },
    toTitle: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.to',
        description: 'To title',
        defaultMessage: 'To'
    },
    fromTitle: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.from',
        description: 'From title',
        defaultMessage: 'From'
    },
    importantHelperText: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.important',
        description: 'important helper text',
        defaultMessage: 'Important'
    },
    recipientEmailLabel: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.recipientEmail',
        description: 'Recipient Email label',
        defaultMessage: 'Recipient Email'
    },
    recipientNameLabel: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.recipientName',
        description: 'Recipient Name label',
        defaultMessage: 'Recipient Name'
    },
    senderEmailLabel: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.senderEmail',
        description: 'Sender Email label',
        defaultMessage: 'Sender\'s Email'
    },
    senderNameLabel: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.senderName',
        description: 'Sender Name label',
        defaultMessage: 'Sender\'s Name'
    },
    yourEmailLabel: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.yourEmail',
        description: 'Your Email label',
        defaultMessage: 'Your Email'
    },
    charactersLeftHelperText: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.charactersLeft',
        description: 'Characters left helper text',
        defaultMessage: '{number} characters left'
    },
    recipientEmailRemark: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.recipientEmailInputCheck',
        description: 'addition of remark for Recipient Email',
        defaultMessage: 'Please refrain from using mobile phone email addresses or disposable email addresses to avoid delivery issues.'
    },
    senderEmailRemark: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.senderEmailInputCheck',
        description: 'addition of remark for Sender Email',
        defaultMessage: 'Please refrain from using mobile phone email addresses or disposable email addresses to avoid delivery issues.'
    },
    personalMessageOptionalLabel: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.personalMessageOptional',
        description: 'Personal message (optional) Label',
        defaultMessage: 'Personal message (optional)'
    },
    chooseAnAmountTitle: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.chooseAnAmount',
        description: 'Choose an amount title',
        defaultMessage: 'Choose an amount'
    },
    customAmountTitle: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.customAmount',
        description: 'Custom amount title',
        defaultMessage: 'Custom amount'
    },
    createAGiftCardTitle: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.createAGiftCard',
        description: 'Create a gift card title',
        defaultMessage: 'Create a gift card'
    },
    proceedToPayButtonText: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.proceedToPay',
        description: 'Proceed to pay button text',
        defaultMessage: 'Proceed to pay'
    },
    giftCardDetailsLink: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.giftCardDetails',
        description: 'Gift card details Link',
        defaultMessage: 'Gift card details'
    },
    concernCheckboxLabel: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.concernCheckboxLabel.md',
        description: 'Concern checkbox main label',
        defaultMessage: 'All the details are double checked and I agree to share the details after reading the privacy policy and terms & conditions.'
    },
    pageRefreshError: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.amountPageRefresh',
        description: 'addition of remark for entering the amount',
        defaultMessage: 'If you receive the error while entering the amount Please refresh the page and try again.'
    },
    yourName: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.yourName',
        description: 'Your Name text field',
        defaultMessage: 'Your Name'
    },
    enterAmount: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.enterAmount',
        description: 'Enter amount field title',
        defaultMessage: 'Enter amount'
    },
    privacyPolicyLink: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.privacyPolicyLink',
        description: 'Privacy policy link',
        defaultMessage: 'Privacy policy'
    },
    termsAndConditionsLink: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.termsAndConditionsLink',
        description: 'Terms & Conditions link',
        defaultMessage: 'Terms & Conditions'
    },
    profaneRecipientName: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.profaneRecipientName',
        description: 'Profanity error message',
        defaultMessage: 'No profanity allowed'
    },
    profaneSenderName: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.profaneSenderName',
        description: 'Profanity error message',
        defaultMessage: 'No profanity allowed'
    },
    profaneMessage: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.profaneMessage',
        description: 'Profanity error message',
        defaultMessage: 'No profanity allowed'
    },
    receiveEmails: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.ReceiveEmail',
        description: 'set permission to receive emails',
        defaultMessage: '*Please set permission to receive emails from @dsc.starbucks.co.jp.​ '
    },
    doublecheckEmail: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.Doublecheck',
        description: 'double check email address',
        defaultMessage: '*Before proceeding to payment, please double check that the email address you entered is correct.'
    }
});

export default giftCardDetailsPageMessages;
