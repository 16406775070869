import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


function PopUpModal({ content, open, handleClose }) {
    return (
        <div>
            <Modal
                className='promo-error-modal'
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='promo-error-box'>
                    {content}
                </Box>
            </Modal>
        </div>
    );
}

PopUpModal.propTypes = {
    content: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default PopUpModal;
