import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';


const ScrollToTopWrapper = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        
        const scrollableContainer = document.getElementsByTagName('html')[0];
        if (scrollableContainer) {
            scrollableContainer.scrollTo(0, 0);
        }
    }, [location.pathname]);

    return children;
};

ScrollToTopWrapper.propTypes = {
    
    children: PropTypes.node.isRequired
};

export default ScrollToTopWrapper;
