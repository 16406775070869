import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';

import giftCardDetailsPageMessages from './messages/giftCardDetailsPageMessages';
import TextInputContainer from '../primitive/input/TextInputContainer';
import { YOUR_EMAIL, YOUR_NAME } from '../constants/constants';
import { getEmailSchema, getNameSchema } from './validation/productDetailsSchema';
import { getProfanityDetectionEnabled } from '../brand/brandSelectors';

const YourEmail = ({ intl }) => (
    <TextInputContainer
        containerClassName='pdpFormField yourEmailFieldBase textFieldOutline'
        className='yourEmailField'
        id='yourEmailField'
        name={YOUR_EMAIL}
        label={(
            <>
                <span className='requiredAsterisk'>*</span>
                {intl.formatMessage(giftCardDetailsPageMessages.yourEmailLabel)}
            </>
        )}
        validationSchema={getEmailSchema(intl)}
        helperText={`${intl.formatMessage(giftCardDetailsPageMessages.senderEmailRemark)}`}
        customPlaceHolder='e.g. email@abc.com'
    />
);

YourEmail.propTypes = {
    intl: PropTypes.object.isRequired
};

const YourName = ({ intl, isProfanityDetectionEnabled }) => (
    <TextInputContainer
        containerClassName='pdpFormField yourNameFieldBase textFieldOutline'
        className='yourNameField'
        id='yourNameField'
        name={YOUR_NAME}
        label={(
            <>
                <span className='requiredAsterisk'>*</span>
                {intl.formatMessage(giftCardDetailsPageMessages.yourName)}
            </>
        )}
        validationSchema={getNameSchema(intl, isProfanityDetectionEnabled, YOUR_NAME, giftCardDetailsPageMessages.profaneSenderName)}
    />
);

YourName.propTypes = {
    intl: PropTypes.object.isRequired,
    isProfanityDetectionEnabled: PropTypes.bool.isRequired
};

const SelfBuyForm = (props) => {
    const { setValue, getValues, trigger } = useFormContext();
    const { intl, isProfanityDetectionEnabled, productDetails: { yourName, yourEmail } = {} } = props;

    React.useEffect(() => {
        if (yourName && !getValues(YOUR_NAME)) {
            setValue(YOUR_NAME, yourName);
            trigger(YOUR_NAME);
        }
        if (yourEmail && !getValues(YOUR_EMAIL)) {
            setValue(YOUR_EMAIL, yourEmail);
            trigger(YOUR_EMAIL);
        }
    }, []);

    return (
        <div className='deliveryformDiv'>
            <YourEmail intl={intl} />
            <YourName
                intl={intl}
                isProfanityDetectionEnabled={isProfanityDetectionEnabled}
            />
        </div>
    );
};

SelfBuyForm.defaultProps = {
    productDetails: {}
};


SelfBuyForm.propTypes = {
    intl: intlShape.isRequired,
    productDetails: PropTypes.object,
    isProfanityDetectionEnabled: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    const isProfanityDetectionEnabled = getProfanityDetectionEnabled(state);
    return {
        isProfanityDetectionEnabled
    };
};

export default connect(mapStateToProps)(injectIntl(SelfBuyForm));
