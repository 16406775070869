import {
    requestDefaultBrandPromo,
    requestPromoForCode,
    calculatePromoRewards,
    requestOrderWillExceedRedemptionLimitsV4View
} from 'capi';
import { Map as IMap, fromJS } from 'immutable';
import {
    put,
    call,
    select,
    takeLatest,
    take,
    all
} from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { logError } from '../utils/errorUtils';
import { getPreferredPromoCode } from '../app/bootstrap';
import { orderSerializer } from '../payment/capiSerializer';
import { getOrderMetaData } from '../payment/paymentSelectors';
import { getCapiClient } from '../session/sessionSelectors';
import { removeQueryParams } from '../routing/routing';
import { getIntlLocale, getIntlCurrencyCode } from '../intl/intlSelectors';
import { SET_CURRENCY, SET_LOCALE, INTL_CURRENCY_UPDATED } from '../intl/intlModule';

import {
    getActivePromo,
    getActivePromoCode
} from './promoSelectors';
import {
    GET_INITIAL_PROMO,
    GET_PROMO_FOR_CODE,
    APPLY_ACTIVE_PROMO,
    INVALID_PROMO_ERROR,
    EXPIRED_PROMO_ERROR,
    SOLD_OUT_PROMO_ERROR,
    PROMO_VALID_SUCCESS,
    SET_REJECTED_PROMO,
    RESET_PROMO,
    REWARDS_REQUEST_STATE,
    setPromo,
    setPromoError,
    setPromoRewards,
    setRejectedPromo,
    setPromoStatus,
    clearPromoStatus,
    clearRejectedPromos,
    clearActivePromo,
    setRewardsRequestActive,
    setRewardsRequestFailure,
    removePromoRewards,
    setPromoByCodeRequestState,
    removeCustomRewardCardRecipients,
    setPromoLimitExceeded
} from './promoModule';
import { getEGiftCards } from '../giftCardDetailsPage/productDetailsSelector';


const isSuccessStatus = status => status === PROMO_VALID_SUCCESS;

const getPromoStatus = (promoData, applySoldOutPromo) => {
    if (!promoData || !promoData.promoCode) {
        return INVALID_PROMO_ERROR;
    }

    if (!promoData.active) {
        return EXPIRED_PROMO_ERROR;
    }

    if (promoData.soldOut && !applySoldOutPromo) {
        return SOLD_OUT_PROMO_ERROR;
    }

    return PROMO_VALID_SUCCESS;
};

function* getPromoBy(args) {
    const capiClient = yield select(getCapiClient);
    let { locale, currency } = args;
    const {
        promoFetchFn,
        getPromoArgs,
        errorReturnVal,
        codeOrDefault
    } = args;
    if (!locale) {
        locale = yield select(getIntlLocale);
    }
    if (!currency) {
        currency = yield select(getIntlCurrencyCode);
        if (!currency) {
            
            
            yield take(INTL_CURRENCY_UPDATED);
            currency = yield select(getIntlCurrencyCode);
        }
    }
    try {
        return yield call(
            promoFetchFn,
            capiClient,
            ...getPromoArgs(locale, currency)
        );
    } catch (error) {
        yield put(setPromoError(codeOrDefault, error));
        return errorReturnVal;
    }
}

function* getDefaultPromo(locale, currency) {
    return yield call(getPromoBy, {
        codeOrDefault: 'default',
        promoFetchFn: requestDefaultBrandPromo,
        getPromoArgs: (promoLocale, promoCurrency) => [promoLocale, promoCurrency],
        errorReturnVal: null,
        locale,
        currency
    });
}

function* setDefaultPromo(defaultPromoData) {
    const statusType = yield call(getPromoStatus, defaultPromoData, true);
    if (defaultPromoData && isSuccessStatus(statusType)) {
        yield put(setPromo(defaultPromoData));
    }
}

export function* fetchDefaultPromo() {
    const defaultPromoData = yield call(getDefaultPromo);
    yield call(setDefaultPromo, defaultPromoData);
}

function* getPromoForCode(promoCode, locale, currency) {
    yield put(setPromoByCodeRequestState(REWARDS_REQUEST_STATE.ACTIVE));
    const promo = yield call(getPromoBy, {
        codeOrDefault: promoCode,
        promoFetchFn: requestPromoForCode,
        getPromoArgs: (promoLocale, promoCurrency) => [promoCode, promoLocale, promoCurrency],
        errorReturnVal: {},
        locale,
        currency
    });
    yield put(setPromoByCodeRequestState(REWARDS_REQUEST_STATE.SUCCESS));
    return promo;
}

export function* fetchPromoForCode(action) {
    const { promoCode, applySoldOutPromo } = action;
    const promoData = yield call(getPromoForCode, promoCode);
    const statusType = getPromoStatus(promoData, applySoldOutPromo);

    if (isSuccessStatus(statusType)) {
        yield put(setPromo(promoData));
    }
    yield put(setPromoStatus({ statusType, promoCode }));

    return statusType;
}

function* capiV4ApplyPromoRewards(promoCode, activeItems, orderMetaData) {
    
    const payment = IMap({
        senderEmail: `${uuid()}@dummy.email`,
        firstName: 'DUMMYNAME'
    });
    let promoLimitExceeded = false;
    const orderData = yield call(
        orderSerializer,
        payment,
        orderMetaData,
        activeItems
    );

    const limitValidationDataPromo = IMap({
        promoCode,
        externalIds: [{
            externalId: orderData.get('egiftCards').get(0).get('selfBuy')
                ? orderData.get('egiftCards').get(0).get('recipientEmail')
                : orderData.get('egiftCards').get(0).get('senderEmail'),
            externalIdType: 'PURCHASER_EMAIL'
        }]
    });

    const capiClient = yield select(getCapiClient);
    try {
        yield put(setRewardsRequestActive());
        
        const rewards = yield call(
            calculatePromoRewards,
            capiClient,
            promoCode,
            orderData
        );

        const limitData = yield call(
            requestOrderWillExceedRedemptionLimitsV4View,
            capiClient,
            limitValidationDataPromo
        );

        if (rewards) {
            yield put(setPromoRewards(fromJS(rewards)));
        } else {
            yield put(setPromoRewards(IMap()));
        }

        if (limitData.willExceedRedemptionLimits) {
            yield put(setPromoRewards(IMap()));
            promoLimitExceeded = rewards && rewards.cartQualifies;
        }
        yield put(setPromoLimitExceeded(promoLimitExceeded));
    } catch (error) {
        yield put(setPromoError(promoCode, error));
        yield put(setRewardsRequestFailure());
        logError(error, { promoCode, additionalMessage: 'Error while processing promo' });
    }
}

export function* applyPromoRewards() {
    const activeItems = yield select(getEGiftCards);
    const promo = yield select(getActivePromo);

    
    if (promo && promo.size && activeItems && activeItems.size) {
        const promoCode = promo.get('promoCode');
        const orderMetaData = yield select(getOrderMetaData);
        yield call(capiV4ApplyPromoRewards, promoCode, activeItems, orderMetaData);
    }
}

function* handleNonDefaultPromo(action) {
    const statusType = yield call(fetchPromoForCode, action);
    if (isSuccessStatus(statusType)) {
        yield call(applyPromoRewards);
        
        yield call(removeQueryParams, ['promoCode']);
    }
}

function* handleDefaultPromo() {
    yield call(fetchDefaultPromo);
    yield call(applyPromoRewards);
}

function* checkStatusAndSetPromo(promoCode, promoData) {
    let success = false;
    if (promoData) {
        const statusType = yield call(getPromoStatus, promoData, true);
        success = isSuccessStatus(statusType);
    }

    if (success) {
        yield put(setPromo(promoData));
    } else {
        yield put(setRejectedPromo(promoCode));
    }

    return success;
}

function* getAllPromos(preferredPromoCode, storedPromoCode, locale, currency) {
    const fetchPromoActions = { default: call(getDefaultPromo, locale, currency) };
    if (preferredPromoCode) {
        fetchPromoActions.request = call(getPromoForCode, preferredPromoCode, locale, currency);
    }
    if (storedPromoCode) {
        fetchPromoActions.stored = call(getPromoForCode, storedPromoCode, locale, currency);
    }
    return yield all(fetchPromoActions);
}

function* removeRequestPromo() {
    yield call(removeQueryParams, ['promoCode']);
}

function* clearPromotionData() {
    yield put(clearPromoStatus());
    yield put(clearActivePromo());
    yield put(removePromoRewards());
    yield put(clearRejectedPromos());
    yield put(removeCustomRewardCardRecipients());
}

function* handlePromoReset() {
    yield call(removeRequestPromo);
    yield call(clearPromotionData);
    yield call(handleDefaultPromo);
}

function* updatePromoForLanguageAndCurrencyUpdates({ locale, currency }) {
    const storedPromoCode = yield select(getActivePromoCode);

    yield put(setRewardsRequestActive());
    yield call(clearPromotionData);

    const allPromoData = yield call(getAllPromos, null, storedPromoCode, locale, currency);

    let validStoredPromo = false;
    
    if (storedPromoCode) {
        validStoredPromo = yield call(checkStatusAndSetPromo, storedPromoCode, allPromoData.stored);
    }

    
    if (allPromoData.default && !validStoredPromo) {
        yield call(setDefaultPromo, allPromoData.default);
    }

    
    yield call(applyPromoRewards);
}

function* initialGetPromo({ locale, currency }) {
    const preferredPromoCode = yield call(getPreferredPromoCode);
    const storedPromoCode = yield select(getActivePromoCode);

    const allPromoData = yield call(getAllPromos, preferredPromoCode, storedPromoCode, locale, currency);

    
    let validPreferredPromo = false;
    if (preferredPromoCode) {
        validPreferredPromo = yield call(checkStatusAndSetPromo, preferredPromoCode, allPromoData.request);
    }

    let validStoredPromo = false;
    
    if (storedPromoCode && !validPreferredPromo) {
        validStoredPromo = yield call(checkStatusAndSetPromo, storedPromoCode, allPromoData.stored);
    }

    
    if (allPromoData.default && !validPreferredPromo && !validStoredPromo) {
        yield call(setDefaultPromo, allPromoData.default);
    }

    
    yield call(applyPromoRewards);
}

export function* promoSagasWatcher() {
    yield takeLatest(GET_INITIAL_PROMO, initialGetPromo);
    yield takeLatest(GET_PROMO_FOR_CODE, handleNonDefaultPromo);
    yield takeLatest(APPLY_ACTIVE_PROMO, applyPromoRewards);
    yield takeLatest(SET_REJECTED_PROMO, removeRequestPromo);
    yield takeLatest(RESET_PROMO, handlePromoReset);
    yield takeLatest([SET_LOCALE, SET_CURRENCY], updatePromoForLanguageAndCurrencyUpdates);
}
