import { Map as IMap } from 'immutable';

import { productClickedSegmentData } from '../segment/segmentSelectors';



export const SAVE_SELECTED_CARD = '@@storefront/SAVE_SELECTED_CARD';
export const RESET_CARD_SELECTION = '@@storefront/RESET_CARD_SELECTION';
export const REQUEST_FACEPLATE_GROUPS = '@@storefront/REQUEST_FACEPLATE_GROUPS';
export const SET_FACEPLATE_GROUPS = '@@storefront/SET_FACEPLATE_GROUPS';
export const FACEPLATE_PREFETCH_IMAGES = '@@storefront/FACEPLATE_PREFETCH_IMAGES';
export const FACEPLATE_PREFETCH_END = '@@storefront/FACEPLATE_PREFETCH_END';

const initialState = IMap({});


const faceplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_SELECTED_CARD: {
            let { faceplate } = action;
            faceplate = faceplate.set('category', action.category);
            return state.set('selectedFaceplate', faceplate);
        }
        case SET_FACEPLATE_GROUPS: {
            return state.set('faceplateGroups', action.faceplateGroups);
        }
        case RESET_CARD_SELECTION:
            return state.delete('selectedFaceplate');
        default:
    }
    return state;
};


export const saveSelectedCard = (category, faceplate) => ({
    type: SAVE_SELECTED_CARD,
    category,
    faceplate,
    meta: {
        segment: {
            eventAction: 'Product Clicked',
            eventData: productClickedSegmentData(faceplate, category)
        }
    }
});

export const resetCardSelection = () => ({
    type: RESET_CARD_SELECTION
});

export const requestFaceplateGroups = params => ({
    type: REQUEST_FACEPLATE_GROUPS,
    params
});

export const setFaceplateGroups = faceplateGroups => ({
    type: SET_FACEPLATE_GROUPS,
    faceplateGroups
});

export const faceplatePrefetchImages = faceplates => ({
    type: FACEPLATE_PREFETCH_IMAGES,
    faceplates
});

export default faceplateReducer;
