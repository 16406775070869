export const XS_BREAKPOINT_CUTOFF = 0;
export const SM_BREAKPOINT_CUTOFF = 768;
export const MD_BREAKPOINT_CUTOFF = 1024;
export const LG_BREAKPOINT_CUTOFF = 1280;
export const XL_BREAKPOINT_CUTOFF = 1600;

export const MAX_SCREEN_WIDTH_CUTOFF = 2600;

export const isMobileview = windowWidth => windowWidth < SM_BREAKPOINT_CUTOFF;

export const isTabView = windowWidth => (
    windowWidth >= SM_BREAKPOINT_CUTOFF && windowWidth < MD_BREAKPOINT_CUTOFF);

export const isDesktopView = windowWidth => windowWidth >= MD_BREAKPOINT_CUTOFF;

export const deviceType = (windowWidth) => {
    switch (true) {
        case isMobileview(windowWidth):
            return 'mobile';
        case isTabView(windowWidth):
            return 'tab';
        default: return 'desktop';
    }
};

export const getBrowserName = (navigator) => {
    const { userAgent } = navigator;
    let browserName = '';

    if (/chrome|chromium|crios/i.test(userAgent) && !/edg/i.test(userAgent)) {
        browserName = 'Chrome';
    } else if (/firefox|fxios/i.test(userAgent)) {
        browserName = 'Firefox';
    } else if (/safari/i.test(userAgent) && !/chrome|chromium|crios/i.test(userAgent)) {
        browserName = 'Safari';
    } else if (/edg/i.test(userAgent)) {
        browserName = 'Edge';
    } else if (/opr\//i.test(userAgent)) {
        browserName = 'Opera';
    } else if (/msie|trident/i.test(userAgent)) {
        browserName = 'Internet Explorer';
    } else {
        browserName = 'Unknown Browser';
    }
    return browserName;
};
