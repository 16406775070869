import React from 'react';
import PropTypes from 'prop-types';
import { Link as MuiLink, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import classNames from 'classnames';

export const Link = ({
    text,
    href,
    style,
    className,
    typographyClassName,
    showExternalLinkIcon,
    ...aditionalProps
}) => (
    <Typography className={classNames('external-link-typography', typographyClassName)}>
        <MuiLink
            href={href}
            className={classNames('external-link', className)}
            component='a'
            underline='always'
            rel='noreferrer noopener'
            target='_blank'
            sx={{ ...style }}
            {...aditionalProps}
        >
            { text }
            { showExternalLinkIcon && <OpenInNewIcon className='external-link-icon' /> }
        </MuiLink>
    </Typography>
);

Link.defaultProps = {
    text: '',
    href: '#',
    style: {},
    className: '',
    typographyClassName: '',
    showExternalLinkIcon: true
};

Link.propTypes = {
    
    text: PropTypes.string,
    href: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    typographyClassName: PropTypes.string,
    showExternalLinkIcon: PropTypes.bool
};

export default Link;
