import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import persistState from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import filter from 'redux-localstorage-filter';
import { serialize } from 'redux-localstorage-immutable';
import { getCapiSessionID } from 'capi/redux/immutable';


import { createCapiSaga } from 'capi/redux';

import { intlSagasWatcher } from '../intl/intlSagas';
import {
    createClearSessionSaga,
    checkSessionSaga,
    getOrCreateSessionSaga
} from '../session/sessionSagas';
import { blackboxResetWatcher } from '../iovation/iovationModule';
import { initiateConfigWatcherSaga, placeOrderSaga } from '../payment/paymentSagas';
import { flowSagasWatcher } from '../routing/flowSagas';
import {
    requestFaceplatesWatcher,
    faceplatesReceivedWatcher,
    faceplatePrefetchImagesWatcher
} from '../landingPage/landingPageSagas';
import { segmentWatcher } from '../segment/segmentSagas';
import { promoSagasWatcher } from '../promo/promoSagas';

import { getCapiClient } from '../session/sessionSelectors';
import { PAYMENT_COMPLETE } from '../payment/paymentModule';


import appReducer from './appReducer';


import { segmentInit } from '../segment/segment';
import { getEnv, getSegmentKey, getCookieConsentEnabled } from './bootstrap';
import { cookiesAllowed } from '../utils/cookieUtils';

const sagaMiddleware = createSagaMiddleware({
    
    
    onError: window.Raven ? window.Raven.captureException : undefined
});

const sagas = [
    intlSagasWatcher,
    createCapiSaga(getCapiClient, getCapiSessionID),
    checkSessionSaga,
    getOrCreateSessionSaga,
    createClearSessionSaga(PAYMENT_COMPLETE),
    blackboxResetWatcher,
    initiateConfigWatcherSaga,
    flowSagasWatcher,
    placeOrderSaga,
    requestFaceplatesWatcher,
    faceplatesReceivedWatcher,
    faceplatePrefetchImagesWatcher,
    promoSagasWatcher
];







const middleware = [sagaMiddleware];

const persistedPaths = [
    'brand',
    'capi',
    'intl.locale',
    'intl.formats',
    'intl.defaultLocale',
    'session',
    'lastAction',
    'iovation',
    'faceplateInfo',
    'completedSteps',
    'addedProducts',
    'webView'
];

const storage = compose(
    serialize,
    filter(persistedPaths)
)(adapter(window.localStorage));



const enhancers = compose(
    
    
    applyMiddleware(...middleware),
    
    
    persistState(storage, 'cashstar-storefront'),
    
    getEnv() !== 'PROD'
    && typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
        
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f  
);


const appStore = createStore(appReducer, undefined, enhancers);

function reloadOnUserPreferenceChange(data) {
    if (data
        && data.message && data.message === 'submit_preferences'
        && data.source && data.source === 'preference_manager') {
        
        

        window.location.reload();
    }
}

const cookieConsentEnabled = getCookieConsentEnabled();

if (cookieConsentEnabled) {
    
    window.addEventListener('message', (event) => {
        

        let { data } = event;
        if (typeof data === 'string') {
            try {
                data = JSON.parse(data);
            } catch (ex) { return; }
        }

        reloadOnUserPreferenceChange(data);
    }, false);
}

const allowAllCookies = cookiesAllowed(cookieConsentEnabled);

function doLoadSegment() {
    const segmentKey = getSegmentKey();
    if (segmentKey) {
        segmentInit(segmentKey);
        sagas.push(segmentWatcher);
    }
}

if (allowAllCookies) {
    
    
    
    
    
    doLoadSegment();
}



if (typeof window._getSanitizedState !== 'undefined') {
    window._getSanitizedState = () => {
        let state = appStore.getState();
        
        const { ...intl } = state.get('intl');
        delete intl.messages;
        state = state.set('intl', intl);
        return state.toJS();
    };
}


sagas.map(sagaMiddleware.run);

export default appStore;
