import * as React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import '../../../node_modules/react-multi-carousel/lib/styles.css';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

import FaceplateCarousel from './FaceplateCarousel';

import { useFlow } from '../../routing/FlowProvider';
import homePageMessages from '../assets/messages/homePageMessages';
import windowSize from '../../utils/windowSize';
import { isSeeAllToBeShown, textToCapitalize as captialize } from '../../utils/landingPageUtils';
import { getLoaderStatus } from '../../loader/loaderSelector';

const CarouselContainer = ({
    category,
    categoryCount,
    noOfFaceplates,
    intl,
    stepName,
    windowWidth,
    handleSubmit,
    isLoading
}) => {
    const { goToNextStep } = useFlow();
    const imagesLength = noOfFaceplates;
    const catergoryName = homePageMessages[category]
        ? captialize(intl.formatMessage(homePageMessages[category]))
        : captialize(category);
    const categoryText = categoryCount === 1
        ? intl.formatMessage(homePageMessages.electronicDesignHeader)
        : catergoryName;

    const seeAllClick = (text) => {
        goToNextStep(stepName, {
            seeAll: true,
            overwritingHistoryParams: { params: { category: text } }
        });
    };

    const DisplaySeeAll = () => (
        <Button className='carousalSeeall' onClick={() => seeAllClick(category)} disableFocusRipple>
            <Typography className='carousalSeeAllText'>
                {intl.formatMessage(homePageMessages.seeAll)}
            </Typography>
        </Button>
    );

    return (
        <div className='CarouselContainer'>
            <div className='carousalHead'>
                {isLoading ? (
                    <Skeleton variant="text" className='skeletonHeading' />
                ) : (
                    <>
                        <Typography className='carousalHeading'>
                            {categoryText}
                        </Typography>
                        {isSeeAllToBeShown(windowWidth, imagesLength) && categoryCount > 1 && <DisplaySeeAll />}
                    </>
                )}
            </div>
            <div className='carousalBody'>
                <FaceplateCarousel category={category} handleSubmit={handleSubmit} categoryCount={categoryCount} />
            </div>
        </div>
    );
};

CarouselContainer.defaultProps = {
    category: '',
    noOfFaceplates: ''
};

CarouselContainer.propTypes = {
    category: PropTypes.string,
    categoryCount: PropTypes.number.isRequired,
    noOfFaceplates: PropTypes.number,
    intl: intlShape.isRequired,
    stepName: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    windowWidth: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    isLoading: getLoaderStatus(state)
});
export default connect(mapStateToProps)(injectIntl(windowSize(CarouselContainer)));
