import swearjar from 'swearjar-extended2';
import profaneWords from './profaneWordsList';


swearjar._badWords = profaneWords;

const isProfaneRule = (errorMessage, isProfanityDetectionEnabled) => (value) => {
    if (!isProfanityDetectionEnabled) return null;
    if (!value) return null;

    
    const profanityDetails = swearjar.detailedProfane(value);
    if (profanityDetails.profane) {
        const words = Object.keys(profanityDetails.wordCount);
        let curseWords = '';
        words.map((val, index) => {
            curseWords = index < (words.length - 1) ? `${curseWords + val}, ` : `${curseWords + val}`;
            return curseWords;
        });
        return {
            ...errorMessage,
            values: {
                words: curseWords
            }
        };
    }
    return null;
};

export default isProfaneRule;
