import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import {
    Box,
    Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import primitiveMessages from '../messages/primitiveMessages';
import { useFlow } from '../../routing/FlowProvider';

const BackButton = ({ intl, stepName, className }) => {
    const { goToPreviousStep } = useFlow();

    return (
        <Box className={classNames('back-button-container', className)}>
            <Button
                className='back-button'
                onClick={() => {
                    goToPreviousStep(stepName, { shouldPushHistory: false });
                }}
            >
                <ArrowBackIcon className='back-button-arrow-icon' />
                { intl.formatMessage(primitiveMessages.backButtonText) }
            </Button>
        </Box>
    );
};

BackButton.defaultProps = {
    className: ''
};

BackButton.propTypes = {
    
    className: PropTypes.string,
    stepName: PropTypes.string.isRequired,
    
    intl: intlShape.isRequired
};

export default injectIntl(BackButton);
