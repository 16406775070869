import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { List as IList } from 'immutable';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import { Modal, Box, Typography } from '@mui/material';
import { deviceType } from '../utils/deviceTypeUtils';
import { getLoaderStatus } from '../loader/loaderSelector';
import windowSize from '../utils/windowSize';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    
    p: 4
};

const TermsAndConditionPopUp = ({
    device,
    itemClass,
    item,
    open,
    handleOpen,
    handleClose
}) => {
    const url = item.get('tnc_url');
    const content = item.get('tnc_content');
    const openNewTab = () => {
        if (url != null && url.trim().length !== 0) {
            window.open(url, '_blank', 'noopener,noreferrer');
        } else if (content != null && content.trim().length !== 0) {
            handleOpen();
        }
    };
    return (
        <div>
            <img
                className={itemClass}
                src={
                    device === 'mobile'
                        ? item.get('mobile_master_image_url')
                        : item.get('master_image_url')
                }
                alt={
                    device === 'mobile'
                        ? item.get('mobile_alt_text')
                        : item.get('alt_text')
                }
                onClick={openNewTab}
            />
            <Modal
                open={open}
                BackdropProps={{
                    style: { backgroundColor: 'transparent', opacity: 10 }
                }}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <Typography
                        id='modal-modal-title'
                        variant='h6'
                        component='h2'
                    >
                        Terms And Conditions
                    </Typography>
                    <Typography id='modal-modal-description'>
                        {content}
                    </Typography>
                    <button
                        type='button'
                        className='close-button-popup-tnc'
                        style={{
                            bottom: 0,
                            right: 0,
                            margin: '20px',
                            color: 'green',
                            borderRadius: '50px 50px 50px 50px',
                            border: '1px solid black',
                            position: 'absolute',
                            paddingTop: '7px',
                            paddingBottom: '7px',
                            paddingLeft: '10px',
                            paddingRight: '10px'
                        }}
                        onClick={handleClose}
                    >
                        Close
                    </button>
                </Box>
            </Modal>
        </div>
    );
};

const Banner = ({
    windowWidth = null,
    transDuration = 500,
    bannerContent = IList(),
    containerClass = '',
    itemClass = ''
}) => {
    const device = deviceType(windowWidth);
    const sortedBannerContent = bannerContent.sort(
        (bannerContentOne, bannerContentTwo) => bannerContentOne.get('order_sequence')
            - bannerContentTwo.get('order_sequence')
    );
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    return (
        <div className={`banner ${containerClass}`}>
            <Carousel
                arrows={bannerContent.size > 1}
                infinite={bannerContent.size > 1}
                transitionDuration={transDuration}
                keyBoardControl
                showDots={bannerContent.size > 1}
                renderDotsOutside
                sliderClass={bannerContent.size === 1 ? 'single-banner' : ''}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 1
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 1
                    }
                }}
            >
                {sortedBannerContent.map(item => (
                    <TermsAndConditionPopUp
                        device={device}
                        itemClass={itemClass}
                        item={item}
                        open={open}
                        handleOpen={handleOpen}
                        handleClose={handleClose}
                    />
                ))}
            </Carousel>
        </div>
    );
};

Banner.defaultProps = {
    transDuration: 500,
    containerClass: '',
    itemClass: ''
};

Banner.propTypes = {
    windowWidth: PropTypes.number.isRequired,
    bannerContent: PropTypes.array.isRequired,
    transDuration: PropTypes.number,
    containerClass: PropTypes.string,
    itemClass: PropTypes.string
};

TermsAndConditionPopUp.propTypes = {
    device: PropTypes.string.isRequired,
    itemClass: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleOpen: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isLoading: getLoaderStatus(state)
});

export default connect(mapStateToProps)(injectIntl(windowSize(Banner)));
