import React from 'react';


const ErrorIcon = () => (
    <svg id="ERROR" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path
            id="Polygon_1"
            data-name="Polygon 1"
            d="M12.728,0,18,5.272v7.456L12.728,18H5.272L0,12.728V5.272L5.272,0Z"
            fill="#c63b2c"
        />
        <rect
            id="Rectangle_30376"
            data-name="Rectangle 30376"
            width="2"
            height="14"
            rx="1"
            transform="translate(13.399 3.5) rotate(45)"
            fill="#fff"
        />
        <rect
            id="Rectangle_30377"
            data-name="Rectangle 30377"
            width="2"
            height="14"
            rx="1"
            transform="translate(14.813 13.4) rotate(135)"
            fill="#fff"
        />
    </svg>
);

export default ErrorIcon;
