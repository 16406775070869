import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import Chip from '@mui/material/Chip';
import { Box, InputAdornment, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { Check as CheckIcon } from '@mui/icons-material';

import { customAmountNumberFormatter, customAmountUnformatter, stripCurrencySymbol } from '../utils/numberUtils';
import giftCardDetailsPageMessages from './messages/giftCardDetailsPageMessages';
import ErrorSymbol from '../primitive/icons/errorIcon';
import TextInput from '../primitive/input/TextInput';
import { getIntlLocale, getIntlCurrencyCode } from '../intl/intlSelectors';
import {
    getEgcDefaultAmount,
    getEgcRangedCatalog,
    getEgcRecommendedDenoms
} from '../brand/brandSelectors';
import { CUSTOM_AMOUNT, CUSTOM_AMOUNT_MAXLENGTH } from '../constants/constants';
import { getCustomAmountSchema } from './validation/productDetailsSchema';
import { getCurrencySymbol } from '../intl/getCurrencySymbol';

const DenominationsContainer = (props) => {
    const {
        intl, handleDenominationClick, formattedDenominations, selectedDenomination
    } = props;
    return (
        <>
            <Typography className='denomTitle'>
                {intl.formatMessage(giftCardDetailsPageMessages.chooseAnAmountTitle)}
            </Typography>
            <Stack className='denomSuggestionContainer'>

                {formattedDenominations.map(denomination => (
                    <Chip
                        className={selectedDenomination === denomination ? 'selecteddenomChip' : 'denomChip'}
                        classes={{
                            label: 'MuiChip-label_text',
                            root: 'MuiChip-root_transition'
                        }}
                        key={denomination}
                        value={denomination}
                        id={`denom-id-${denomination}`}
                        label={(
                            <React.Fragment>
                                <p className={classnames('denomSuggestionAmountText', 'MuiChip-label',
                                    { selectedDenomSuggestionAmountText: selectedDenomination === denomination })}
                                >
                                    {
                                        selectedDenomination === denomination
                                        && <CheckIcon className='check-icon-denom' />
                                    }
                                    {denomination}
                                </p>
                            </React.Fragment>
                        )}
                        onClick={() => handleDenominationClick(denomination)}
                    />
                ))}

            </Stack>
        </>
    );
};

DenominationsContainer.propTypes = {
    intl: intlShape.isRequired,
    handleDenominationClick: PropTypes.func.isRequired,
    formattedDenominations: PropTypes.array.isRequired,
    selectedDenomination: PropTypes.string.isRequired
};

const CustomAmountContainer = (props) => {
    const {
        intl, formatCurrency, unformatCurrency, handleCustomAmountChange, handleCustomAmountBlur,
        customFormattedAmount, errors, intlCurrencyCode, catalogRange
    } = props;

    const minDenomination = catalogRange.get('start');
    const maxDenomination = catalogRange.get('end');
    const customAmountWidth = customFormattedAmount ? `${customFormattedAmount.length * 1.5}rem` : '6rem';
    const customAmountColor = errors.customAmount ? '#d32f2f !important' : 'rgba(0, 0, 0, 0.58)';
    const customAmountCurrencySymbol = getCurrencySymbol(intl, intlCurrencyCode);
    const content = `${intl.formatMessage(giftCardDetailsPageMessages.pageRefreshError)}`;
    return (
        <>
            <Typography className='customAmountTitle' sx={{ color: customAmountColor }}>
                <span className='requiredAsterisk'>*</span>
                {intl.formatMessage(giftCardDetailsPageMessages.enterAmount)}
            </Typography>

            <Box className='customAmountBox'>
                <TextInput
                    className={CUSTOM_AMOUNT}
                    id={CUSTOM_AMOUNT}
                    name={CUSTOM_AMOUNT}
                    setValueAs={value => unformatCurrency(value)}
                    validationSchema={getCustomAmountSchema(intl, minDenomination, maxDenomination, formatCurrency)}
                    onChange={handleCustomAmountChange}
                    onBlur={handleCustomAmountBlur}
                    variant='standard'
                    placeholder='0'
                    required
                    sx={{ width: customAmountWidth }}
                    FormHelperTextProps={{
                        classes: { error: 'Custom_Amount_MuiFormHelperText-root' }
                    }}
                    InputProps={{
                        classes: { root: 'CustomAmountMuiInputBase' },
                        startAdornment: (
                            <InputAdornment position="start">
                                <p className="CustomAmountSymbol">{customAmountCurrencySymbol.prefix}</p>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <p className="CustomAmountSymbol">{customAmountCurrencySymbol.suffix}</p>
                            </InputAdornment>
                        ),
                        inputProps: { maxLength: CUSTOM_AMOUNT_MAXLENGTH }
                    }}
                    classes={{ input: 'CustomAmountMuiInputBase' }}
                    type='text'
                    value={customFormattedAmount}
                    helperText={
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    }
                />
                {errors.customAmount ? <ErrorSymbol /> : ''}
            </Box>
        </>
    );
};

CustomAmountContainer.propTypes = {
    catalogRange: PropTypes.object.isRequired,
    intlCurrencyCode: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    formatCurrency: PropTypes.func.isRequired,
    unformatCurrency: PropTypes.func.isRequired,
    handleCustomAmountBlur: PropTypes.func.isRequired,
    handleCustomAmountChange: PropTypes.func.isRequired,
    customFormattedAmount: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired
};

const DenomSuggestions = (props) => {
    const {
        intl, productDetails: { cardValue } = {}, locale, recommendedDenoms,
        catalogRange, defaultAmount, intlCurrencyCode
    } = props;


    const formatCurrency = value => customAmountNumberFormatter({ locale }, value, intlCurrencyCode, true);
    const unformatCurrency = value => customAmountUnformatter({ locale }, intlCurrencyCode, value);
    const _stripCurrencySymbol = value => stripCurrencySymbol(intl, value, intlCurrencyCode);


    const formattedDenominations = recommendedDenoms.map(denomination => formatCurrency(denomination));

    const formattedRecommendedValue = formatCurrency(defaultAmount);

    const formValidation = useFormContext();
    const {
        formState: { errors }, clearErrors, getValues, setValue
    } = formValidation;

    const getCustomFormattedAmount = () => {
        const customAmount = getValues(CUSTOM_AMOUNT);

        // return the value updated in the component state if present
        if (customAmount !== undefined) {
            const customUnformattedAmount = unformatCurrency(customAmount);
            return _stripCurrencySymbol(formatCurrency(customUnformattedAmount));
        }

        // return formatted default amount value if present
        if (formattedRecommendedValue !== undefined) {
            return _stripCurrencySymbol(formattedRecommendedValue);
        }

        // By default return the first recommended denomination
        return formattedDenominations.size > 0 && _stripCurrencySymbol(formattedDenominations.get(0));
    };

    const customFormattedAmount = getCustomFormattedAmount();

    const [selectedDenomination, setSelectedDenomination] = React.useState(formattedRecommendedValue);

    const handleDenominationClick = (denomination) => {
        if (denomination) {
            setValue(CUSTOM_AMOUNT, denomination);
            setSelectedDenomination(denomination);
            clearErrors(CUSTOM_AMOUNT);
        }
    };

    const handleCustomAmountChange = (customAmountChange) => {
        const customAmountValue = customAmountChange.target.value;
        const rawDenomination = unformatCurrency(customAmountValue);
        const formattedDenomination = formatCurrency(rawDenomination);
        setSelectedDenomination(formattedDenomination);
        setValue(CUSTOM_AMOUNT, formattedDenomination);
    };

    const handleCustomAmountBlur = (customAmountBlur) => {
        const customAmountValue = customAmountBlur.target.value;
        const rawValue = customAmountValue && unformatCurrency(customAmountValue);
        const parsedCustomAmount = rawValue && parseFloat(rawValue).toString();
        const formattedCustomAmount = formatCurrency(parsedCustomAmount);
        setSelectedDenomination(formattedCustomAmount);
        setValue(CUSTOM_AMOUNT, parsedCustomAmount);
    };

    const setCardAndCustomValue = () => {
        if (cardValue) {
            const formattedCardValue = formatCurrency(cardValue);
            const formattedCardValueWithoutCurrency = _stripCurrencySymbol(formattedCardValue);
            setValue(CUSTOM_AMOUNT, formattedCardValueWithoutCurrency);
            setSelectedDenomination(formattedCardValue);
        } else {
            const customRecommendedValue = _stripCurrencySymbol(formattedRecommendedValue);
            setValue(CUSTOM_AMOUNT, customRecommendedValue);
        }
    };

    React.useEffect(() => {
        setCardAndCustomValue();
        if (!cardValue) {
            setSelectedDenomination(formattedRecommendedValue);
        }
    }, [locale]);

    return (
        <Card className='amountContainer'>
            <DenominationsContainer
                intl={intl}
                handleDenominationClick={handleDenominationClick}
                formattedDenominations={formattedDenominations}
                selectedDenomination={selectedDenomination}
            />

            <CustomAmountContainer
                intl={intl}
                catalogRange={catalogRange}
                intlCurrencyCode={intlCurrencyCode}
                handleCustomAmountChange={handleCustomAmountChange}
                handleCustomAmountBlur={handleCustomAmountBlur}
                formatCurrency={formatCurrency}
                unformatCurrency={unformatCurrency}
                customFormattedAmount={customFormattedAmount}
                errors={errors}
            />
        </Card>
    );
};

DenomSuggestions.defaultProps = {
    productDetails: {}
};

DenomSuggestions.propTypes = {
    
    intl: intlShape.isRequired,
    productDetails: PropTypes.object,
    locale: PropTypes.string.isRequired,
    recommendedDenoms: PropTypes.object.isRequired,
    intlCurrencyCode: PropTypes.string.isRequired,
    catalogRange: PropTypes.object.isRequired,
    defaultAmount: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    locale: getIntlLocale(state),
    recommendedDenoms: getEgcRecommendedDenoms(state),
    intlCurrencyCode: getIntlCurrencyCode(state),
    catalogRange: getEgcRangedCatalog(state),
    defaultAmount: getEgcDefaultAmount(state)
});

export default connect(mapStateToProps)(injectIntl(DenomSuggestions));
