import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
    Box, Typography, Stack, Button
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import FormLayout from '../formLayout/FormLayout';
import AppliedCoupon from '../promotion/AppliedCoupon';
import { useFlow } from '../routing/FlowProvider';
import ExternalLink from '../primitive/Link';
import PaymentWidget from './PaymentWidget';
import { getIntlCurrencyCode } from '../intl/intlSelectors';
import { getFormattedAmountWithCurrency } from '../utils/numberUtils';
import Loader from '../loader/loader';
import { getPaymentStatus } from './paymentSelectors';
import { getSelectedItemAmount } from '../giftCardDetailsPage/productDetailsSelector';
import { PAYMENT_STATUS, PROMO_ERROR_RESPONSES } from './paymentModule';
import paymentMessages from './messages/paymentMessages';
import { trackExternalLinkClick } from '../segment/segmentModule';
import dynamicLinkMessages from '../brand/messages/DynamicLinkMessages';
import { getEnv } from '../app/bootstrap';
import { getPromoForCode } from '../promo/promoModule';
import {
    getActivePromoCode, getCartQualifiesForPromo,
    getPromoStatus, getRejectedPromo, getPromoLimitExceeded
} from '../promo/promoSelectors';
import MoreOffersCoupons from '../promotion/MoreOffersCoupons';
import PromoErrorModal from '../promotion/PromoErrorModal';
import promoMessages from '../promotion/messages/promoMessages';

export const PaymentForm = ({
    intl,
    stepName,
    totalAmount,
    trackExternalLinkClickAction,
    intlCurrencyCode,
    promoCode,
    promoSubmitHandler
}) => {
    const { goToPreviousStep } = useFlow();

    React.useEffect(() => {
        if (promoCode) {
            promoSubmitHandler(promoCode);
        }
    }, []);

    return (
        <Box className='payment-widget-container'>
            <Box className='required-title'>
                {intl.formatMessage(paymentMessages.requiredTitle)}
            </Box>
            <PaymentWidget stepName={stepName} />
            <Stack direction='row' spacing={1} className='payment-info-stack'>
                <Typography variant='body2' className='payment-info-text'>
                    { intl.formatMessage(paymentMessages.paymentInfoNonExchangeableEGifts) }
                    <div>
                        { intl.formatMessage(paymentMessages.CheckEmail) }
                    </div>
                    <div>
                        { intl.formatMessage(paymentMessages.MakeCorrection) }
                    </div>
                </Typography>
            </Stack>
            <ExternalLink
                text={intl.formatMessage(paymentMessages.privacyPolicyLink)}
                href={intl.formatMessage(dynamicLinkMessages.privacyPolicy)}
                className='privacy-policy-link'
                onClick={() => trackExternalLinkClickAction({
                    urlName: 'privacy policy',
                    type: 'Payment form',
                    url: intl.formatMessage(dynamicLinkMessages.privacyPolicy)
                })}
                alignItems='center'
            />
            <Stack spacing={4} direction='row' alignItems='center' justifyContent='right' className='form-button-group'>
                <Button
                    className='negative-button'
                    variant='outlined'
                    onClick={() => goToPreviousStep(stepName, { shouldPushHistory: false })}
                >
                    <p className='negative-text'>
                        { intl.formatMessage(paymentMessages.cancelButton) }
                    </p>
                </Button>
                <Button
                    className='positive-button'
                    variant='contained'
                    id='pay-button'
                >
                    <LockOutlinedIcon className='lock_icon' />
                    <p className='positive-text'>
                        {`${intl.formatMessage(paymentMessages.payButton,
                            { number: getFormattedAmountWithCurrency(intl, totalAmount, intlCurrencyCode) })}
                        `}
                    </p>
                </Button>
            </Stack>
        </Box>
    );
};


PaymentForm.propTypes = {
    
    intl: intlShape.isRequired,
    
    stepName: PropTypes.string.isRequired,
    
    totalAmount: PropTypes.number.isRequired,
    
    trackExternalLinkClickAction: PropTypes.func.isRequired,
    intlCurrencyCode: PropTypes.string.isRequired,
    promoCode: PropTypes.string.isRequired,
    promoSubmitHandler: PropTypes.func.isRequired
};

const formMapStateToProps = state => ({
    totalAmount: getSelectedItemAmount(state),
    intlCurrencyCode: getIntlCurrencyCode(state),
    promoCode: getActivePromoCode(state)
});

const PaymentReduxForm = connect(
    formMapStateToProps,
    { trackExternalLinkClickAction: trackExternalLinkClick }
)(PaymentForm);

const PaymentPage = ({
    intl, stepName, status, promoSubmitHandler, cartQualifiesForPromo, promoStatus, rejectedPromo, promoLimitExceeded
}) => (
    <FormLayout
        defaultCard
        enableBackBtn
        stepName={stepName}
        formLeftPanelTitle={intl.formatMessage(paymentMessages.orderReviewLabel)}
        formLeftHeading={intl.formatMessage(paymentMessages.checkoutLabel)}
    >
        {promoStatus && (promoStatus.get('statusType') in PROMO_ERROR_RESPONSES)
        && !rejectedPromo
        && <PromoErrorModal stepName={stepName} promoStatus={promoStatus} />
        }
        {cartQualifiesForPromo && !rejectedPromo
            ? <AppliedCoupon intl={intl} />
            : (
                <MoreOffersCoupons
                    intl={intl}
                    cssClassName='apply-coupon-more-offers'
                    variant='apply'
                    heading={intl.formatMessage(promoMessages.moreOffersHeading)}
                    offersTextCssClassName={promoLimitExceeded ? 'promo-limit-exceeded-text' : 'no-offers-available'}
                    offersText={promoLimitExceeded ? intl.formatMessage(promoMessages.promoLimitExceededInlineText) : intl.formatMessage(promoMessages.noOffersMessage)}
                />
            )
        }
        <Typography className='payment-title'>
            { intl.formatMessage(paymentMessages.paymentTitle) }
        </Typography>
        <PaymentReduxForm stepName={stepName} promoSubmitHandler={promoSubmitHandler} intl={intl} />
        {status === PAYMENT_STATUS.get('ORDERING') && <Loader showTestEnv={getEnv() !== 'PROD'} />}
        {status === PAYMENT_STATUS.get('CONFIG_PROCESSING') && <Loader showTestEnv={getEnv() !== 'PROD'} />}
    </FormLayout>
);

PaymentPage.propTypes = {
    
    intl: intlShape.isRequired,
    
    stepName: PropTypes.string.isRequired,
    
    status: PropTypes.string.isRequired,
    promoSubmitHandler: PropTypes.func.isRequired,
    cartQualifiesForPromo: PropTypes.bool.isRequired,
    promoStatus: PropTypes.object.isRequired,
    rejectedPromo: PropTypes.string.isRequired,
    promoLimitExceeded: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    status: getPaymentStatus(state),
    cartQualifiesForPromo: getCartQualifiesForPromo(state),
    promoStatus: getPromoStatus(state),
    rejectedPromo: getRejectedPromo(state),
    promoLimitExceeded: getPromoLimitExceeded(state)
});

const mapDispatchToProps = {
    promoSubmitHandler: getPromoForCode
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentPage));
