import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { List as IList, Map as IMap } from 'immutable';

import Box from '@mui/material/Box';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Stack } from '@mui/material';

import { customAmountNumberFormatter } from '../utils/numberUtils';
import orderStatusMessages from './messages/orderStatusMessages';
import FormLayout from '../formLayout/FormLayout';
import { useFlow } from '../routing/FlowProvider';
import { getGrandTotal } from '../payment/paymentSelectors';
import { getIntlLocale, getIntlCurrencyCode } from '../intl/intlSelectors';
import { trackExternalLinkClick } from '../segment/segmentModule';
import dynamicLinkMessages from '../brand/messages/DynamicLinkMessages';
import Link from '../primitive/Link';
import { getBonusCards } from '../promo/promoSelectors';

let OrderSummary = ({
    intl,
    amount,
    locale,
    currencyCode,
    trackExternalLinkClickAction
}) => {
    const linkOnClick = (urlName, url) => trackExternalLinkClickAction({ urlName, url, type: 'Order Failure Page' });
    return (
        <React.Fragment>
            <CardContent>
                <Box className='orderDetails'>
                    <Typography className='orderAmountLabel'>
                        { intl.formatMessage(orderStatusMessages.amountToPayLabel) }
                    </Typography>
                    <Typography className='orderAmount'>
                        {customAmountNumberFormatter({ locale }, amount, currencyCode, true)}
                    </Typography>
                </Box>

            </CardContent>

            <Typography className='orderActions'>
                <Link
                    className='orderAction'
                    href={intl.formatMessage(dynamicLinkMessages.faq)}
                    text={intl.formatMessage(orderStatusMessages.faqsLabel)}
                    onClick={() => linkOnClick('FAQs', intl.formatMessage(dynamicLinkMessages.faq))}
                />

                <Link
                    className='orderAction orderStatusCustomerSupport'
                    href={intl.formatMessage(dynamicLinkMessages.inquiryList)}
                    text={intl.formatMessage(orderStatusMessages.customerSupportLabel)}
                    onClick={() => linkOnClick('Customer Support', intl.formatMessage(dynamicLinkMessages.inquiryList))}
                />
            </Typography>
        </React.Fragment>
    );
};

OrderSummary.propTypes = {
    intl: intlShape.isRequired,
    amount: PropTypes.number.isRequired,
    locale: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
    trackExternalLinkClickAction: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    amount: getGrandTotal(state),
    locale: getIntlLocale(state),
    currencyCode: getIntlCurrencyCode(state)
});

OrderSummary = connect(
    mapStateToProps,
    { trackExternalLinkClickAction: trackExternalLinkClick }
)(OrderSummary);

const getFormTitle = (intl, stepName, goToPreviousStep) => (
    <React.Fragment>
        <Alert
            icon={<ErrorOutline fontSize='inherit' className='errorIcon' />}
            severity='error'
            className='failureMsg'
        >
            {intl.formatMessage(orderStatusMessages.orderFailureTitle)}
        </Alert>

        <Paper className='failureInfo'>
            {intl.formatMessage(orderStatusMessages.orderFailureMessage)}
            {}
            <Link
                text={intl.formatMessage(orderStatusMessages.tryAgainButtonLabel)}
                className='tryAgainAction'
                target='_self'
                onClick={() => goToPreviousStep(stepName, { shouldPushHistory: false })}
                showExternalLinkIcon={false}
            />
        </Paper>
    </React.Fragment>
);

const OrderFailure = ({ intl, stepName, bonusCards }) => {
    const { goToNextStep, goToPreviousStep } = useFlow();

    return (
        <FormLayout
            formTitle={getFormTitle(intl, stepName, goToPreviousStep)}
            failureCard
            formLeftPanelTitle={intl.formatMessage(orderStatusMessages.orderSummaryLabel)}
            cartQualifiesForPromo={bonusCards.size > 0}
            bonusCard={bonusCards ? bonusCards.get(0, IMap()) : IMap()}
        >
            <Stack container display='flex' alignItems='center' flexDirection='column'>

                <Card className='orderSummaryContainer failureSummary'><OrderSummary intl={intl} /></Card>

                {}
                <Stack
                    spacing={4}
                    direction='row'
                    alignItems='center'
                    justifyContent='right'
                    className='form-button-group'
                >
                    <Button
                        className='cancelButton'
                        variant='outlined'
                        onClick={() => goToNextStep(stepName, { shouldPushHistory: false })}
                    >
                        <p className='cancelText'>{ intl.formatMessage(orderStatusMessages.cancelButtonLabel) }</p>
                    </Button>

                    <Button
                        className='tryAgainButton'
                        variant='contained'
                        onClick={() => goToPreviousStep(stepName, { shouldPushHistory: false })}
                    >
                        <p className='tryAgainText'>{ intl.formatMessage(orderStatusMessages.tryAgainButtonLabel) }</p>
                    </Button>
                </Stack>

            </Stack>
        </FormLayout>
    );
};

OrderFailure.defaultProps = {
    bonusCards: IList()
};

OrderFailure.propTypes = {
    intl: intlShape.isRequired,
    
    stepName: PropTypes.string.isRequired,
    
    bonusCards: PropTypes.instanceOf(IList)
};

const mapStateToPropsOrderFailure = state => ({
    bonusCards: getBonusCards(state)
});

export default connect(mapStateToPropsOrderFailure)(injectIntl(OrderFailure));
