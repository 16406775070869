import {
    put,
    select,
    takeEvery,
    takeLatest
} from 'redux-saga/effects';
import { createCapiSession, clearCapiSession } from 'capi/redux';
import { v4 as uuid } from 'uuid';

import {
    getHostname,
    getReferrer,
    getUrlParams
} from './utils';
import {
    clearStorefrontSession,
    setStorefrontSession,
    GET_OR_CREATE_STOREFRONT_SESSION
} from './sessionModule';
import { getStorefrontSessionKey } from './sessionSelectors';
import { LOCATION_CHANGE } from '../routing/routingModule';


const getCreateCapiSessionAction = sessionKey => createCapiSession({
    sessionKey,
    host: getHostname(),
    refUrl: getReferrer(),
    ...getUrlParams()
});

function* getOrCreateSession({ location = null }) {
    const sessionKey = yield select(getStorefrontSessionKey);
    if (!sessionKey) {
        const newSessionKey = yield uuid();
        yield put(setStorefrontSession(newSessionKey));
        yield put(getCreateCapiSessionAction(newSessionKey));
        if (location && window.RISKX) {
                        window.RISKX.setSid(newSessionKey);
            window.RISKX.go(location.location.pathname);
        }
    }
}

function* innerClearSessionSaga() {
    yield put(clearStorefrontSession());
    yield put(clearCapiSession());
}


export const createClearSessionSaga = (action) => {
    function* clearSessionSaga() {
        yield takeEvery(action, innerClearSessionSaga);
    }
    return clearSessionSaga;
};

export function* getOrCreateSessionSaga() {
    yield takeLatest(GET_OR_CREATE_STOREFRONT_SESSION, getOrCreateSession);
}

export function* checkSessionSaga() {
    yield takeEvery(LOCATION_CHANGE, getOrCreateSession);
}
