import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';

import ErrorIcon from '../icons/errorIcon';

import { getShrinkEnabledInputField } from '../../app/bootstrap';

const TextInput = (props) => {
    const formValidation = useFormContext();
    const { register, formState: { errors }, getValues } = formValidation;

    const {
        name, validationSchema, helperText, onChange, onBlur, setValueAs,
        customPlaceHolder, ...otherProps
    } = props;

    const value = getValues(name);

    const error = errors && errors[name];

    const shrinkEnabledInputField = getShrinkEnabledInputField();

    return (
        <TextField
            {...register(name, {
                ...validationSchema, onChange, onBlur, setValueAs
            })}
            error={!!error}
            placeholder={!shrinkEnabledInputField && customPlaceHolder}
            helperText={error ? error.message : helperText}
            InputProps={error ? { endAdornment: <ErrorIcon /> } : null}
            InputLabelProps={!shrinkEnabledInputField ? { shrink: true } : (value && { shrink: true })}
            {...otherProps}
        />
    );
};

TextInput.defaultProps = {
    helperText: '',
    onChange: undefined,
    onBlur: undefined,
    name: '',
    setValueAs: undefined,
    validationSchema: {},
    customPlaceHolder: ''
};

TextInput.propTypes = {
    helperText: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    setValueAs: PropTypes.func,
    validationSchema: PropTypes.object,
    customPlaceHolder: PropTypes.string
};

export default TextInput;
