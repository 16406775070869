import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { useFlow } from './FlowProvider';
import { replace } from './routing';
import { ROUTES } from './flowConstants';

export const Step = ({ component: Component, stepName }) => {
    const { findFirstIncompleteStep, stepHasChanged, showCurrencyPicker } = useFlow();
    const location = useLocation();

    useEffect(() => {
        stepHasChanged(stepName, location);
        showCurrencyPicker(stepName);
                if (window.RISKX) {
            window.RISKX.go(ROUTES[stepName]);
        }
        const firstIncompleteStep = findFirstIncompleteStep(stepName);
        if (firstIncompleteStep !== stepName) {
            replace({ pathname: ROUTES[firstIncompleteStep] });
        }
    }, [stepName]);

    return <Component stepName={stepName} />;
};

Step.displayName = 'Step';
Step.propTypes = {
    component: PropTypes.func.isRequired,
    stepName: PropTypes.string.isRequired
};

export default Step;
