import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import { getBrandName, getBrandCode } from '../brand/brandSelectors';
import { getBrandUrl, getStaticMediaURL } from '../app/bootstrap';
import headerMessages from './message/HeaderMessage';


const Logo = (props) => {
    const { intl, brandName, brandCode } = props;
    const linkCopy = intl.formatMessage(headerMessages.linkText, { brandName });
    const logoSrc = intl.formatMessage(headerMessages.logoSrc, { brandCode });
    const imgUrl = `${getStaticMediaURL()}/images/${brandCode}/${logoSrc}`;

    return (
        <div className='Logo'>
            <a href={getBrandUrl()} target="_blank" rel="noreferrer">
                <img
                    src={imgUrl}
                    alt={linkCopy}
                    height='100%'
                    width='100%'
                    title={linkCopy}
                />
            </a>
        </div>
    );
};

Logo.propTypes = {
    brandName: PropTypes.string.isRequired,
    brandCode: PropTypes.string.isRequired,
    intl: intlShape.isRequired
};

const mapStateToProps = state => ({
    brandName: getBrandName(state),
    brandCode: getBrandCode(state)
});


export default connect(mapStateToProps)(injectIntl(Logo));
