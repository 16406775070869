import React, { useEffect } from 'react';
import { List as IList } from 'immutable';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { intlShape, injectIntl } from 'react-intl';

import {
    getCookieConsentEnabled,
    getBrandUrl,
    getOrderingTokenServiceCodes
} from '../app/bootstrap';
import { getBrandName } from '../brand/brandSelectors';
import CashstarLink from './CashstarLink';
import { getCustomCashstarLinks } from './FooterSelector';
import footerMessages from './messages/FooterMessages';
import { initializeRiskified } from '../utils/riskifiedUtils';
import { getStorefrontSessionKey } from '../session/sessionSelectors';
import { PAYMENT_PLUS_RISK } from '../constants/constants';
import CashstarLinksDefault from './CashstarLinksDefault';

export const Footer = ({
    customLinks,
    sessionKey,
    brandName,
    intl
}) => {
    const linkList = customLinks.map((link) => {
        const { text, url } = link.toJS();
        return (
            <Grid
                item
                className='grid-item'
                xs={12}
                sm={12}
                md='auto'
                key={url}
            >
                <CashstarLink
                    linkText={text}
                    url={url}
                    key={url}
                />
            </Grid>
        );
    });

    useEffect(() => {
        const brandUrl = getBrandUrl();
        const ppRiskEnabled = getOrderingTokenServiceCodes()
            .split(',').includes(PAYMENT_PLUS_RISK);

        if (ppRiskEnabled && sessionKey && brandUrl) {
            
            
            initializeRiskified(sessionKey, brandUrl);
        }
    }, []);

    return (
        <footer className="footer">
            <div className="footer-container">
                <Grid
                    container
                    className='footer-grid-container'
                    spacing={{
                        xs: 2, sm: 2, md: 3, lg: 3
                    }}
                    columns={{
                        xs: 12, sm: 12, md: 9, lg: 9
                    }}
                >
                    { customLinks.size ? linkList : <CashstarLinksDefault /> }
                    { getCookieConsentEnabled() && (
                        <Grid
                            item
                            className='grid-item'
                            xs={12}
                            sm={12}
                            md='auto'
                            key='teconsent'
                        >
                            <Typography className='footer-grid-item'>
                                <div id="teconsent" />
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid
                    container
                    className='footer-right-grid-container'
                    spacing={{
                        xs: 1, sm: 1, md: 2, lg: 2
                    }}
                    columns={{
                        xs: 12, sm: 12, md: 3, lg: 3
                    }}
                >
                    <Grid
                        item
                        flex={1}
                        className='grid-item-7'
                        xs={12}
                        sm={12}
                        md='auto'
                    >
                        <Typography className='footer-grid-item'>
                            {
                                intl.formatMessage(
                                    footerMessages.headerCopyRightText,
                                    { currentYear: new Date().getFullYear(), brandName }
                                )
                            }
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        className='grid-item-8 poweredByCashstar'
                        xs={12}
                        sm={12}
                        md='auto'
                    >
                        <Typography className='footer-grid-item'>
                            {
                                intl.formatMessage(
                                    footerMessages.headerPoweredByCashstar, { brandName }
                                )
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </footer>
    );
};

Footer.defaultProps = {
    sessionKey: null,
    brandName: null
};

Footer.propTypes = {
    customLinks: PropTypes.instanceOf(IList).isRequired,
    intl: intlShape.isRequired,
    sessionKey: PropTypes.string,
    brandName: PropTypes.string
};

const mapStateToProps = state => ({
    customLinks: getCustomCashstarLinks(state),
    sessionKey: getStorefrontSessionKey(state),
    brandName: getBrandName(state)
});

export default connect(mapStateToProps)(injectIntl(Footer));
