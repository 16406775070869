import { Map as IMap } from 'immutable';
import { createSelector } from 'reselect';

export const getWebView = state => state.get('webView', IMap());


export const getIsWebView = createSelector(
    getWebView,
    webView => webView.get('isWebView', false)
);
