import { defineMessages } from 'react-intl';

const validationMessages = defineMessages({
    requiredFields: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.requiredFields',
        description: 'This field is required Error Message',
        defaultMessage: 'This field is required'
    },
    consentCheckboxError: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.consentCheckboxError',
        description: 'Please check this box if you want to proceed Error Message',
        defaultMessage: 'Please check this box if you want to proceed'
    },
    minLimit: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.minLimit',
        description: 'Amount can\'t be less than Error Message',
        defaultMessage: 'Amount can\'t be less than {number}'
    },
    maxLimit: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.maxLimit',
        description: 'Amount can\'t be more than Error Message',
        defaultMessage: 'Amount can\'t be more than {number}'
    },
    amountRequired: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.amountRequired',
        description: 'Amount is required Error Message',
        defaultMessage: 'Amount is required'
    },
    emailValidityError: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.emailValidityError',
        description: 'Enter a valid email id Error Message',
        defaultMessage: 'Enter a valid email id'
    },
    nameMaxLength: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.nameMaxLength',
        description: 'Max length error for Name fields',
        defaultMessage: 'Name cannot exceed {maxLength} characters.'
    },
    messageMaxLength: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.messageMaxLength',
        description: 'Max length error for Message field',
        defaultMessage: 'Message cannot exceed {maxLength} characters.'
    },
    specialCharactersError: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.specialCharactersError',
        description: 'Error message to convey special characters are not allowed',
        defaultMessage: 'Special characters are not allowed.'
    },
    errorsAlert: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.errorsAlert',
        description: 'Number of errors message',
        defaultMessage: 'You need to fix {numberOfErrors} errors to proceed.'
    },
    nameValidityError: {
        id: 'giftCardDetailsPage.giftCardDetailsPageMessages.nameValidityError',
        description: 'Enter a valid name Error Message',
        defaultMessage: 'Enter a valid name'
    }
});

export default validationMessages;
