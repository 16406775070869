import React from 'react';
import PropTypes from 'prop-types';
import {
    ButtonBase, Select, MenuItem, InputLabel
} from '@mui/material';
import { intlShape } from 'react-intl';
import { List as IList } from 'immutable';
import currencyLocaleMessages from './messages/LocaleCurrencyMessages';


const LanguageName = ({ languageCode }) => new Intl.DisplayNames(languageCode, { type: 'language' }).of(languageCode);

LanguageName.propTypes = {
    languageCode: PropTypes.string.isRequired
};


const LanguagePicker = ({
    intl, language, setLanguage, localesForSelectedCountry
}) => {
    const handleChange = event => setLanguage(event.target.value);

    return (
        <ButtonBase className='form-inputs-base'>
            <InputLabel className='form-inputs-label' id="label">
                {intl.formatMessage(currencyLocaleMessages.languageLabelText)}
            </InputLabel>
            <Select
                onChange={handleChange}
                displayEmpty
                className='language-dropdown'
                value={language}
            >
                {
                    localesForSelectedCountry.map((supportedLocale) => {
                        const [languageCode, countryCode] = supportedLocale.split('-');
                        return (
                            <MenuItem key={supportedLocale} value={supportedLocale}>
                                <LanguageName languageCode={languageCode} />
                                {' - '}
                                {languageCode.toUpperCase()}
                                {' ('}
                                {countryCode.toUpperCase()}
                                {') '}
                            </MenuItem>
                        );
                    })
                }
            </Select>
        </ButtonBase>
    );
};

LanguagePicker.propTypes = {
    intl: intlShape.isRequired,
    language: PropTypes.string.isRequired,
    setLanguage: PropTypes.string.isRequired,
    localesForSelectedCountry: PropTypes.instanceOf(IList).isRequired
};

export default LanguagePicker;
