export default {
    "simple": {
        "fuck": [
            "sexual"
        ],
        "shit": [
            "sexual",
            "inappropriate"
        ],
        "cunt": [
            "insult",
            "sexual"
        ],
        "twat": [
            "sexual",
        ],
        "shitty": [
            "inappropriate"
        ],
        "cock": [
            "sexual"
        ],
        "fag": [
            "discriminatory"
        ],
        "fck": [
            "sexual"
        ],
        "penis": [
            "sexual"
        ],
        "cocks": [
            "sexual",
            "category2"
        ],
        "cocksucker": [
            "discriminatory",
            "sexual"
        ],
        "cocksuckers": [
            "discriminatory",
            "sexual"
        ],
        "cunts": [
            "insult",
            "sexual"
        ],
        "faggot": [
            "discriminatory"
        ],
        "faggots": [
            "discriminatory"
        ],
        "fags": [
            "discriminatory"
        ],
        "fucks": [
            "sexual"
        ],
        "motherfucker": [
            "insult",
            "sexual"
        ],
        "motherfuckers": [
            "insult",
            "sexual"
        ],
        "nigger": [
            "discriminatory"
        ],
        "niggers": [
            "discriminatory"
        ],
        "shits": [
            "insult"
        ],
        "tits": [
            "sexual"
        ],
        "twats": [
            "sexual"
        ],
        "fucking": [
            "sexual"
        ],
        "motherfucking": [
            "insult"
        ],
        "fuckin": [
            "sexual"
        ],
        "ass": [
            "sexual",
            "insult"
        ],
        "bitch": [
            "discriminatory",
            "insult"
        ],

    },
    "emoji": {
        "1f4a9": [
            "category1",
            "category2"
        ],
        "word2": [
            "category1"
        ],
        "word3": [
            "category2"
        ]
  }
};
