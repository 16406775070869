import { defineMessages } from 'react-intl';

const languagePickerMessages = defineMessages({
    'en-us': {
        id: 'intl.languagePicker.en-us',
        description: 'Display name for US English language picker selection',
        defaultMessage: 'English - US'
    },
    'en-ca': {
        id: 'intl.languagePicker.en-ca',
        description: 'Display name for CA English language picker selection',
        defaultMessage: 'English - CA'
    },
    'en-au': {
        id: 'intl.languagePicker.en-au',
        description: 'Display name for AU English language picker selection',
        defaultMessage: 'English - AU'
    },
    'en-ch': {
        id: 'intl.languagePicker.en-ch',
        description: 'Display name for CH English language picker selection',
        defaultMessage: 'English - CH'
    },
    'en-hk': {
        id: 'intl.languagePicker.en-hk',
        description: 'Display name for HK English language picker selection',
        defaultMessage: 'English - HK'
    },
    'en-nz': {
        id: 'intl.languagePicker.en-nz',
        description: 'Display name for NZ English language picker selection',
        defaultMessage: 'English - NZ'
    },
    'en-se': {
        id: 'intl.languagePicker.en-se',
        description: 'Display name for SE English language picker selection',
        defaultMessage: 'English - SE'
    },
    'en-gb': {
        id: 'intl.languagePicker.en-gb',
        description: 'Display name for GB English language picker selection',
        defaultMessage: 'English - GB'
    },
    'fr-ca': {
        id: 'intl.languagePicker.fr-ca',
        description: 'Display name for CA French language picker selection',
        defaultMessage: 'Français - CA'
    },
    'es-us': {
        id: 'intl.languagePicker.es-us',
        description: 'Display name for US Spanish language picker selection',
        defaultMessage: 'Español - US'
    },
    'es-es': {
        id: 'intl.languagePicker.es-es',
        description: 'Display name for ES Spanish language picker selection',
        defaultMessage: 'Español - ES'
    },
    'es-mx': {
        id: 'intl.languagePicker.es-mx',
        description: 'Display name for MX Spanish language picker selection',
        defaultMessage: 'Español - MX'
    },
    'de-de': {
        id: 'intl.languagePicker.de-de',
        description: 'Display name for DE German language picker selection',
        defaultMessage: 'Deutsche - DE'
    },
    'sv-se': {
        id: 'intl.languagePicker.sv-se',
        description: 'Display name for SE Swedish language picker selection',
        defaultMessage: 'Svenska - SE'
    },
    'fr-fr': {
        id: 'intl.languagePicker.fr-fr',
        description: 'Display name for FR French language picker selection',
        defaultMessage: 'Français - FR'
    },
    'pt-br': {
        id: 'intl.languagePicker.pt-br',
        description: 'Display name for BR Brazilian Portuguese language picker selection',
        defaultMessage: 'Português - BR'
    },
    'nl-nl': {
        id: 'intl.languagePicker.nl-nl',
        description: 'Display name for NL Dutch-Netherlands language picker selection',
        defaultMessage: 'Nederlands - NL'
    },
    'ja-jp': {
        id: 'intl.languagePicker.ja-jp',
        description: 'Display name for JP Japanese language picker selection',
        defaultMessage: '日本語 - JP'
    },
    languagePickerLabel: {
        id: 'intl.languagePicker.languagePickerLabel',
        description: 'Label for language picker - this is hidden from display',
        defaultMessage: 'Select a Language'
    }
});

export default languagePickerMessages;
