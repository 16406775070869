import {
    takeLatest,
    call
} from 'redux-saga/effects';

import { APP_ERROR_TYPES, SHOW_APP_ERROR } from './routingModule';
import { PAYMENT_SUCCESS, PAYMENT_FAILED } from '../payment/paymentModule';
import { replace } from './routing';
import { getErrorNoRetryPath } from './paths';
import { PAYMENT } from './flowConstants';
import { getFlowContextMethodsForSaga } from './FlowProvider';

export function* showAppError({ errorType }) {
    if (errorType === APP_ERROR_TYPES.NO_RETRY) {
        yield call(replace, { pathname: getErrorNoRetryPath() });
    }
}

export function* completeBuyFlow({ orderDetails }) {
    const { goToNextStep } = yield call(getFlowContextMethodsForSaga);
        const location = {
        state: orderDetails
    };
    yield call(
        goToNextStep,
        PAYMENT,
        {
            shouldPushHistory: false,
            paymentSuccess: true,
            overwritingHistoryParams: { ...location }
        }
    );
}


export function* orderFailureHandler() {
    const { goToNextStep } = yield call(getFlowContextMethodsForSaga);
    yield call(goToNextStep, PAYMENT, { shouldPushHistory: false });
}

export function* flowSagasWatcher() {
    yield takeLatest(SHOW_APP_ERROR, showAppError);
    yield takeLatest(PAYMENT_SUCCESS, completeBuyFlow);
    yield takeLatest(PAYMENT_FAILED, orderFailureHandler);
}
