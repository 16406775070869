import React from 'react';
import PropTypes from 'prop-types';

import { logError } from '../utils/errorUtils';

import ErrorRefresh from './ErrorRefresh';


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) { 
        
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) { 
        logError(error, errorInfo);
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return <ErrorRefresh />;
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired
};

export default ErrorBoundary;
