import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import PopUpModal from '../primitive/PopUpModal';
import promoErrorMessage from './PromoErrorMessages';
import { PROMO_ERROR_RESPONSES } from '../payment/paymentModule';
import { useFlow } from '../routing/FlowProvider';
import { setRejectedPromo, removePromoRewards } from '../promo/promoModule';

const modalContent = (intl, statusType, handleClose) => {
    let buttonName;
    let content;
    let heading;
    if (statusType === PROMO_ERROR_RESPONSES.PROMO_LIMITED) {
        buttonName = intl.formatMessage(promoErrorMessage.limitExceedButtonText);
        content = intl.formatMessage(promoErrorMessage.limitExceedContent);
        heading = intl.formatMessage(promoErrorMessage.limitExceedHeading);
    } else {
        buttonName = intl.formatMessage(promoErrorMessage.soldOutButtonText);
        content = intl.formatMessage(promoErrorMessage.soldOutContent);
        heading = intl.formatMessage(promoErrorMessage.soldOutHeading);
    }
    return (
        <div className='promo-error-div'>
            <div className='heading'>
                {heading}
            </div>
            <div className='content' dangerouslySetInnerHTML={{ __html: content }} />
            <div className='button-div'>
                <Button className='button' variant="outlined" onClick={handleClose}>{buttonName}</Button>
            </div>
        </div>
    );
};

function PromoErrorModal({
    intl,
    promoStatus,
    stepName,
    addInvalidPromo,
    removeRewards
}) {
    const { goToPreviousStep } = useFlow();
    const [open, setOpen] = useState(false);

    const { statusType, promoCode } = promoStatus.toJS();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        goToPreviousStep(stepName, { shouldPushHistory: false });
        if (statusType === PROMO_ERROR_RESPONSES.PROMO_SOLD_OUT) {
            addInvalidPromo(promoCode);
            removeRewards();
        }
    };

    useEffect(() => {
        switch (statusType) {
            case PROMO_ERROR_RESPONSES.PROMO_LIMITED:
            case PROMO_ERROR_RESPONSES.PROMO_SOLD_OUT:
                handleOpen();
                break;
            default:
                break;
        }
    }, [promoStatus]);

    return (
        <div>
            <PopUpModal content={modalContent(intl, statusType, handleClose)} open={open} handleClose={handleClose} />
        </div>
    );
}

PromoErrorModal.propTypes = {
    intl: intlShape.isRequired,
    promoStatus: PropTypes.object.isRequired,
    stepName: PropTypes.string.isRequired,
    addInvalidPromo: PropTypes.func.isRequired,
    removeRewards: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    addInvalidPromo: setRejectedPromo,
    removeRewards: removePromoRewards
};

export default connect(null, mapDispatchToProps)(injectIntl(PromoErrorModal));
