import { defineMessages } from 'react-intl';

const promoMessages = defineMessages({
    moreOffersHeading: {
        id: 'promotion.promoMessages.moreOffersHeading',
        description: 'More Offers and Coupons Heading',
        defaultMessage: 'Offers and coupons'
    },
    freeGiftCardTitle: {
        id: 'promotion.promoMessages.freeGiftCardTitle',
        description: 'Free GiftCard Worth',
        defaultMessage: 'Free GiftCard Worth <b>{number}</b>'
    },
    freeTitle: {
        id: 'promotion.promoMessages.Free',
        description: 'Free Title',
        defaultMessage: 'Free'
    },
    noOffersMessage: {
        id: 'promotion.promoMessages.noOffersMessage',
        description: 'No Offers or Coupons Message',
        defaultMessage: 'No Offers or Coupons Available'
    },
    offerAndCouponTitle: {
        id: 'promotion.promoMessages.offerAndCouponTitle',
        description: 'Offers and Coupons Title',
        defaultMessage: 'Offers and Coupons'
    },
    freePromoCardTitle: {
        id: 'promotion.promoMessages.freePromoCardTitle',
        description: 'Free GiftCard Worth',
        defaultMessage: 'Free GiftCard Worth <b>{number}</b>'
    },
    promoLimitExceededInlineText: {
        id: 'promotion.promoMessages.promoLimitExceededInlineText',
        description: 'inline text for promo limit exceeding.',
        defaultMessage: 'Benefits will be given only once per email.'
    }

});

export default promoMessages;
