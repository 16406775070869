import Big from 'big.js';
import { Map as IMap, List as IList } from 'immutable';
import { createSelector } from 'reselect';
import countryToCurrency from 'country-to-currency';
import { getIntlCurrencyCode } from '../intl/intlSelectors';
import { getInCatalog } from './catalogUtils';
import { getEnableShareableLinkFlow } from '../app/bootstrap';

export const getBrand = state => state.get('brand', IMap());

export const getSupportedLocales = createSelector(
    getBrand,
    (brand) => {
        const supportedLocales = brand.get('supportedLocales', IList());
        const electronicCurrencyList = brand.get('electronicCurrencyList', IList());

        const currencySuppoetedLocales = supportedLocales.filter((supportedLocale) => {
            const countryCode = supportedLocale.split('-')[1];
            const currencyCode = countryToCurrency[countryCode.toUpperCase()];
            return electronicCurrencyList.includes(currencyCode);
        });
        return currencySuppoetedLocales;
    }
);

export const getBrandCode = createSelector(
    getBrand,
    brand => brand.get('brandCode')
);

export const getBrandElectronicCurrencyList = createSelector(
    getBrand,
    brand => brand.get('electronicCurrencyList', IList())
);

export const getBrandName = createSelector(
    getBrand,
    brand => brand.get('brandName')
);

export const getgdprValue = createSelector(
    getBrand,
    brand => brand.get('gdpr', IMap())
);

export const getgdprTermsAcknowledgementEnabled = createSelector(
    getgdprValue,
    gdpr => gdpr.get('gdprTermsAcknowledgementEnabled')
);

export const getBrandCatalogs = state => state.getIn(['brand', 'catalogs'], IMap());

export const getBannerData = state => state.getIn(['brand', 'bannerData'], IList());


export const getCatalogForCurrency = (catalogs, currency) => (
    catalogs.get(currency) || IMap()
);

export const getEgcDefaultCurrency = createSelector(
    getBrandElectronicCurrencyList,
    defaultCurrency => defaultCurrency.get(0)
);

export const getEgcRecommendedDenoms = createSelector(
    getBrandCatalogs,
    getIntlCurrencyCode,
    (catalogs, currency) => {
        const egcRecommendedDenoms = getInCatalog(
            getCatalogForCurrency(catalogs, currency),
            ['egcRecommendedDenoms'],
            IList()
        );
        return egcRecommendedDenoms.map(value => new Big(parseFloat(value).toFixed(2)));
    }
);

export const getEgcDefaultAmount = createSelector(
    getBrandCatalogs,
    getIntlCurrencyCode,
    (catalogs, currency) => {
        const egcDefaultAmount = getInCatalog(
            getCatalogForCurrency(catalogs, currency),
            ['egcDefaultAmount'],
            IList()
        );
        return egcDefaultAmount;
    }
);

export const getEgcRangedCatalog = createSelector(
    getBrandCatalogs,
    getIntlCurrencyCode,
    (catalogs, currency) => {
        const egcRangedCatalog = getInCatalog(
            getCatalogForCurrency(catalogs, currency),
            ['egcRangedCatalog'],
            IList()
        );
        return egcRangedCatalog;
    }
);

export const getBrandDefaultLocale = createSelector(
    getBrand,
    brand => brand.get('defaultLocaleCode')
);

export const getProfanityDetectionEnabled = createSelector(
    getBrand,
    brand => brand.get('isProfanityDetectionEnabled', true)
);

export const getfeatureValue = createSelector(
    getBrand,
    brand => brand.get('features', IMap())
);

export const getShareViaLinkEnabled = createSelector(
    getfeatureValue,
    getBrandCode,
    (features, brandCode) => {
        const enableShareableLinkFlowData = getEnableShareableLinkFlow();
        const isShareableLinkFlowEnabled = enableShareableLinkFlowData.includes(brandCode);
        return features.get('IsShareViaLinkDeliveryOptionEnabled') && isShareableLinkFlowEnabled;
    }
);

export const getShareViaLinkDefaultOptionEnabled = createSelector(
    getfeatureValue,
    features => features.get('isShareViaLinkDefaultDeliveryOption')
);
