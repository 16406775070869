import { Map as IMap } from 'immutable';

import { getNowTimestamp } from '../utils/timeUtils';


export const SESSION_LIFETIME = 1000 * 60 * 60 * 24; 
export const sessionLifetimeDeserializer = (state, payload) => {
    const lastActionTimestamp = payload.lastAction;
    if (lastActionTimestamp) {
        const now = getNowTimestamp();
        const sessionExpired = (now - lastActionTimestamp) > SESSION_LIFETIME;
        if (sessionExpired) {
            return state;
        }
    }

    
    const { intl } = payload;
    delete payload.intl;
    return state.merge(payload).set('intl', intl);
};



export const CLEAR_STOREFRONT_SESSION = '@@storefront/CLEAR_STOREFRONT_SESSION';
export const SET_STOREFRONT_SESSION = '@@storefront/SET_STOREFRONT_SESSION';
export const GET_OR_CREATE_STOREFRONT_SESSION = '@@storefront/GET_OR_CREATE_STOREFRONT_SESSION';



const sessionReducer = (state = IMap({}), action) => {
    switch (action.type) {
        case SET_STOREFRONT_SESSION: {
            const { sessionKey } = action;
            return state.set('sessionKey', sessionKey);
        }
        case CLEAR_STOREFRONT_SESSION:
            return state.delete('sessionKey');

        default:
    }
    return state;
};



export const clearStorefrontSession = () => ({
    type: CLEAR_STOREFRONT_SESSION
});

export const setStorefrontSession = sessionKey => ({
    type: SET_STOREFRONT_SESSION,
    sessionKey
});

export const getOrCreateStorefrontSession = () => ({
    type: GET_OR_CREATE_STOREFRONT_SESSION
});

export default sessionReducer;
