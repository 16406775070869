import { delay } from 'redux-saga';
import {
    takeLatest, put, take, call, race, select
} from 'redux-saga/effects';
import {
    RETRIEVED_INITIATE_CONFIG_DATA,
    REQUEST_INITIATE_CONFIG_ERROR,
    fetchInitialPaymentConfigData,
    placeOrder,
    ORDER_SUCCESS,
    ORDER_FAILED,
    PAYMENT_FAILED
} from 'capi/redux';

import { logError, logWarning } from '../utils/errorUtils';
import {
    paymentMethodDataHandlers,
    
    PROCESS_PAYMENT,
    PROCESS_INITIATE_CONFIG,
    
    initiateConfigRequestSuccess,
    initiateConfigRequestFailed,
    startSendOrder,
    paymentSuccess,
    paymentFailed,
    paymentComplete,
    paymentFailedPromo
} from './paymentModule';
import {
    showAppError,
    APP_ERROR_TYPES,
    clearCompletedSteps
} from '../routing/routingModule';
import {
    sanitizePaymentData,
    orderSerializer,
    orderResponseSerializer,
    initiateConfigSerializer
} from './capiSerializer';
import { getEGiftCards } from '../giftCardDetailsPage/productDetailsSelector';
import { getOrderMetaData, getGrandTotal } from './paymentSelectors';
import { resetCardSelection } from '../landingPage/landingPageModule';
import { flushProductDetails } from '../giftCardDetailsPage/productDetailsModule';
import { resetIovation } from '../iovation/iovationModule';
import { getIntlLocale, getIntlCurrencyCode } from '../intl/intlSelectors';
import { getStorefrontSessionKey } from '../session/sessionSelectors';
import {
    resetPromo, clearRejectedPromos, setPromoStatus
} from '../promo/promoModule';
import { getBonusCards } from '../promo/promoSelectors';
import { loadingAction } from '../loader/loaderModule';

export const defaultFailureResponse = {
    status: 402,
    statusText: 'Payment Required',
    data: {
        errorCode: 'PAYMENT_REQUIRED',
        fieldErrors: []
    }
};

export const parseErrorResponse = response => ({
    httpCode: response.status,
    httpMessage: response.statusText,
    capiCode: response.data.errorCode,
    fieldErrors: response.data.fieldErrors
});

export const parseErrorResponseForSegment = response => ({
    httpCode: response.status,
    httpMessage: response.statusText || JSON.stringify(response.data)
});

export function* initiateConfigWatcherSaga() {
    yield takeLatest(PROCESS_INITIATE_CONFIG, function* initiateConfigSaga(action) {
        
        const { data: { isEdited } } = action;
        const eGiftCards = yield select(getEGiftCards);
        const orderMetaData = yield select(getOrderMetaData);
        const brandDefaultLocale = yield select(getIntlLocale);
        const countryCode = (brandDefaultLocale).split('-')[1].toUpperCase();
        const riskifiedSessionId = window.RISKX && window.RISKX.getCartId();
        
        const data = yield call(
            initiateConfigSerializer,
            eGiftCards,
            orderMetaData,
            { countryCode, isEdited },
            riskifiedSessionId
        );
        
        yield put(fetchInitialPaymentConfigData(data.toJS()));
        
        const result = yield take([
            RETRIEVED_INITIATE_CONFIG_DATA, REQUEST_INITIATE_CONFIG_ERROR
        ]);
        switch (result.type) {
            case RETRIEVED_INITIATE_CONFIG_DATA:
                yield put(initiateConfigRequestSuccess(result.data));
                break;
            case REQUEST_INITIATE_CONFIG_ERROR:
                
                logError('Error on initiate config call. Capi-js returned a request failure');
                
                yield put(initiateConfigRequestFailed());
                break;
            default:
                break;
        }
    });
}


export function* gatewayTimeoutSaga() {
        yield put(showAppError(APP_ERROR_TYPES.NO_RETRY));
        yield put(paymentComplete());
    yield put(resetCardSelection());
    yield put(flushProductDetails());
    yield put(resetIovation());
    yield put(clearCompletedSteps());
}

export function* orderingSaga(payment) {
    const eGiftCards = yield select(getEGiftCards);
    const orderMetaData = yield select(getOrderMetaData);
    const order = yield call(orderSerializer,
        payment,
        orderMetaData,
        eGiftCards);
    
    yield put(placeOrder(order.toJS()));

    
    const result = yield take([
        ORDER_SUCCESS, ORDER_FAILED, PAYMENT_FAILED
    ]);

            payment = sanitizePaymentData(payment);

    const totalAmount = orderMetaData.get('amount').toFixed(2);
    const currencyCode = orderMetaData.get('currencyCode');

    switch (result.type) {
        case ORDER_FAILED: {
            logWarning(result.error);
            const res = typeof result.error.response !== 'undefined'
                ? result.error.response : defaultFailureResponse;
            const parsedResponse = parseErrorResponse(res);
            const parsedErrorForSegment = parseErrorResponseForSegment(res);
            if (res.status === 403) {
                yield put(paymentFailedPromo(
                    parsedResponse,
                    {
                        error: parsedErrorForSegment,
                        eGiftCards,
                        totalAmount,
                        currencyCode
                    }
                ));
                yield put(setPromoStatus({ statusType: res.data.errorCode, promoCode: order.toJS().promoCode }));
                yield put(loadingAction({ isLoading: false }));
            } else if (res.status === 504) {
                logWarning('Order API responded with a order placement timeout');
                yield call(gatewayTimeoutSaga);
            } else {
                yield put(paymentFailed(
                    parsedResponse,
                    {
                        error: parsedErrorForSegment,
                        eGiftCards,
                        totalAmount,
                        currencyCode
                    }
                ));
            }
            break;
        }
        case PAYMENT_FAILED: {
            const additionalSegmentData = { eGiftCards, totalAmount, currencyCode };
            yield call(paymentErrorHandler, result.error, payment, payment.get('paymentMethod'), additionalSegmentData);
            break;
        }
        case ORDER_SUCCESS: {
                        try {
                
                const orderData = yield call(orderResponseSerializer, result.data);
                const sessionKey = yield select(getStorefrontSessionKey);
                const bonusCards = yield select(getBonusCards);
                const bonusCardsCopy = JSON.parse(JSON.stringify(bonusCards.toJS()));
                yield put(clearRejectedPromos());

                const orderDetails = {
                    orderData: orderData.toJS(),
                    eGiftCards: eGiftCards.toJS(),
                    amount: totalAmount,
                    currencyCode,
                    sessionKey,
                    bonusCards: bonusCardsCopy
                };

                
                yield put(paymentSuccess(orderDetails));
            } catch (e) {
                
                yield put(showAppError(APP_ERROR_TYPES.NO_RETRY));
                logError(`Order success handling failed: "${e}", bailing and showing the user a 500`);
            } finally {
                                yield put(paymentComplete());
                yield put(resetCardSelection());
                yield put(flushProductDetails());
                yield put(resetIovation());
                yield put(resetPromo());
            }
            break;
        }
        default:
            break;
    }
}

export function* paymentErrorHandler(error, payment, paymentMethod, additionalSegmentData = {}) {
    logWarning(`${paymentMethod} auth failed: ${error}`, payment);
    const parsedResponse = parseErrorResponse(defaultFailureResponse);
    const parsedErrorForSegment = parseErrorResponseForSegment(defaultFailureResponse);
    yield put(paymentFailed(parsedResponse, { error: parsedErrorForSegment, ...additionalSegmentData }));
}

export function* placeOrderSaga() {
        while (true) {
                        let { paymentMethod, paymentData } = yield take(PROCESS_PAYMENT);
        
        const eGiftCards = yield select(getEGiftCards);
        const totalAmount = (yield select(getGrandTotal)).toFixed(2);
        const currencyCode = yield select(getIntlCurrencyCode);

        
        yield put(startSendOrder());

        try {
            const paymentMethodDataHandler = paymentMethodDataHandlers.get(paymentMethod);
            let payment = yield call(paymentMethodDataHandler, paymentData);

            
            if (payment.get('approved')) {
                                const ORDERING_TIMEOUT_THRESHOLD = 30; 
                const { timeout, response } = yield race({
                    timeout: delay(ORDERING_TIMEOUT_THRESHOLD * 1000),
                    response: call(orderingSaga, payment)
                });

                if (timeout && !response) {
                    logWarning('Order placement timeout: No response from order API');
                    yield call(gatewayTimeoutSaga);
                }
            } else {
                const error = 'Not approved from storefront';
                payment = sanitizePaymentData(payment);
                const additionalSegmentData = { eGiftCards, totalAmount, currencyCode };
                yield call(paymentErrorHandler, error, payment, paymentMethod, additionalSegmentData);
            }
        } catch (e) {
                        paymentData = sanitizePaymentData(paymentData);
            const additionalSegmentData = { eGiftCards, totalAmount, currencyCode };
            yield call(paymentErrorHandler, e, paymentData, paymentMethod, additionalSegmentData);
        }
    }
}

export default placeOrderSaga;
