import { fromJS, Map as IMap } from 'immutable';


export const PROMO_STORE_KEY = 'promo';
export const ACTIVE_PROMO = 'activePromo';
export const PROMO_ERROR = 'promoError';
export const PROMO_BY_CODE_REQUEST_STATE = 'promoByCodeRequestState';
export const REJECTED_PROMO_CODE = 'rejectedPromoCode';
export const PROMO_REWARDS = 'rewards';
export const PROMO_REWARDS_STATE = 'requestState';
export const PROMO_STATUS = 'promoStatus';
export const REJECTED_PROMO = 'rejectedPromoCode';
export const CUSTOM_REWARD_CARD_RECIPIENT_STORE_KEY = 'customRewardRecipients';
export const PROMO_LIMIT_EXCEEDED = 'promoLimitExceeded';

export const REWARDS_REQUEST_STATE = {
    ACTIVE: 'active',
    FAILED: 'failed',
    SUCCESS: 'success'
};


export const INVALID_PROMO_ERROR = 'promo_invalid';
export const EXPIRED_PROMO_ERROR = 'promo_expired';
export const SOLD_OUT_PROMO_ERROR = 'promo_sold_out';
export const PROMO_VALID_SUCCESS = 'promo_valid_success';


export const GET_INITIAL_PROMO = '@@storefront/GET_INITIAL_PROMO';
export const APPLY_ACTIVE_PROMO = '@@storefront/APPLY_PROMO';
export const GET_PROMO_FOR_CODE = '@@storefront/GET_PROMO_FOR_CODE';
export const RESET_PROMO = '@@storefront/RESET_PROMO';


export const SET_ACTIVE_PROMO = '@@storefront/SET_ACTIVE_PROMO';
export const SET_PROMO_ERROR = '@@storefront/SET_PROMO_ERROR';
export const SET_PROMO_BY_CODE_REQUEST_STATE = '@@storefront/SET_PROMO_BY_CODE_REQUEST_STATE';
export const SET_REJECTED_PROMO = '@@storefront/SET_REJECTED_PROMO';
export const SET_PROMO_REWARDS = '@@storefront/SET_PROMO_REWARDS';
export const SET_REWARDS_REQUEST_STATE = '@@storefront/SET_REWARDS_REQUEST_STATE';
export const SET_PROMO_STATUS = '@@storefront/SET_PROMO_STATUS';
export const CLEAR_REJECTED_PROMOS = '@@storefront/CLEAR_REJECTED_PROMO';
export const REMOVE_PROMO_REWARDS = '@@storefront/REMOVE_PROMO_REWARDS';
export const CLEAR_CUSTOM_REWARD_CARD_RECIPIENT = '@@storefront/CLEAR_CUSTOM_REWARD_CARD_RECIPIENT';
export const SET_PROMO_LIMIT_EXCEEDED = '@@storefront/SET_PROMO_LIMIT_EXCEEDED';

export const initialState = IMap();



export default function promoReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ACTIVE_PROMO: {
            return state.set(ACTIVE_PROMO, fromJS(action.promo));
        }
        case SET_PROMO_ERROR: {
            const { codeOrDefault, error } = action;
            return state.update(PROMO_ERROR, (errorMap = IMap()) => errorMap.merge({ [codeOrDefault]: error }));
        }
        case SET_PROMO_BY_CODE_REQUEST_STATE: {
            return state.set(PROMO_BY_CODE_REQUEST_STATE, action.state);
        }
        case SET_REJECTED_PROMO: {
            
            const stateWithoutActivePromo = state.delete(ACTIVE_PROMO);
            return stateWithoutActivePromo.set(REJECTED_PROMO_CODE, fromJS(action.rejectedPromoCode));
        }
        case SET_PROMO_REWARDS: {
            return state
                .set(PROMO_REWARDS,
                    fromJS(action.rewards).merge({ [PROMO_REWARDS_STATE]: REWARDS_REQUEST_STATE.SUCCESS }));
        }
        case SET_REWARDS_REQUEST_STATE: {
            return state.setIn([PROMO_REWARDS, PROMO_REWARDS_STATE], action.state);
        }
        case SET_PROMO_STATUS: {
            return state.set(PROMO_STATUS, fromJS(action.status));
        }
        case CLEAR_REJECTED_PROMOS: {
            return state
                .delete(PROMO_ERROR)
                .delete(REJECTED_PROMO_CODE);
        }
        case REMOVE_PROMO_REWARDS: {
            return state.delete(PROMO_REWARDS);
        }
        case CLEAR_CUSTOM_REWARD_CARD_RECIPIENT: {
            return state.delete(CUSTOM_REWARD_CARD_RECIPIENT_STORE_KEY);
        }
        case SET_PROMO_LIMIT_EXCEEDED: {
            return state.set(PROMO_LIMIT_EXCEEDED, fromJS(action.status));
        }
        default:
            return state;
    }
}


export const getInitialPromo = () => ({
    type: GET_INITIAL_PROMO
});

export function setPromo(promo) {
    return {
        type: SET_ACTIVE_PROMO,
        promo
    };
}

export function clearActivePromo() {
    return {
        type: SET_ACTIVE_PROMO,
        promo: {}
    };
}

export function setPromoError(codeOrDefault, error) {
    return {
        type: SET_PROMO_ERROR,
        codeOrDefault,
        error
    };
}

export const setPromoByCodeRequestState = requestState => ({
    type: SET_PROMO_BY_CODE_REQUEST_STATE,
    state: requestState
});

export const setRejectedPromo = rejectedPromoCode => ({
    type: SET_REJECTED_PROMO,
    rejectedPromoCode
});

export const setPromoRewards = rewards => ({
    type: SET_PROMO_REWARDS,
    rewards
});

export const setRewardsRequestActive = () => ({
    type: SET_REWARDS_REQUEST_STATE,
    state: REWARDS_REQUEST_STATE.ACTIVE
});

export const setRewardsRequestFailure = () => ({
    type: SET_REWARDS_REQUEST_STATE,
    state: REWARDS_REQUEST_STATE.FAILED
});

export const applyActivePromo = () => ({
    type: APPLY_ACTIVE_PROMO
});

export const getPromoForCode = promoCode => ({
    type: GET_PROMO_FOR_CODE,
    promoCode
});

export function setPromoStatus(status) {
    return {
        type: SET_PROMO_STATUS,
        status
    };
}

export function resetPromo() {
    return {
        type: RESET_PROMO
    };
}

export const clearRejectedPromos = () => ({
    type: CLEAR_REJECTED_PROMOS
});

export function clearPromoStatus() {
    return {
        type: SET_PROMO_STATUS,
        status: null
    };
}

export const removePromoRewards = () => ({
    type: REMOVE_PROMO_REWARDS
});

export const removeCustomRewardCardRecipients = () => ({
    type: CLEAR_CUSTOM_REWARD_CARD_RECIPIENT
});


export const setPromoLimitExceeded = status => ({
    type: SET_PROMO_LIMIT_EXCEEDED,
    status
});
