import {
    Drawer, Button, Typography, Box, Divider
} from '@mui/material';
import { List as IList } from 'immutable';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import countryToCurrency from 'country-to-currency';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import orderStatusMessages from '../order/messages/orderStatusMessages';
import footerMessages from '../footer/messages/FooterMessages';
import { getIntlLocale } from '../intl/intlSelectors';
import { getCustomCashstarLinks } from '../footer/FooterSelector';
import { getBrandName } from '../brand/brandSelectors';
import { getCookieConsentEnabled } from '../app/bootstrap';
import DrawerFooterLinksDefault from './DrawerFooterLinksDefault';
import { getCurrencyPickerStatus } from '../localeCurrencyPicker/LocaleCurrencyPickerSelector';
import windowSize from '../utils/windowSize';
import { isMobileview } from '../utils/deviceTypeUtils';


const Flag = ({ countryCode, styleNames = {} }) => <i className={classnames(styleNames, 'flag', countryCode)} />;


const DrawerComponent = ({
    customLinks, intl, locale, brandName, handleClickOpen, fullySupportedLocalesUniqueArr,
    localesForSelectedCountry, isCurrencyPickerStatus, windowWidth
}) => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const excludedFooter = [intl.formatMessage(orderStatusMessages.faqsLabel).toLowerCase().trim(),
        intl.formatMessage(orderStatusMessages.customerSupportLabel).toLowerCase().trim()];

    const linkList = customLinks.map((link) => {
        const { text, url } = link.toJS();
        if (excludedFooter.includes(text.toLowerCase().trim())) {
            return null;
        }
        return (
            <ListItem>
                <Button
                    className='drawerHeaderButton'
                    href={url}
                    rel='noreferrer noopener'
                    target='_blank'
                >
                    {text}
                </Button>
            </ListItem>
        );
    });

    const toggleDrawer = open => (event) => {
        if (
            event.type === 'keydown'
            && (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpenDrawer(open);
    };

    const languageText = new Intl.DisplayNames([locale.split('-')[0]], {
        type: 'language'
    }).of(locale.split('-')[0]);

    const countrySelected = locale.split('-')[1];

    return (
        <div className='headerDrawerWrapper'>
            <Drawer
                anchor='right'
                variant='persistent'
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                <Box>
                    <IconButton className='header-Drawer-Close-Icon' onClick={() => { setOpenDrawer(false); }}>
                        <CloseIcon />
                    </IconButton>
                    {}
                    <ListItem className='drawerLocalizationButtonWrapper'>
                        {
                            fullySupportedLocalesUniqueArr.length <= 1 && localesForSelectedCountry.size <= 1
                                ? null
                                : (isCurrencyPickerStatus && (
                                    <Button
                                        variant="text"
                                        className='drawerLocalizationButton'
                                        onClick={handleClickOpen}
                                    >
                                        <Flag className='localizationHeaderFlag' countryCode={countrySelected} />
                                        <Typography className='drawerLocalizationHeaderText'>
                                            -
                                            {' '}
                                            {countryToCurrency[countrySelected.toUpperCase()]}
                                            {' '}
                                            |
                                            {' '}
                                            {languageText}
                                        </Typography>

                                    </Button>
                                ))
                        }
                    </ListItem>
                    <ListItem>
                        <Divider className='drawerDivider' />
                    </ListItem>
                    {}
                    {}
                    <Box>
                        {customLinks.size ? linkList : <DrawerFooterLinksDefault />}
                        {getCookieConsentEnabled() && isMobileview(windowWidth) && (
                            <ListItem>
                                <Button className='drawerHeaderButton' id="teconsent" />
                            </ListItem>

                        )}
                    </Box>

                    <Box className='drawer-footer-wrapper'>
                        <ListItem className='drawer-footer'>
                            <Typography className='drawer-footer-item'>
                                {
                                    intl.formatMessage(
                                        footerMessages.headerCopyRightText,
                                        { currentYear: new Date().getFullYear(), brandName }
                                    )
                                }
                            </Typography>
                        </ListItem>
                        <ListItem className='drawer-footer'>

                            <Typography className='drawer-footer-item'>
                                {
                                    intl.formatMessage(
                                        footerMessages.headerPoweredByCashstar, { brandName }
                                    )
                                }
                            </Typography>

                        </ListItem>

                    </Box>

                </Box>
            </Drawer>
            <IconButton
                edge="start"
                aria-label="open drawer"
                onClick={toggleDrawer(true)}
                className='headerDrawerIcon'
            >
                <MenuIcon />
            </IconButton>
        </div>
    );
};

Flag.defaultProps = {
    styleNames: {}
};

Flag.propTypes = {
    countryCode: PropTypes.string.isRequired,
    styleNames: PropTypes.object
};

DrawerComponent.defaultProps = {
    brandName: null,
    handleClickOpen: null
};

DrawerComponent.propTypes = {
    customLinks: PropTypes.instanceOf(IList).isRequired,
    intl: intlShape.isRequired,
    locale: PropTypes.string.isRequired,
    brandName: PropTypes.string,
    handleClickOpen: PropTypes.func,
    fullySupportedLocalesUniqueArr: PropTypes.instanceOf(IList).isRequired,
    localesForSelectedCountry: PropTypes.object.isRequired,
    isCurrencyPickerStatus: PropTypes.bool.isRequired,
    windowWidth: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
    customLinks: getCustomCashstarLinks(state),
    locale: getIntlLocale(state),
    brandName: getBrandName(state),
    isCurrencyPickerStatus: getCurrencyPickerStatus(state)
});

export default connect(mapStateToProps)(injectIntl(windowSize(DrawerComponent)));
