import { Map as IMap } from 'immutable';


export const APP_ERROR_TYPES = {
    NO_RETRY: 'no-retry',
    REFRESH: 'refresh'
};


export const LOCATION_CHANGE = '@@storefront/LOCATION_CHANGE';
export const SAVE_COMPLETED_STEPS = '@@storefront/SAVE_COMPLETED_STEPS';
export const SHOW_APP_ERROR = '@@storefront/SHOW_APP_ERROR';
export const CLEAR_COMPLETED_STEPS = '@@storefront/CLEAR_COMPLETED_STEPS';
export const STEP_CHANGE = '@@storefront/STEP_CHANGE';

const initialState = IMap({});


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_COMPLETED_STEPS: {
            const { completedSteps } = action;
            return state.merge(completedSteps);
        }
        case CLEAR_COMPLETED_STEPS: {
            return initialState;
        }
        default:
    }
    return state;
};



export const saveCompletedSteps = completedSteps => ({
    type: SAVE_COMPLETED_STEPS,
    completedSteps
});

export const clearCompletedSteps = () => ({
    type: CLEAR_COMPLETED_STEPS
});

export const changeLocation = (location, action) => ({
    type: LOCATION_CHANGE,
    location,
    action
});

export const showAppError = errorType => ({
    type: SHOW_APP_ERROR,
    errorType,
    meta: {
        segment: {
            eventAction: 'Gateway timeout',
            eventData: {
                description: 'A gateway timeout means the order may have succeeded.'
                + ' So we tell the user to check for a confirmation before they retry their order'
            }
        }
    }
});

export const changeStep = (path, location) => ({
    type: STEP_CHANGE,
    path,
    location
});

export default reducer;
