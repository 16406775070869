import { defineMessages } from 'react-intl';

const primitiveMessages = defineMessages({
    backButtonText: {
        id: 'primitive.primitiveMessages.backButtonText',
        description: 'Back button text',
        defaultMessage: 'Back'
    }
});

export default primitiveMessages;
