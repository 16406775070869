import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List as IList, Map as IMap } from 'immutable';
import { injectIntl, intlShape } from 'react-intl';
import {
    Grid,
    Typography,
    Box,
    Divider,
    Card,
    CardMedia,
    CardContent
} from '@mui/material';
import classNames from 'classnames';
import OrderStatusLayout from './OrderStatuslayout';
import { customAmountNumberFormatter } from '../utils/numberUtils';
import { getEGiftCards } from '../giftCardDetailsPage/productDetailsSelector';
import { getIntlLocale, getIntlCurrencyCode } from '../intl/intlSelectors';
import { getSelectedFaceplate } from '../landingPage/landingPageSelectors';


import orderStatusMessages from '../order/messages/orderStatusMessages';
import promoMessages from '../promotion/messages/promoMessages';
import SharableLinkCard from '../giftCardDetailsPage/SharableLinkCard';
import { EGIFT_DELIVERY_SHAREABLE } from '../constants/constants';



const FormLeftPanel = (props) => {
    const {
        successCard,
        failureCard,
        defaultCard,
        noDetailsCard,
        intl,
        formLeftPanelTitle,
        displayData,
        storedGiftCards,
        locale,
        currencyCode,
        selectedFaceplate,
        cartQualifiesForPromo,
        bonusCard
    } = props;
    let displayInfo = displayData ? displayData.eGiftCards : storedGiftCards.toJS();
    
    displayInfo = (displayInfo && displayInfo[0]) || {};
    const { faceplate } = displayInfo;
    const cardMediaUrl = faceplate ? faceplate.imageURL : selectedFaceplate.get('imageURL');
    const purchaseCardValue = customAmountNumberFormatter(
        { locale },
        displayInfo.cardValue,
        currencyCode,
        true
    );
    
    if (displayInfo.selfBuy) {
        displayInfo.recipientName = displayInfo.yourName;
        displayInfo.recipientEmail = displayInfo.yourEmail;
    }

    const bonusCardValue = bonusCard ? customAmountNumberFormatter({ locale }, bonusCard.get('cardValue', 0),
        currencyCode, true) : 0;
    const bonusCardImg = () => {
        const bonusCardFaceplate = bonusCard.get('faceplate', IMap());
        return bonusCardFaceplate ? bonusCardFaceplate.get('imageURL') : '';
    };
    const promoCardDescString = `<div class="promo-highlight-text"> ${intl.formatMessage(promoMessages.freeGiftCardTitle, { number: bonusCardValue })} </div>`;
    return (
        <Box className={classNames(
            'form-container-item-left-panel', {
                'color-scheme-default': defaultCard,
                'color-scheme-success': successCard,
                'color-scheme-failure': failureCard,
                'color-scheme-no-details': noDetailsCard
            }
        )}
        >
            <div className="left-panel-title">
                {formLeftPanelTitle}
            </div>
            { !successCard && !failureCard && (
                <Card className={classNames('left-panel-card', { 'no-details-style-scheme': noDetailsCard })}>
                    {cardMediaUrl && (
                        <CardMedia
                            className='left-panel-card-media'
                            component='img'
                            image={cardMediaUrl}
                            title="Selected Faceplate"
                        />
                    )}
                    <CardContent className='left-panel-card-content'>
                        <Grid className={classNames('left-panel-card-details', { hide: noDetailsCard })} container>
                            <Grid
                                item
                                xs={12}
                                md={8}
                                order={{ xs: 2, md: 1 }}
                                className='left-panel-card-grid margin-bottom-grid'
                            >
                                <Typography className='card-details-h6-variant' variant='h6' color='text.secondary'>
                                    { displayInfo.eGiftCardShareOption === EGIFT_DELIVERY_SHAREABLE && !displayInfo.selfBuy ? intl.formatMessage(orderStatusMessages.sendALinkToLabel) : intl.formatMessage(orderStatusMessages.sendToLabel) }
                                </Typography>
                                {
                                    displayInfo.eGiftCardShareOption && displayInfo.eGiftCardShareOption === EGIFT_DELIVERY_SHAREABLE && !displayInfo.selfBuy ? (<Typography className='card-details-text'>{displayInfo.recipientName}</Typography>) : (<Typography className='card-details-text'>{displayInfo.recipientEmail}</Typography>)
                                }
                            </Grid>
                            <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }} className='margin-bottom-grid'>
                                <Typography
                                    className='card-details-h6-variant text-align-right'
                                    variant='h6'
                                    color='text.secondary'
                                >
                                    { intl.formatMessage(orderStatusMessages.giftCardValueLabel) }
                                </Typography>
                                <Typography className='card-details-text text-align-right'>
                                    {purchaseCardValue}
                                </Typography>
                            </Grid>
                            {!displayInfo.selfBuy && displayInfo.personalMessage && (
                                <Grid
                                    className='card-details-personal-message-container'
                                    item
                                    xs={12}
                                    order={{ xs: 3, md: 3 }}
                                >
                                    <Typography className='card-details-h6-variant' variant='h6' color='text.secondary'>
                                        { intl.formatMessage(orderStatusMessages.personalMessageLabel) }
                                    </Typography>
                                    <Typography className='card-details-text'>
                                        { displayInfo.personalMessage }
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            )}
            {(successCard || failureCard) && (
                <>
                    <OrderStatusLayout
                        purchaseCardValue={purchaseCardValue}
                        cardMediaUrl={cardMediaUrl}
                        displayInfo={displayInfo}
                    />
                    {cartQualifiesForPromo ? (
                        <>
                            {(successCard && displayInfo.eGiftCardShareOption === EGIFT_DELIVERY_SHAREABLE && !displayInfo.selfBuy) && <SharableLinkCard />}
                            <Box>
                                <Divider className='billSummaryDivider' />
                            </Box>
                            <Typography dangerouslySetInnerHTML={{ __html: promoCardDescString }} />
                            <OrderStatusLayout
                                isPromo
                                purchaseCardValue={bonusCardValue}
                                cardMediaUrl={bonusCardImg()}
                                displayInfo={displayInfo}
                            />

                        </>
                    ) : (successCard && displayInfo.eGiftCardShareOption === EGIFT_DELIVERY_SHAREABLE && !displayInfo.selfBuy) && <SharableLinkCard />
                    }
                </>
            )}
        </Box>
    );
};

FormLeftPanel.defaultProps = {
    displayData: null,
    formLeftPanelTitle: '',
    bonusCard: null,
    cartQualifiesForPromo: false
};

FormLeftPanel.propTypes = {
    
    successCard: PropTypes.bool.isRequired,
    failureCard: PropTypes.bool.isRequired,
    defaultCard: PropTypes.bool.isRequired,
    noDetailsCard: PropTypes.bool.isRequired,
    formLeftPanelTitle: PropTypes.string,
    displayData: PropTypes.object,
    cartQualifiesForPromo: PropTypes.bool,
    bonusCard: PropTypes.instanceOf(IMap),
    
    intl: intlShape.isRequired,
    
    storedGiftCards: PropTypes.instanceOf(IList).isRequired,
    locale: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
    selectedFaceplate: PropTypes.instanceOf(IMap).isRequired
};

const mapStateToProps = state => ({
    storedGiftCards: getEGiftCards(state),
    locale: getIntlLocale(state),
    currencyCode: getIntlCurrencyCode(state),
    selectedFaceplate: getSelectedFaceplate(state)
});

export default connect(mapStateToProps)(injectIntl(FormLeftPanel));
