
export const BASE_PATH = 'store-v2';

export const getBasePath = () => BASE_PATH;

export const getHomePath = () => `/${getBasePath()}`;

export const getCategoryPath = () => `/${getBasePath()}/category`;

export const getPdpPath = () => `/${getBasePath()}/product`;

export const getPaymentPath = () => `/${getBasePath()}/payment`;

export const getOrderSuccessPath = () => `/${getBasePath()}/confirmation/success`;

export const getOrderFailurePath = () => `/${getBasePath()}/confirmation/failure`;

export const getErrorNoRetryPath = () => `/${getBasePath()}/error-no-retry`;
