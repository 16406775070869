import { defineMessages } from 'react-intl';

const promoErrorMessage = defineMessages({
    soldOutHeading: {
        id: 'orderSuccessPage.promoSoldOutLabel',
        description: 'Label for promo sold out.',
        defaultMessage: 'Promo Sold Out'
    },
    soldOutContent: {
        id: 'orderSuccessPage.soldOutText',
        description: 'Text to show when a promo is sold out.',
        defaultMessage: `Whoops! The promotion tied to your order is sold out. 
        You can go back and edit your order without the promotion.`
    },
    limitExceedHeading: {
        id: 'orderSuccessPage.promoLimitExceededLabel',
        description: 'Label for promo limit exceeded',
        defaultMessage: 'Promotion Limit is Used Up'
    },
    limitExceedContent: {
        id: 'orderSuccessPage.promoLimitExceededText',
        description: 'Text to show when promo redemption limit has exceeded',
        defaultMessage: `You have already redeemed this promotion. 
        Would you like to go back and edit your cart or retry your payment without the promotion?`
    },
    soldOutButtonText: {
        id: 'orderSuccessPage.promoModalEditCartLabel',
        description: 'Label for promo modal edit cart',
        defaultMessage: 'Edit Order'
    },
    limitExceedButtonText: {
        id: 'orderSuccessPage.promoModalCloseLabel',
        description: 'Label for promo modal close button',
        defaultMessage: 'GOT IT'
    }
});

export default promoErrorMessage;
