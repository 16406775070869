import { Map as IMap } from 'immutable';
import { WEBVIEW_KEY, WEBVIEW_VALUE } from '../constants/constants';


export const SET_IS_WEB_VIEW = '@@storefront/SET_IS_WEB_VIEW';


const webViewReducer = (state = IMap({}), action) => {
    switch (action.type) {
        case SET_IS_WEB_VIEW: {
            const { isWebView } = action;
            return state.set('isWebView', isWebView);
        }
        default:
    }
    return state;
};



export const setIsWebView = isWebView => ({
    type: SET_IS_WEB_VIEW,
    isWebView
});

export const setIsWebViewValue = (dispatch) => {
    const searchParams = new URLSearchParams(window.location.search);
    const mobileOriginFromUrl = searchParams.get(WEBVIEW_KEY) || '';
    if (mobileOriginFromUrl && mobileOriginFromUrl === WEBVIEW_VALUE) {
        dispatch(setIsWebView(true));
    }
};

export default webViewReducer;
