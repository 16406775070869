import { Map as IMap } from 'immutable';


const initialState = {
    isCurrencyPickerEnabled: false
};


export const SET_CURRENCY_PICKER_ENABLE_STATUS = '@@storefront/SET_CURRENCY_PICKER_ENABLE_STATUS';


const currencyPickerReducer = (state = IMap(initialState), action) => {
    switch (action.type) {
        case SET_CURRENCY_PICKER_ENABLE_STATUS: {
            const { payload: { isCurrencyPickerEnabled = false } = {} } = action;
            return state.set('isCurrencyPickerEnabled', isCurrencyPickerEnabled);
        }
        default:
    }
    return state;
};


export const setIsCurrencyPickerEnabled = payload => ({
    type: SET_CURRENCY_PICKER_ENABLE_STATUS,
    payload
});

export default currencyPickerReducer;
